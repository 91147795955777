import colors from "./Colors";

const styles = {
  maincontainer: {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
  },
  seccontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "10vh",
    marginLeft: 30,
    marginRight: 30,
  },
  text: {
    color: colors.darkgray,
  },
  cardcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 30,
    marginRight: 30,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    padding: 10,
    margin: 10,
  },
  cardimg: {
    height: "30vh",
    width: "100%",
    cursor: "pointer",
  },
  emptycontainer: {
    display: "flex",
    flexDirection: "column",
  },
  emptytextcontainer: {
    textAlign: "center",
    color: colors.darkgray,
    marginTop: "20vh",
  },
};

export default styles;
