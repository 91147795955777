import React from "react";
import { Paper } from "@material-ui/core";
import { promosRef, storageRef } from "../Firebase/FirebaseConfig";

// Import styles and components
import Header from "../Components/Header";
import {
  CustomTextField,
  CustomButtonDark,
} from "../Components/Custom/CustomComponents";
import {
  CompleteModal,
  ErrorModal,
} from "../Components/Modals/UnarchiveModals";
import styles from "../Styles/UnarchivePromos";

export default class UnarchivePromo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendBack = this.sendBack.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.path) {
      this.setState({ disabled: true }, () =>
        this.handleImageUpload(this.state.path)
      );
    } else {
      // Not updating with a new image.
      this.setState({ disabled: true }, () => this.uploadToDatabase());
    }
  }

  async handleImageUpload(path) {
    const image = path[0];

    // Generate a unique name for the file.
    const uniqueId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    const ref = storageRef.child(`/promos/${uniqueId}.jpg`);

    // Take filesystem path and upload a byte stream to firebase storage.
    await ref
      .put(image)
      .then(() => {
        // Get the downloadURL since it will be public to anyone who has the promotion.
        ref
          .getDownloadURL()
          .then((url) => {
            // Create date info for when this badboy expires.
            const today = new Date();
            const futureDate = this.state.date.split("-").join("/");
            const selectedDate = new Date(futureDate);
            const diffInTime = selectedDate.getTime() - today.getTime();
            const diffInDays = diffInTime / (1000 * 3600 * 24);

            // Everything looks good, push to database.
            const newPromo = promosRef.push({
              name: this.state.name,
              description: this.state.desc,
              numDays: Math.ceil(diffInDays),
              expDate: this.state.date,
              numSold: 0,
              code: this.state.code,
              imageName: uniqueId,
              url: url,
              uses: this.state.uses,
            });

            // Update the promotion key to the actual snapshot.key.
            promosRef
              .child(newPromo.key)
              .update({ id: newPromo.key })
              .then(() => {
                // Show completion of task
                this.setState({ showComplete: true });
              });
          })
          .catch((e) => {
            console.log(e);
            this.setState({ disabled: false, showError: true });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ disabled: false, showError: true });
      });
  }

  /* Just upload the file to realtime db without a new image. */
  uploadToDatabase() {
    const today = new Date();
    const futureDate = this.state.date.split("-").join("/");
    const selectedDate = new Date(futureDate);
    const diffInTime = selectedDate.getTime() - today.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    // Everything looks good, push to database.
    const newPromo = promosRef.push({
      name: this.state.name
        ? this.state.name
        : this.props.location.state.promo.name,
      description: this.state.desc
        ? this.state.desc
        : this.props.location.state.promo.description,
      numDays: Math.ceil(diffInDays),
      expDate: this.state.date,
      numSold: 0,
      code: this.state.code
        ? this.state.code
        : this.props.location.state.promo.code,
      imageName: this.props.location.state.promo.imageName,
      url: this.props.location.state.promo.url,
      uses: this.state.uses
        ? this.state.uses
        : this.props.location.state.promo.uses,
    });

    // Update the promotion key to the actual snapshot.key.
    promosRef
      .child(newPromo.key)
      .update({ id: newPromo.key })
      .then(() => {
        // Show completion of task
        this.setState({ showComplete: true });
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Send the user back to the analytics screen. */
  sendBack() {
    this.props.history.push({
      pathname: "analytics",
    });
  }

  render() {
    // Minimum date for something to expire is the current date.
    const today = new Date();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let y = today.getFullYear();
    d = d < 10 ? "0" + d : d;
    const mindate = y + "-" + m + "-" + d;

    return (
      <>
        <Header history={this.props.history} />

        <div style={styles.maincontainer}>
          <div style={styles.secondarycontainer}>
            <Paper elevation={3} style={styles.paper}>
              <form onSubmit={this.handleSubmit} style={styles.form}>
                <h1 style={styles.formheader}>Edit Pre-paid Service</h1>
                <label style={styles.formtext}>Name</label>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={this.state.name}
                  placeholder={this.props.location.state.promo.name}
                  style={styles.input}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                <label style={styles.formtext}>Code</label>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={this.state.code}
                  placeholder={this.props.location.state.promo.code}
                  style={styles.input}
                  onChange={(e) => this.setState({ code: e.target.value })}
                />
                <label style={styles.formtext}>Image</label>
                <CustomTextField
                  id="image"
                  variant="outlined"
                  type="file"
                  style={styles.input}
                  placeholder={this.props.location.state.promo.image}
                  value={this.state.image}
                  onChange={(e) => this.handleImageUpload(e.target.files)}
                />
                <label style={styles.formtext}>Description</label>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={this.state.desc}
                  rows={4}
                  multiline
                  style={styles.input}
                  placeholder={this.props.location.state.promo.description}
                  onChange={(e) => this.setState({ desc: e.target.value })}
                />
                <label style={styles.formtext}>Expiration Date</label>
                <CustomTextField
                  id="date"
                  style={styles.addpromocalendar}
                  variant="outlined"
                  required
                  InputProps={{ inputProps: { min: mindate } }}
                  type="date"
                  value={this.state.date}
                  onChange={(e) =>
                    this.setState({ date: e.target.value }, () =>
                      console.log(this.state.date)
                    )
                  }
                />
                <label style={styles.formtext}>Number Of Uses</label>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={this.state.uses}
                  style={styles.input}
                  placeholder={this.props.location.state.promo.uses}
                  onChange={(e) => this.setState({ uses: e.target.value })}
                />
                <div style={styles.buttoncontainer}>
                  <CustomButtonDark
                    variant="contained"
                    color="default"
                    disabled={this.state.disable}
                    style={styles.button}
                    type="submit"
                  >
                    UNARCHIVE
                  </CustomButtonDark>
                </div>
              </form>
            </Paper>
          </div>
        </div>

        {/* Show Modals */}
        {this.state.showComplete && (
          <CompleteModal
            close={() =>
              this.setState({ showComplete: false }, () => this.sendBack())
            }
          />
        )}
        {this.state.showError && (
          <ErrorModal close={() => this.setState({ showError: false })} />
        )}
      </>
    );
  }
}
