import React from "react";
import { ButtonGroup } from "@material-ui/core";
import { CustomButton2Dark } from "../Custom/CustomComponents";
import styles from "../../Styles/Components";

import {
  CompleteAllModal,
  SendAllModal,
  ErrorModal,
} from "../Modals/NotificationUserModals";

export default class NotificationButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ButtonGroup
        style={styles.buttongroup}
        color="inherit"
        aria-label="outlined primary button group"
      >
        <CustomButton2Dark
          onClick={() => this.setState({ showModal: true, group: "Users" })}
        >
          Send To All Users
        </CustomButton2Dark>
        <CustomButton2Dark
          onClick={() => this.setState({ showModal: true, group: "Customers" })}
        >
          Send To All Customers
        </CustomButton2Dark>
        {this.state.showModal && (
          <SendAllModal
            notification={this.props.notification}
            group={this.state.group}
            invalidPermission={() => this.props.invalidPermission}
            showComplete={() =>
              this.setState({ showComplete: true, showModal: false })
            }
            close={() => this.setState({ showModal: false })}
            showError={(error) =>
              this.setState({ showModal: false, showError: true, error: error })
            }
          />
        )}
        {this.state.showComplete && (
          <CompleteAllModal
            group={this.state.group}
            close={() => this.setState({ showComplete: false })}
          />
        )}
        {this.state.showError && (
          <ErrorModal
            error={this.state.error}
            close={() => this.setState({ showError: false })}
          />
        )}
      </ButtonGroup>
    );
  }
}
