import React from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import styles from "../../Styles/UploadButton";

/*
 * This just returns a small button with a plus in the middle for
 * uploading documents.
 */
export default class AddNotificationButton extends React.Component {
  render() {
    return (
      <div style={styles.main}>
        <AddCircleIcon
          fontSize="large"
          style={styles.icon}
          onClick={() => this.props.promptModal()}
        />
      </div>
    );
  }
}
