const colors = {
  primarybackground: "#FFFFFF",
  secondarybackground: "#FFFFFF",
  gray: "#424242",
  darkgray: "#27282C",
  white: "#FFFFFF",
  black: "#000000",
  disabled: "#323F4B",
  buttonprimary: "#3F51B5",
  buttonsecondary: "#F50057",
  buttondefault: "#E0E0E0",
  sentbody: "#0b93f6",
  receivedbody: "#86f19e",
  highlight: "#E6C4A6",
};

export default colors;
