import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Link,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { CustomButton2, CustomTextField } from "../Custom/CustomComponents";
import SendNotification from "../Users/SendNotification";
import styles from "../../Styles/Components";
import { userRef } from "../../Firebase/FirebaseConfig";

/*
 * Component returns the hard number of promotions that have remaining
 * redemptions.
 */
export default class PromoRemaining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      promoarr: [],
      filteredArray: [],
      remaining: 0,
    };
  }

  /* Get the necessary data everytime the component is mounted. */
  componentDidMount() {
    this.getData(this.props.promo.id);
  }

  /* Returns who has the given promotion along with some basic user data. */
  getData(promoId) {
    let temparr = [];
    let remaining = 0;

    // Read the user node once.
    userRef.once("value", (snapshot) => {
      // Iterate through each user.
      snapshot.forEach((snap) => {
        const mypromoref = userRef.child(snap.key).child("promos");
        // Read the user's promotions once.
        mypromoref.once("value", (sn) => {
          // Iterate through the user's promotions.
          sn.forEach((s) => {
            console.log("in second for each", s.val());
            if (s.val().id === promoId) {
              let Obj = {
                ownerEmail: snap.val().email,
                ownerName: snap.val().name,
                id: snap.val().id,
                promo: s.val(),
                name: snap.val().name,
              };
              let uses = s.val().uses;
              remaining += parseInt(uses, 10);
              temparr.push(Obj);
            }
          });
          // Set state to array of all promotions that users have under
          this.setState({
            loading: false,
            promoarr: temparr,
            remaining: remaining,
          });
        });
      });
    });
  }

  /* Handler for when search is updated. */
  updateSearch = (search) => {
    var updatedData = [];
    if (search === null || search === undefined || search === "") {
      updatedData = [];
    } else if (typeof search == "object") {
      updatedData.push(search);
    } else {
      // User didn't click on a card, show all cases of users with first name
      // relative to the search.
      this.state.promoarr.forEach((element) => {
        let lower = element.ownerName.toLowerCase();
        let email = element.ownerEmail.toLowerCase();

        if (
          lower.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase())
        ) {
          updatedData.push(element);
        }
      });
    }
    this.setState({ filteredArray: updatedData });
  };

  /* Helper method to render a timestamp into a user-friendly date. */
  renderDate(timestamp) {
    let n = new Date(timestamp);
    let m = n.getMonth() + 1;
    let d = n.getDate();
    let y = n.getFullYear();
    return m + "/" + d + "/" + y;
  }

  /* Sorting handler for filtering out data properly. */
  sortData = (sort, comparator) => {
    console.log(sort, comparator);

    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.promoarr;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    // Sort should be a string of the selected column
    if (sort === "Name" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.ownerName.toLowerCase() < b.ownerName.toLowerCase()) {
          return -1;
        }
        if (a.ownerName.toLowerCase() > b.ownerName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promoarr: array, filter: "asc", sort: sort });
    } else if (sort === "Name" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.ownerName.toLowerCase() > b.ownerName.toLowerCase()) {
          return -1;
        }
        if (a.ownerName.toLowerCase() < b.ownerName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promoarr: array, filter: "desc", sort: sort });
    } else if (sort === "Email" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.ownerEmail.toLowerCase() < b.ownerEmail.toLowerCase()) {
          return -1;
        }
        if (a.ownerEmail.toLowerCase() > b.ownerEmail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promoarr: array, filter: "asc", sort: sort });
    } else if (sort === "Email" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.ownerEmail.toLowerCase() > b.ownerEmail.toLowerCase()) {
          return -1;
        }
        if (a.ownerEmail.toLowerCase() < b.ownerEmail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promoarr: array, filter: "desc", sort: sort });
    } else if (sort === "PName" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.promo.name.toLowerCase() < b.promo.name.toLowerCase()) {
          return -1;
        }
        if (a.promo.name.toLowerCase() > b.promo.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promoarr: array, filter: "asc", sort: sort });
    } else if (sort === "PName" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.promo.name.toLowerCase() > b.promo.name.toLowerCase()) {
          return -1;
        }
        if (a.promo.name.toLowerCase() < b.promo.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promoarr: array, filter: "desc", sort: sort });
    } else if (sort === "Code" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.promo.code.toLowerCase() < b.promo.code.toLowerCase()) {
          return -1;
        }
        if (a.promo.code.toLowerCase() > b.promo.code.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promoarr: array, filter: "asc", sort: sort });
    } else if (sort === "Code" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.promo.code.toLowerCase() > b.promo.code.toLowerCase()) {
          return -1;
        }
        if (a.promo.code.toLowerCase() < b.promo.code.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promoarr: array, filter: "desc", sort: sort });
    } else if (sort === "Date" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.promo.expDate.toLowerCase() > b.promo.expDate.toLowerCase()) {
          return -1;
        }
        if (a.promo.expDate.toLowerCase() < b.promo.expDate.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promoarr: array, filter: "asc", sort: sort });
    } else if (sort === "Date" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.promo.expDate.toLowerCase() < b.promo.expDate.toLowerCase()) {
          return -1;
        }
        if (a.promo.expDate.toLowerCase() > b.promo.expDate.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promoarr: array, filter: "desc", sort: sort });
    } else if (sort === "Remaining" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.promo.uses.toLowerCase() < b.promo.uses.toLowerCase()) {
          return -1;
        }
        if (a.promo.uses.toLowerCase() > b.promo.uses.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promoarr: array, filter: "asc", sort: sort });
    } else if (sort === "Remaining" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.promo.uses.toLowerCase() > b.promo.uses.toLowerCase()) {
          return -1;
        }
        if (a.promo.uses.toLowerCase() < b.promo.uses.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promoarr: array, filter: "desc", sort: sort });
    }
  };

  /* Main render method of component.5 */
  render() {
    const { search } = this.state;
    const filter = createFilterOptions();

    return (
      <>
        {this.state.loading && <CircularProgress />}
        {!this.state.loading && this.state.promoarr.length === 0 && (
          <div>
            <h2 style={styles.nopromostext}>
              No users have this prepaid service yet.
            </h2>
          </div>
        )}

        {!this.state.loading && this.state.promoarr.length > 0 && (
          <Paper elevation={3} style={styles.listpromomain}>
            <div style={styles.collectioncontainer}>
              <h3 style={styles.collectionText}>
                Total Outstanding Redemptions:{" "}
              </h3>
              <h3 style={styles.collectionNumber}>{this.state.remaining}</h3>
            </div>
            <div style={styles.listpromosearchcontainer}>
              <Autocomplete
                value={search}
                onChange={(event, newValue) => this.updateSearch(newValue)}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={this.state.promoarr}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }

                  // Regular option
                  return option.ownerName + option.ownerEmail;
                }}
                renderOption={(option) =>
                  option.ownerName + " - " + option.ownerEmail
                }
                style={styles.userssearchbar}
                freeSolo
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    placeholder="Search..."
                    variant="outlined"
                  />
                )}
              />
            </div>
            <TableContainer component={Paper} style={styles.tableContainer}>
              <Table style={styles.table}>
                <TableHead style={styles.tablehead}>
                  <TableRow>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Name"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Name", "desc")
                            : this.sortData("Name", "asc")
                        }
                      >
                        User Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Email"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Email", "desc")
                            : this.sortData("Email", "asc")
                        }
                      >
                        User Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "PName"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("PName", "desc")
                            : this.sortData("PName", "asc")
                        }
                      >
                        Prepaid Service Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Code"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Code", "desc")
                            : this.sortData("Code", "asc")
                        }
                      >
                        Prepaid Service Code
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Date"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Date", "desc")
                            : this.sortData("Date", "asc")
                        }
                      >
                        Expiration Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Remaining"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Remaining", "desc")
                            : this.sortData("Remaining", "asc")
                        }
                      >
                        Redemptions Remaining
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>Action</TableCell>
                    <TableCell style={styles.rowheaderText}>
                      Send Notification
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.filteredArray.length === 0 &&
                    this.state.promoarr.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell style={styles.rowText}>
                          {user.ownerName}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          <Link
                            href={`mailto:${user.ownerEmail}`}
                            color="inherit"
                          >
                            {user.ownerEmail}
                          </Link>
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {user.promo.name}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {user.promo.code}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {user.promo.expDate}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {user.promo.uses}
                        </TableCell>

                        {/* Take emp to user's promotions. */}
                        <TableCell style={styles.rowText}>
                          <CustomButton2
                            varaiant="contained"
                            color="default"
                            onClick={() =>
                              this.props.history.push({
                                pathname: "userpromotions",
                                state: { user: user },
                              })
                            }
                          >
                            View
                          </CustomButton2>
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          <SendNotification user={user} />
                        </TableCell>
                      </TableRow>
                    ))}
                  {this.state.filteredArray.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell style={styles.rowText}>
                        {user.ownerName}
                      </TableCell>

                      <TableCell style={styles.rowText}>
                        <Link
                          href={`mailto:${user.ownerEmail}`}
                          color="inherit"
                        >
                          {user.ownerEmail}
                        </Link>
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        {user.promo.name}
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        {user.promocode}
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        {user.promo.expDate}
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        {user.promo.uses}
                      </TableCell>

                      <TableCell style={styles.rowText}>
                        <CustomButton2
                          varaiant="contained"
                          color="default"
                          onClick={() =>
                            this.props.history.push({
                              pathname: "emppromos",
                              state: { DATA: this.state.promoarrdata },
                            })
                          }
                        >
                          View
                        </CustomButton2>
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        <SendNotification user={user} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </>
    );
  }
}
