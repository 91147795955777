/* eslint-disable eqeqeq */
import React from "react";
import { Button, Paper, Fade, Modal, Backdrop } from "@material-ui/core";
import {
  adminRef,
  promosRef,
  storageRef,
  archivePromosRef,
  auth,
} from "../../Firebase/FirebaseConfig";

// Import Styles
import styles from "../../Styles/Modal.js";
import CancelIcon from "@material-ui/icons/Cancel";
import { PINAlert } from "../Alerts";
import {
  CustomTextField,
  CustomButton,
  CustomButton2,
} from "../Custom/CustomComponents";

/*
 * This modal is shown when a user clicks on the "EDIT" button for a promotion.
 */
export class EditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disable: false };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  /* Main image upload handler. */
  async handleImageUpload(path) {
    this.setState({ disable: true });

    const image = path[0];

    // Create a unique id for the image.
    const uniqueId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    const ref = storageRef.child(`/promos/${uniqueId}.jpg`);

    // Use the filepath to create a bytestream to firebase storage.
    await ref
      .put(image)
      .then((res) => {
        // Get download url from firebase storage and save it to state so we can
        // use it in realtimedb.
        ref
          .getDownloadURL()
          .then((url) => {
            this.setState({ url: url, imageName: uniqueId, disable: false });
          })
          .catch((e) => {
            console.log(e);
            this.setState({ disable: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ disable: false });
      });
  }

  /* Main submission handler. Prevents the page from reloading upon form submission. */
  handleSubmit(e) {
    e.preventDefault();
    // Verify the change is correct (all fields should be inputted when in form).

    promosRef
      .orderByChild("id")
      .equalTo(this.props.promo.id)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          promosRef.child(snapshot.key).update({
            name: this.state.name ? this.state.name : this.props.promo.name,
            code: this.state.code ? this.state.code : this.props.promo.code,
            imageName: this.state.imageName
              ? this.state.imageName
              : this.props.promo.imageName,
            url: this.state.url ? this.state.url : this.props.promo.url,
            description: this.state.desc
              ? this.state.desc
              : this.props.promo.desc,
            numDays: this.state.expDays
              ? this.state.expDays
              : this.props.promo.numDays,
            uses: this.state.uses ? this.state.uses : this.props.promo.uses,
          });

          // Promotion has successfully been updated.
          this.props.close();
        }
      });
  }

  /* Close snackbar if user chooses to do so. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.editpromocontainer}>
            <form onSubmit={this.handleSubmit} style={styles.editpromoform}>
              <h1 style={styles.addpromoheader}>Edit Pre-paid Service</h1>
              <label style={styles.addpromolabel}>Name</label>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={this.state.name}
                placeholder={this.props.promo.name}
                style={styles.input}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
              <label style={styles.addpromolabel}>Code</label>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={this.state.code}
                placeholder={this.props.promo.code}
                style={styles.input}
                onChange={(e) => this.setState({ code: e.target.value })}
              />
              <label style={styles.addpromolabel}>Image</label>
              <CustomTextField
                id="image"
                variant="outlined"
                type="file"
                style={styles.input}
                value={this.state.image}
                onChange={(e) => this.handleImageUpload(e.target.files)}
              />
              <label style={styles.addpromolabel}>Description</label>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={this.state.desc}
                rows={4}
                multiline
                style={styles.input}
                placeholder={this.props.promo.desc}
                onChange={(e) => this.setState({ desc: e.target.value })}
              />
              <label style={styles.addpromolabel}>Amount Sold</label>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={this.props.promo.numSold}
                style={styles.input}
                InputProps={{ readOnly: true }}
              />
              <label style={styles.addpromolabel}>
                Days Until Expiration Once Given
              </label>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={this.state.expDays}
                style={styles.input}
                placeholder={this.props.promo.numDays}
                onChange={(e) => this.setState({ expDays: e.target.value })}
              />
              <label style={styles.addpromolabel}>Number Of Uses</label>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={this.state.uses}
                style={styles.input}
                placeholder={this.props.promo.uses}
                onChange={(e) => this.setState({ uses: e.target.value })}
              />
              <div style={styles.editpromobuttoncontainer}>
                <CustomButton
                  variant="contained"
                  color="default"
                  disabled={this.state.disable}
                  style={styles.editpromobutton}
                  type="submit"
                >
                  SUBMIT
                </CustomButton>
              </div>
            </form>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when the promo has been removed.
 */
export class DeleteModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              This prepaid-service has been deleted.
            </h1>
            <div>
              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Modal prompts the user for pin and verifies they want to remove this promotion.
 */
export class DeleteConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false, disabled: true, PIN: "" };

    this.handleRemove = this.handleRemove.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  /* Only admins can remove promotions. If this needs to change please add manager and whomever else here. Thanks.*/
  confirmRemove(e) {
    e.preventDefault();

    // Check admin node in database for user's pin and id.
    adminRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val().PIN == this.state.PIN) {
            this.handleRemove();
          } else {
            this.setState({ alert: true });
          }
        }
      });
  }

  /* Main removal handler of component. */
  handleRemove() {
    // Find promotion in database and remove it.
    promosRef
      .orderByChild("id")
      .equalTo(this.props.promo.id)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          // Either remove it for everyone or just admins.

          // ^^ That logic will go here if we choose to change it.

          // Save promotion under archive node.
          archivePromosRef
            .push({
              name: snapshot.val().name,
              code: snapshot.val().code,
              imageName: snapshot.val().imageName,
              id: this.props.promo.id,
              url: snapshot.val().url,
              description: snapshot.val().description,
              expDate: snapshot.val().expDate,
              numSold: snapshot.val().numSold,
              numDays: snapshot.val().numDays,
              uses: snapshot.val().uses,
            })
            .then(() => {
              // Remove promotion so other admins cannot use it.
              promosRef.child(snapshot.key).remove();
              this.props.showDeleteModal();
            });
        }
      });
  }

  /* Close alerts. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}
            <Paper elevation={3} style={styles.deletepromocontainer}>
              <div style={styles.disableContainer}>
                <CancelIcon
                  fontSize="large"
                  color="secondary"
                  style={styles.cancelIcon}
                />
                <div>
                  <h1 style={styles.addpromolabel}>Are you sure?</h1>
                </div>
                <form
                  onSubmit={this.confirmRemove}
                  style={styles.deleteconfirmmodalform}
                >
                  <h3 style={styles.deletepromoheader}>
                    Do you really want to delete this prepaid service? No one
                    will be able to give it to users.
                  </h3>
                  <h3 style={styles.deletepromoheader}>
                    Please enter your PIN below.
                  </h3>
                  <CustomTextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 6 }}
                    value={this.state.PIN}
                    placeholder="000000"
                    onChange={(e) => this.setState({ PIN: e.target.value })}
                  />
                  <hr style={styles.deleteunderline} />
                  <div style={styles.deletebuttoncontainer}>
                    <CustomButton
                      color="default"
                      variant="contained"
                      style={styles.deletebutton1}
                      onClick={() => this.props.close()}
                    >
                      CANCEL
                    </CustomButton>
                    <CustomButton2
                      color="secondary"
                      variant="contained"
                      disabled={this.state.PIN.length === 6 ? false : true}
                      style={styles.deletebutton}
                      type="submit"
                    >
                      DELETE
                    </CustomButton2>
                  </div>
                </form>
              </div>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal for if an admin wants to disable a promotion from being given to users.
 */
export class DisableModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false, disabled: true, PIN: "" };

    this.handleDisable = this.handleDisable.bind(this);
    this.confirmDisable = this.confirmDisable.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  /* Close alerts. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Search admin node for authed user then disable promotion. */
  confirmDisable(e) {
    e.preventDefault();

    adminRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val().PIN == this.state.PIN) {
            this.handleDisable();
          } else {
            this.setState({ alert: true });
          }
        }
      });
  }

  /* Disable promotion in realtimedb. */
  handleDisable() {
    // Find promotion and change it to disabled.
    promosRef
      .orderByChild("id")
      .equalTo(this.props.promo.id)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          // Either remove it for everyone or just admins.
          promosRef.child(snapshot.key).update({ disabled: true });
          this.props.showDisableConfirmationModal();
        }
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}
            <Paper elevation={3} style={styles.editpromocontainer}>
              <div style={styles.disableContainer}>
                <CancelIcon
                  fontSize="large"
                  color="secondary"
                  style={styles.cancelIcon}
                />
                <div>
                  <h1 style={styles.addpromolabel}>Are you sure?</h1>
                </div>
                <form
                  onSubmit={this.confirmDisable}
                  style={styles.editpromoform}
                >
                  <h3 style={styles.addpromoheader}>
                    Do you really want to disable this prepaid service? No one
                    will be able to give it to users.
                  </h3>
                  <h3 style={styles.addpromoheader}>
                    Please enter your PIN below.
                  </h3>
                  <CustomTextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 6 }}
                    value={this.state.PIN}
                    placeholder="000000"
                    onChange={(e) => this.setState({ PIN: e.target.value })}
                  />
                  <hr style={styles.editpromobutton} />
                  <div style={styles.disablebuttoncontainer}>
                    <CustomButton
                      color="default"
                      variant="contained"
                      style={styles.editpromobutton}
                      onClick={() => this.props.close()}
                    >
                      CANCEL
                    </CustomButton>
                    <CustomButton2
                      color="secondary"
                      variant="contained"
                      disabled={this.state.PIN.length === 6 ? false : true}
                      style={styles.editpromobutton}
                      type="submit"
                    >
                      DISABLE
                    </CustomButton2>
                  </div>
                </form>
              </div>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Show completion for when a prepaid-service has been disabled.
 */
export class ShowDisableConfirmationModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromocontainer}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              This prepaid-service has been disabled.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal that prompts current authed user for their pin
 * in order to enable the prepaid-service.
 */
export class EnableModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false, disabled: true, PIN: "" };

    this.handleEnable = this.handleEnable.bind(this);
    this.confirmEnable = this.confirmEnable.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  /* Close snackbar if user's pin is incorrect. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Search admin node in realtime db for current authed user. */
  confirmEnable(e) {
    e.preventDefault();

    // Make sure pin is for current user and is correct.
    adminRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val().PIN == this.state.PIN) {
            this.handleEnable();
          } else {
            this.setState({ alert: true });
          }
        }
      });
  }

  /* Find prepaid-service in realtimedb and enable it. */
  handleEnable() {
    // Remove
    promosRef
      .orderByChild("id")
      .equalTo(this.props.promo.id)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          // Either remove it for everyone or just admins.
          promosRef.child(snapshot.key).update({ disabled: false });
          this.props.showEnableConfirmationModal();
        }
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}
            <Paper elevation={3} style={styles.deletepromocontainer}>
              <div style={styles.disableContainer}>
                <CancelIcon
                  fontSize="large"
                  color="secondary"
                  style={styles.cancelIcon}
                />
                <div>
                  <h1 style={styles.addpromoheader}>Are you sure?</h1>
                </div>
                <form
                  onSubmit={this.confirmEnable}
                  style={styles.editpromoform}
                >
                  <h3 style={styles.addpromoheader}>
                    Do you really want to enable this prepaid service?
                  </h3>
                  <h3 style={styles.addpromoheader}>
                    Please enter your PIN below.
                  </h3>
                  <CustomTextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 6 }}
                    value={this.state.PIN}
                    placeholder="000000"
                    onChange={(e) => this.setState({ PIN: e.target.value })}
                  />
                  <hr style={styles.editpromobutton} />
                  <div style={styles.disablebuttoncontainer}>
                    <CustomButton
                      color="primary"
                      variant="contained"
                      style={styles.editpromobutton}
                      onClick={() => this.props.close()}
                    >
                      CANCEL
                    </CustomButton>
                    <CustomButton2
                      color="secondary"
                      variant="contained"
                      disabled={this.state.PIN.length === 6 ? false : true}
                      style={styles.editpromobutton}
                      type="submit"
                    >
                      ENABLE
                    </CustomButton2>
                  </div>
                </form>
              </div>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class ShowEnableConfirmationModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              This prepaid-service has been enabled.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}
