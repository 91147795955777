import React from "react";
import { chatrooms } from "../Firebase/FirebaseConfig";
import Header from "../Components/Header";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Link,
  LinearProgress,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  CustomTextField,
  CustomButtonDark,
} from "../Components/Custom/CustomComponents";
import styles from "../Styles/Screens";
import { InvalidPermission } from "../Components/Alerts";

// Default variables
let myRole = localStorage.getItem("myRole");

/*
 * Shows all chatrooms (who has messaged who) on the mobile/web app
 * Employee's of the dealership can view all messages from each user.
 */
export default class AllMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatroomdata: [],
      filteredArray: [],
      showInvalidPermission: false,
    };
  }

  /* Get all chatroooms after first render. */
  componentDidMount() {
    myRole = localStorage.getItem("myRole");
    this.getChatrooms();
  }
  /* Get all messages from user's so employee's can view them. */
  getChatrooms() {
    let temparr = [];
    chatrooms.once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        // At this point, snapshot is a whole chatroom. We need to
        let d = Object.values(snap.val());
        let Obj = {};

        if (
          d[0].senderRole === "Employee" ||
          d[0].senderRole === "Manager" ||
          d[0].senderRole === "Admin"
        ) {
          Obj.empemail = d[0].senderEmail;
          Obj.useremail = d[0].receiverEmail;
        } else {
          Obj.empemail = d[0].receiverEmail;
          Obj.useremail = d[0].senderEmail;
        }

        Obj.chatroom = snap.val();

        temparr.push(Obj);
        this.setState({ chatroomdata: temparr });
      });
    });
  }

  /* Handler for when search is updated. */
  updateSearch = (search) => {
    var updatedData = [];
    if (search === null || search === undefined || search === "") {
      updatedData = [];
    } else if (typeof search == "object") {
      updatedData.push(search);
    } else {
      // User didn't click on a card, show all cases of users with first name
      // relative to the search.
      this.state.chatroomdata.forEach((element) => {
        let lower = element.empemail.toLowerCase();

        if (lower.includes(search.toLowerCase())) {
          updatedData.push(element);
        }
      });
    }
    this.setState({ filteredArray: updatedData });
  };

  /* Sorting handler for filtering out data properly. */
  sortData = (sort, comparator) => {
    console.log(sort, comparator);

    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.chatroomdata;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    // Sort should be a string of the selected column
    if (sort === "Email" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.empemail.toLowerCase() < b.empemail.toLowerCase()) {
          return -1;
        }
        if (a.empemail.toLowerCase() > b.empemail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ chatroomdata: array, filter: "asc", sort: sort });
    } else if (sort === "Email" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.empemail.toLowerCase() > b.empemail.toLowerCase()) {
          return -1;
        }
        if (a.empemail.toLowerCase() < b.empemail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ chatroomdata: array, filter: "desc", sort: sort });
    } else if (sort === "REmail" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.useremail.toLowerCase() < b.useremail.toLowerCase()) {
          return -1;
        }
        if (a.useremail.toLowerCase() > b.useremail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ chatroomdata: array, filter: "asc", sort: sort });
    } else if (sort === "REmail" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.useremail.toLowerCase() > b.useremail.toLowerCase()) {
          return -1;
        }
        if (a.useremail.toLowerCase() < b.useremail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ chatroomdata: array, filter: "desc", sort: sort });
    }
  };

  /* Main render method of component. */
  render() {
    const { search } = this.state;
    const filter = createFilterOptions();
    return (
      <>
        <Header history={this.props.history} />

        <div style={styles.userscontainer}>
          <div style={styles.userscontentcontainer}>
            <h3 style={styles.usersheadertext}>
              *This screen shows all conversations.
            </h3>
            <Autocomplete
              value={search}
              onChange={(event, newValue) => this.updateSearch(newValue)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={this.state.chatroomdata}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }

                // Regular option
                return option.empemail;
              }}
              renderOption={(option) =>
                option.empemail + " - " + option.useremail
              }
              style={styles.userssearchbar}
              freeSolo
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  placeholder="Search..."
                  variant="outlined"
                />
              )}
            />
          </div>

          {/* Main container to sort by chatrooms. */}
          {this.state.loading && <LinearProgress color="primary" />}

          {/* Content has all loaded. */}
          <TableContainer component={Paper} style={styles.tablecontainer}>
            <Table style={styles.table}>
              <TableHead style={styles.tablehead}>
                <TableRow>
                  <TableCell style={styles.rowheaderText}>
                    <TableSortLabel
                      active={this.state.sort === "Email"}
                      direction={this.state.filter === "desc" ? "desc" : "asc"}
                      onClick={() =>
                        this.state.filter === "asc"
                          ? this.sortData("Email", "desc")
                          : this.sortData("Email", "asc")
                      }
                    >
                      Employee Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowheaderText}>
                    <TableSortLabel
                      active={this.state.sort === "REmail"}
                      direction={this.state.filter === "desc" ? "desc" : "asc"}
                      onClick={() =>
                        this.state.filter === "asc"
                          ? this.sortData("REmail", "desc")
                          : this.sortData("REmail", "asc")
                      }
                    >
                      Receiver Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowheaderText}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredArray.length === 0 &&
                  this.state.chatroomdata.map((user, index) => (
                    <TableRow key={index} style={styles.rowClickable}>
                      <TableCell style={styles.rowText}>
                        <Link href={`mailto:${user.empemail}`} color="inherit">
                          {user.empemail}
                        </Link>
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        <Link href={`mailto:${user.useremail}`} color="inherit">
                          {user.useremail}
                        </Link>
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        <CustomButtonDark
                          color="default"
                          variant="contained"
                          onClick={() =>
                            myRole === "Admin"
                              ? this.props.history.push({
                                  pathname: "/userchatroom",
                                  state: { data: user },
                                })
                              : this.setState({ showInvalidPermission: true })
                          }
                        >
                          View Messages
                        </CustomButtonDark>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.filteredArray.map((user, index) => (
                  <TableRow key={index} style={styles.rowClickable}>
                    <TableCell
                      style={styles.rowText}
                      onClick={() => this.determineOnclick(user)}
                    >
                      <Link href={`mailto:${user.empemail}`} color="inherit">
                        {user.empemail}
                      </Link>
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <Link href={`mailto:${user.useremail}`} color="inherit">
                        {user.useremail}
                      </Link>
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <CustomButtonDark
                        color="default"
                        variant="contained"
                        onClick={() =>
                          myRole === "Admin"
                            ? this.props.history.push({
                                pathname: "/userchatroom",
                                state: { data: user },
                              })
                            : this.setState({ showInvalidPermission: true })
                        }
                      >
                        View Messages
                      </CustomButtonDark>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Here we will put any modals we need. */}
          {this.state.showInvalidPermission && (
            <InvalidPermission
              close={() => this.setState({ showInvalidPermission: false })}
            />
          )}
        </div>
      </>
    );
  }
}
