import React from "react";

import {
  userRef,
  empRef,
  managerRef,
  adminRef,
  DELETE_USER,
} from "../Firebase/FirebaseConfig";
import Header from "../Components/Header";
import {
  CustomButtonDark,
  CustomButton2Dark,
  CustomTextField,
  CustomSelect,
} from "../Components/Custom/CustomComponents";
import {
  Avatar,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import {
  Downgrade,
  Upgrade,
  ErrorModal,
  CompleteDowngradeModal,
  CompleteUpgradeModal,
  UserUpdateSuccess,
  EmployeeEntryForm,
} from "../Components/Users/ChangeRoles";
import {
  DeleteUserModal,
  ShowDeleteCompleteModal,
  ResetSuccessModal,
  ResetError,
  UpdateUser,
} from "../Components/Modals/UserModals";
import ResetEmail from "../Components/Users/ResetEmail";
import DisableSwitches from "../Components/Users/DisableSwitches";
import styles from "../Styles/UserInfo";

/*
 * Shows the selected user's information with editable fields.
 */
export default class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.location.state.user,
      deletedisabled: false,
      showDowngrade: false,
      showUpgrade: false,
      name: this.props.location.state.user.name,
      PIN: this.props.location.state.user.PIN
        ? this.props.location.state.user.PIN
        : 0,
      officeNum: this.props.location.state.user.officeNum,
      phoneNum: this.props.location.state.user.phoneNum,
      department: this.props.location.state.user.department,
      canUpload: this.props.location.state.user.canUpload,
      canShare: this.props.location.state.user.canShare,
      canMessage: this.props.location.state.user.canMessage,
      canViewAllDocs: this.props.location.state.user.canViewAllDocs,
    };

    this.closeModals = this.closeModals.bind(this);
    this.closeAndReturn = this.closeAndReturn.bind(this);
    this.saveChildState = this.saveChildState.bind(this);
    this.triggerSubmit = this.triggerSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }

  /* Everytime this component comes into the viewport, set this storage variable. */
  componentDidMount() {
    localStorage.setItem("usersRole", this.props.location.state.user.role);
    console.log(this.checkDisabled(this.state.user, "Downgrade"));
    console.log(this.checkDisabled(this.state.user, "Upgrade"));
  }

  /* Close all modals. */
  closeModals = () => {
    this.setState({
      showUpgrade: false,
      showDowngrade: false,
      showErrorModal: false,
      showError: false,
      showDeleteModal: false,
      showComplete: false,
      showUpComplete: false,
      showEmployeeComplete: false,
      showUserUpdated: false,
      showResetSuccessEmail: false,
      showResetError: false,
      submit: false,
    });
  };

  /* After a submission to change the user's info, return to the user's screen. */
  closeAndReturn = () => {
    this.setState(
      {
        showUpgrade: false,
        showDowngrade: false,
        showErrorModal: false,
        showDeleteModal: false,
        showError: false,
        showComplete: false,
        showUpComplete: false,
        showEmployeeComplete: false,
        showUserUpdated: false,
        showResetSuccessEmail: false,
        showResetError: false,
        submit: false,
      },
      () => {
        this.props.history.goBack();
      }
    );
  };

  /* Save state of child disable component (switches) */
  saveChildState = (state) => {
    this.setState({
      canUpload: state.canUpload,
      canShare: state.canShare,
      canMessage: state.canMessage,
      canViewAllDocs: state.canViewAllDocs,
    });
  };

  /* Handler for page reloads */
  triggerSubmit(e) {
    e.preventDefault();
    this.setState({ submit: true });
  }

  /* Main submission handler for the form data. Shows error or completion modal. */
  handleSubmit() {
    // Update user data here.
    if (
      this.state.user.role === "User" ||
      this.state.user.role === "Customer"
    ) {
      // Selectd user is a user or customer. Update their info accordingly.
      userRef
        .orderByChild("id")
        .equalTo(this.state.user.id)
        .once("child_added", (snapshot) => {
          userRef
            .child(snapshot.key)
            .update({
              name: this.state.name,
              canMessage: this.state.canMessage,
              canUpload: this.state.canUpload,
            })
            .then(() => {
              this.setState({ showUserUpdated: true });
            })
            .catch((e) => {
              console.log(e);
              this.setState({ message: e, showErrorModal: true });
            });
        });
    } else if (this.state.user.role === "Employee") {
      empRef
        .orderByChild("id")
        .equalTo(this.state.user.id)
        .once("child_added", (snapshot) => {
          empRef
            .child(snapshot.key)
            .update({
              name:
                this.state.name.length > 1
                  ? this.state.name
                  : this.state.user.name,
              officeNum:
                this.state.officeNum.length > 6
                  ? this.state.officeNum
                  : this.state.user.officeNum,
              phoneNum:
                this.state.phoneNum.length > 6
                  ? this.state.phoneNum
                  : this.state.user.phoneNum,
              department:
                this.state.department.length > 4
                  ? this.state.department
                  : this.state.user.department,
              canUpload: this.state.canUpload,
              canShare: this.state.canShare,
              canMessage: this.state.canMessage,
              canViewAllDocs: this.state.canViewAllDocs,
            })
            .then(() => {
              this.setState({ showUserUpdated: true });
            })
            .catch((e) => {
              console.log(e);
              this.setState({ message: e, showErrorModal: true });
            });
        });
    } else if (this.state.user.role === "Manager") {
      managerRef
        .orderByChild("id")
        .equalTo(this.state.user.id)
        .once("child_added", (snapshot) => {
          managerRef
            .child(snapshot.key)
            .update({
              name:
                this.state.name.length > 1
                  ? this.state.name
                  : this.state.user.name,
              officeNum:
                this.state.officeNum.length > 6
                  ? this.state.officeNum
                  : this.state.user.officeNum,
              phoneNum:
                this.state.phoneNum.length > 6
                  ? this.state.phoneNum
                  : this.state.user.phoneNum,
              department:
                this.state.department.length > 4
                  ? this.state.department
                  : this.state.user.department,
              canUpload: this.state.canUpload,
              canShare: this.state.canShare,
              canMessage: this.state.canMessage,
              canViewAllDocs: this.state.canViewAllDocs,
            })
            .then(() => {
              this.setState({ showUserUpdated: true });
            })
            .catch((e) => {
              console.log(e);
              this.setState({ message: e, showErrorModal: true });
            });
        });
    } else if (this.state.user.role === "Admin") {
      adminRef
        .orderByChild("id")
        .equalTo(this.state.user.id)
        .once("child_added", (snapshot) => {
          adminRef
            .child(snapshot.key)
            .update({
              name:
                this.state.name.length > 1
                  ? this.state.name
                  : this.state.user.name,
              officeNum:
                this.state.officeNum.length > 6
                  ? this.state.officeNum
                  : this.state.user.officeNum,
              phoneNum:
                this.state.phoneNum.length > 6
                  ? this.state.phoneNum
                  : this.state.user.phoneNum,
              department:
                this.state.department.length > 4
                  ? this.state.department
                  : this.state.user.department,
              canUpload: this.state.canUpload,
              canShare: this.state.canShare,
              canMessage: this.state.canMessage,
              canViewAllDocs: this.state.canViewAllDocs,
            })
            .then(() => {
              this.setState({ showUserUpdated: true });
            })
            .catch((e) => {
              console.log(e);
              this.setState({ message: e, showErrorModal: true });
            });
        });
    }
  }

  /* If removing a user, the admin is prompted with a confirmation modal then 
     this firebase function is invoked to delete them. */
  async removeUser() {
    const userRole = this.state.user.role;
    const userId = this.state.user.id;
    this.setState({ deletedisabled: true, showDeleteModal: false });

    return fetch(DELETE_USER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ uid: userId, role: userRole }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.message === "SUCCESS") {
          this.setState({ deletedisabled: false, showDeleteComplete: true });
        } else {
          this.setState({ deletedisabled: false, showDeleteError: true });
        }
      });
  }

  /* Check if current signed in user is able to upgrade/downgrade selected user */
  checkDisabled(user, action) {
    // console.log("User:", user);
    const userRole = user.role;
    const myRole = localStorage.getItem("myRole");

    console.log(userRole, myRole, action);

    if (userRole === "Admin" && myRole === "Admin" && action === "Downgrade") {
      return false;
    } else if (userRole !== "Admin" && myRole === "Admin") {
      return false;
    } else if (
      userRole === "Manager" &&
      myRole === "Manager" &&
      action === "Downgrade"
    ) {
      return false;
    } else if (
      userRole !== "Manager" &&
      userRole !== "Admin" &&
      myRole === "Manager"
    ) {
      return false;
    } else if (
      userRole === "Employee" &&
      myRole === "Employee" &&
      action === "Downgrade"
    ) {
      return false;
    } else if (
      userRole !== "Customer" &&
      userRole !== "Employee" &&
      userRole !== "Manager" &&
      userRole !== "Admin" &&
      myRole === "Employee"
    ) {
      return false;
    } else if (
      userRole === "Customer" &&
      myRole === "Employee" &&
      action === "Downgrade"
    ) {
      return false;
    }

    // Admins cannot upgrade admins any further
    // Managers cannot upgrade managers
    // Employee's cannot upgrade employees or make customers employees
    return true;
  }

  /* Main render method of component. */
  render() {
    const myId = this.props.location.state.myId;
    const myRole = localStorage.getItem("myRole");

    return (
      <>
        <Header history={this.props.history} />

        {/* show user profile picture to the left and then a form with all of their changable data. */}
        <div style={styles.maincontainer}>
          {/* Should have a back button at the top of the screen. */}
          <CustomButtonDark
            color="inherit"
            onClick={() => this.props.history.goBack()}
          >
            Back
          </CustomButtonDark>

          {/* Show spinner for when a user is being deleted. */}
          {this.state.deletedisabled && (
            <div style={styles.progresscontainer}>
              <div style={styles.progressseccontainer}>
                <CircularProgress />
              </div>
            </div>
          )}
          <div style={styles.contentcontainer}>
            {/* Avatar and name and email area here */}
            <div style={styles.fullheight}>
              <Avatar
                alt={this.state.user.name}
                src={this.state.user.avatar}
                style={styles.useravatar}
              />

              {/* Add stuff here for disabling messaging and sharing documents, etc */}
              <DisableSwitches
                user={this.state.user}
                myId={myId}
                userId={this.state.user.id}
                userRole={this.state.user.role}
                isEmp={
                  this.state.user.role === "Employee" ||
                  this.state.user.role === "Manager" ||
                  this.state.user.role === "Admin"
                    ? true
                    : false
                }
                saveState={this.saveChildState}
                canMessage={this.state.canMessage}
                canShare={this.state.canShare}
                canUpload={this.state.canUpload}
                canViewAllDocs={this.state.canViewAllDocs}
                isUser={this.state.user.role === "User" ? true : false}
                isCustomer={this.state.user.role === "Customer" ? true : false}
              />
              <Button
                color="primary"
                variant="contained"
                style={styles.button}
                onClick={() => {
                  this.props.history.push({
                    pathname: "userdocs",
                    state: { user: this.state.user, myId: myId },
                  });
                }}
              >
                View Documents
              </Button>
              <ResetEmail
                email={this.state.user.email}
                showSuccess={() =>
                  this.setState({ showResetSuccessEmail: true })
                }
                showError={() => this.setState({ showResetError: true })}
              />
              <Button
                color="secondary"
                variant="contained"
                style={styles.button}
                disabled={
                  this.state.deletedisabled || myRole === "Admin" ? false : true
                }
                onClick={() => this.setState({ showDeleteModal: true })}
              >
                Delete User
              </Button>
            </div>
            <div style={styles.formcontainer}>
              <form onSubmit={this.triggerSubmit} style={styles.form}>
                <h1 style={styles.formheader}>Edit Profile</h1>
                <hr style={styles.userrightbutton} />

                <InputLabel style={styles.label}>Name</InputLabel>
                <CustomTextField
                  id="outlined-basic"
                  style={styles.usertextinput}
                  variant="outlined"
                  type="text"
                  value={this.state.name}
                  placeholder={this.state.user.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                {this.state.user.role !== "User" &&
                  this.state.user.role !== "Customer" && (
                    <>
                      {this.state.user.id === myId && (
                        <>
                          <InputLabel style={styles.label}>PIN</InputLabel>
                          <CustomTextField
                            id="outlined-basic"
                            style={styles.usertextinput}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            inputProps={{ maxLength: 6 }}
                            value={this.state.PIN}
                            placeholder={this.state.user.PIN}
                            onChange={(e) =>
                              this.setState({ PIN: e.target.value })
                            }
                          />
                        </>
                      )}

                      <InputLabel style={styles.label}>
                        Office Number
                      </InputLabel>
                      <CustomTextField
                        id="outlined-basic"
                        style={styles.usertextinput}
                        variant="outlined"
                        type="text"
                        value={this.state.officeNum}
                        placeholder={this.state.user.officeNum}
                        onChange={(e) =>
                          this.setState({ officeNum: e.target.value })
                        }
                      />
                      <InputLabel style={styles.label}>Phone Number</InputLabel>
                      <CustomTextField
                        id="outlined-basic"
                        style={styles.usertextinput}
                        variant="outlined"
                        type="text"
                        value={this.state.phoneNum}
                        placeholder={this.state.user.phoneNum}
                        onChange={(e) =>
                          this.setState({ phoneNum: e.target.value })
                        }
                      />

                      {/* This needs to look good in the dark */}
                      <FormControl
                        variant="filled"
                        style={styles.userrightbutton}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Department
                        </InputLabel>
                        <CustomSelect
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          value={this.state.department}
                          onChange={(e) =>
                            this.setState({ department: e.target.value })
                          }
                        >
                          <MenuItem value="Sales">Sales</MenuItem>
                          <MenuItem value="Service">Service</MenuItem>
                          <MenuItem value="Parts">Parts</MenuItem>
                          <MenuItem value="Body Shop">Body Shop</MenuItem>
                          <MenuItem value="Office">Office</MenuItem>
                        </CustomSelect>
                      </FormControl>
                    </>
                  )}

                <hr style={styles.userrightbutton} />

                {/* Logic for upgrading and degrading a user */}
                <div style={styles.userbuttoncontainer}>
                  <CustomButton2Dark
                    variant="contained"
                    color="default"
                    disabled={this.checkDisabled(this.state.user, "Downgrade")}
                    style={styles.userleftbutton}
                    onClick={() => this.setState({ showDowngrade: true })}
                  >
                    Downgrade
                  </CustomButton2Dark>
                  <CustomButtonDark
                    variant="contained"
                    color="default"
                    disabled={this.checkDisabled(this.state.user, "Upgrade")}
                    style={styles.userrightbutton}
                    onClick={() => this.setState({ showUpgrade: true })}
                  >
                    Upgrade
                  </CustomButtonDark>
                </div>

                <CustomButtonDark
                  variant="contained"
                  color="default"
                  type="submit"
                  disabled={
                    (this.state.myRole !== "Admin" &&
                      this.state.user.role === "Admin") ||
                    (this.state.myRole === "Employee" &&
                      this.state.user.role === "Manager") ||
                    (this.state.myRole === "Manager" &&
                      this.state.user.role === "Manager")
                  }
                >
                  Submit
                </CustomButtonDark>
              </form>
            </div>
          </div>
        </div>

        {/* Show modals when appropriate. */}

        {this.state.submit && (
          <UpdateUser close={this.closeModals} confirm={this.handleSubmit} />
        )}

        {this.state.showDowngrade && (
          <Downgrade
            myId={myId}
            userId={this.state.user.id}
            myRole={myRole}
            userRole={this.state.user.role}
            close={this.closeModals}
            showError={(e) =>
              this.setState({ showErrorModal: true, message: e })
            }
            showComplete={() => this.setState({ showComplete: true })}
          />
        )}

        {this.state.showUpgrade && (
          <Upgrade
            myId={myId}
            userId={this.state.user.id}
            myRole={myRole}
            userRole={this.state.user.role}
            close={this.closeModals}
            showError={(e) =>
              this.setState({ showErrorModal: true, message: e })
            }
            showComplete={() =>
              this.setState({ showUpComplete: true, showUpgrade: false })
            }
            showEmployeeComplete={() =>
              this.setState({ showEmployeeComplete: true, showUpgrade: false })
            }
          />
        )}

        {this.state.showUpComplete && (
          <CompleteUpgradeModal
            name={this.state.user.name}
            close={this.closeAndReturn}
          />
        )}

        {this.state.showComplete && (
          <CompleteDowngradeModal
            name={this.state.user.name}
            close={this.closeAndReturn}
          />
        )}

        {this.state.showErrorModal && (
          <ErrorModal close={this.closeModals} message={this.state.message} />
        )}

        {this.state.showUserUpdated && (
          <UserUpdateSuccess
            close={this.closeAndReturn}
            name={this.state.user.name}
          />
        )}

        {this.state.showEmployeeComplete && (
          <EmployeeEntryForm
            userId={this.state.user.id}
            close={this.closeModals}
            showComplete={() =>
              this.setState({
                showUpComplete: true,
                showEmployeeComplete: false,
              })
            }
            showError={() =>
              this.setState({
                showErrorModal: true,
                showEmployeeComplete: false,
              })
            }
          />
        )}

        {this.state.showDeleteModal && (
          <DeleteUserModal
            myRole={myRole}
            myId={myId}
            close={this.closeModals}
            showComplete={this.removeUser}
            showError={() =>
              this.setState({ showErrorModal: true, showDeleteModal: false })
            }
          />
        )}

        {this.state.showDeleteComplete && (
          <ShowDeleteCompleteModal close={() => this.props.history.goBack()} />
        )}

        {this.state.showResetSuccessEmail && (
          <ResetSuccessModal close={this.closeModals} user={this.state.user} />
        )}

        {this.state.showResetError && <ResetError close={this.closeModals} />}
      </>
    );
  }
}
