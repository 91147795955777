import React from "react";
import { Modal, Backdrop, Fade, Paper } from "@material-ui/core";
import { CustomButton, CustomButton2 } from "../Custom/CustomComponents";
import styles from "../../Styles/UnarchivePromos";

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class CompleteModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.modal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.modalpaper}>
            <h1 style={styles.modalpaperheader}>
              This prepaid-service has been unarchived and updated.
            </h1>
            <h3 style={styles.modalpaperheader}>
              It will be available for Admins and Managers to give out shortly.
            </h3>
            <hr style={styles.modalbutton} />
            <div>
              <CustomButton2
                variant="contained"
                color="default"
                style={styles.modalbutton}
                onClick={() => this.props.close()}
              >
                CLOSE
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * If an error occurs during the create user process, this modal gets displayed.
 */
export class ErrorModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.modal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.modalpaper}>
            <h2 style={styles.modalpaperheader}>
              Something went wrong with unarchiving this prepaid-service. Please
              try again later.
            </h2>

            <hr style={styles.modalbutton} />
            <CustomButton
              color="default"
              variant="contained"
              style={styles.modalbutton}
              onClick={() => this.props.close()}
            >
              CLOSE
            </CustomButton>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}
