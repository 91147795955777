import colors from "./Colors";

const styles = {
  maincontainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.white,
  },
  loadingtext: {
    textAlign: "center",
    color: colors.darkgray,
  },
};

export default styles;
