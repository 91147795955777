import React from "react";
import { CircularProgress } from "@material-ui/core";
import styles from "../../Styles/Loading";

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => this.props.show(), 2000);
  }

  render() {
    return (
      <div style={styles.maincontainer}>
        <CircularProgress />
        <h2 style={styles.loadingtext}>Generating reports...</h2>
      </div>
    );
  }
}
