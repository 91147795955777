import colors from "./Colors";

const styles = {
  maincontainer: {
    height: "100%",
    minHeight: "100vh",
    backgroundColor: colors.white,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    textDecoration: "none",
  },
  linktitle: {
    textAlign: "center",
    color: colors.black,
  },
};

export default styles;
