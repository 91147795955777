/* eslint-disable eqeqeq */
import React from "react";
import {
  userRef,
  adminRef,
  empRef,
  managerRef,
} from "../../Firebase/FirebaseConfig";
import {
  LinearProgress,
  Paper,
  Modal,
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { PINAlert } from "../Alerts";
import styles from "../../Styles/Modal";
import {
  CustomButton,
  CustomButton2,
  CustomTextField,
  CustomSelect,
} from "../Custom/CustomComponents";

/*
 * Modal to upgrade a user to the next possible role.
 */
export class Upgrade extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: false };
    this.lookupPin = this.lookupPin.bind(this);
  }

  /* Find the employee pin and upgrade the user. */
  lookupPin(e) {
    e.preventDefault();

    const pin = this.state.PIN;

    if (this.props.myRole === "Employee") {
      empRef
        .orderByChild("id")
        .equalTo(this.props.myId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == pin) {
            // emp pin is same as one added.
            this.upgradeUser();
          }
        });
    } else if (this.props.myRole === "Manager") {
      managerRef
        .orderByChild("id")
        .equalTo(this.props.myId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == pin) {
            // manager pin is same as one added
            this.upgradeUser();
          }
        });
    } else if (this.props.myRole === "Admin") {
      adminRef
        .orderByChild("id")
        .equalTo(this.props.myId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == pin) {
            // admin pin is same as one added
            this.upgradeUser();
          }
        });
    } else {
      // Authed user should not be here
      let message =
        "You need to be an employee, manager, or admin to perform this action.";
      this.props.showError(message);
    }
  }

  /* Find the selected user in realtimedb and upgrade them to the next available role. */
  upgradeUser() {
    // Creating a customer/employee pin
    let userpin = Math.floor(100000 + Math.random() * 900000);
    userpin = userpin.toString();

    if (this.props.userRole === "User") {
      // If we are upgrading a user to a customer
      // Search for selected user in database.
      userRef
        .orderByChild("id")
        .equalTo(this.props.userId)
        .once("child_added", (snapshot) => {
          if (snapshot.val()) {
            // Update proper user roles.
            userRef
              .child(snapshot.key)
              .update({
                role: "Customer",
                PIN: userpin,
                rewardsPoints: 1,
                canUpload: true,
                canShare: false,
                canMessage: snapshot.val().canMessage,
              })
              .then(() => {
                // User was updated properly.
                this.props.showComplete();
              })
              .catch((e) => {
                console.log(e);
                this.props.showError(e);
              });
          }
        });
    } else if (this.props.userRole === "Customer") {
      // If we are upgrading a customer to an employee
      userRef
        .orderByChild("id")
        .equalTo(this.props.userId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists()) {
            empRef
              .push({
                role: "Employee",
                PIN: snapshot.val().PIN,
                id: snapshot.val().id,
                avatar: snapshot.val().avatar,
                name: snapshot.val().name,
                email: snapshot.val().email,
                fcmToken: snapshot.val().fcmToken,
                officeNum: "",
                phoneNum: "",
                department: "",
                rewardsPoints: 0,
                private_files: snapshot.val().private_files
                  ? snapshot.val().private_files
                  : {},
                private_nonimgfiles: snapshot.val().private_nonimgfiles
                  ? snapshot.val().private_nonimgfiles
                  : {},
                canUpload: snapshot.val().canUpload,
                canShare: true,
                canMessage: snapshot.val().canMessage,
                canViewAllDocs: true,
              })
              .then(() => {
                userRef.child(snapshot.key).remove();
                this.props.showEmployeeComplete();
              })
              .catch((e) => {
                console.log(e);
                this.props.showError(e);
              });
          }
        });
    } else if (
      this.props.userRole === "Employee" &&
      (this.props.myRole === "Manager" || this.props.myRole === "Admin")
    ) {
      // If we are upgrading an employee to a manager

      // Find employee in database
      empRef
        .orderByChild("id")
        .equalTo(this.props.userId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists()) {
            // Push their data to the manager node in realtime db
            managerRef
              .push({
                role: "Manager",
                PIN: snapshot.val().PIN,
                avatar: snapshot.val().avatar,
                name: snapshot.val().name,
                email: snapshot.val().email,
                id: snapshot.val().id,
                rewardsPoints: 0,
                fcmToken: snapshot.val().fcmToken,
                officeNum: snapshot.val().officeNum,
                phoneNum: snapshot.val().phoneNum,
                department: snapshot.val().department,
                private_files: snapshot.val().private_files
                  ? snapshot.val().private_files
                  : {},
                private_nonimgfiles: snapshot.val().private_nonimgfiles
                  ? snapshot.val().private_nonimgfiles
                  : {},
                canUpload: snapshot.val().canUpload,
                canShare: snapshot.val().canShare,
                canMessage: snapshot.val().canMessage,
                canViewAllDocs: snapshot.val().canViewAllDocs,
              })
              .then(() => {
                empRef.child(snapshot.key).remove();
                this.props.showComplete();
              })
              .catch((e) => {
                console.log(e);
                this.props.showError(e);
              });
          }
        });
    } else if (
      this.props.userRole === "Manager" &&
      (this.props.myRole === "Manager" || this.props.myRole === "Admin")
    ) {
      // If we are upgrading a manager to admin

      // Find manager in manager node
      managerRef
        .orderByChild("id")
        .equalTo(this.props.userId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists()) {
            // Push their data to the admin node in realtime db
            adminRef
              .push({
                role: "Admin",
                PIN: snapshot.val().PIN,
                avatar: snapshot.val().avatar,
                name: snapshot.val().name,
                email: snapshot.val().email,
                id: snapshot.val().id,
                rewardsPoints: 0,
                fcmToken: snapshot.val().fcmToken,
                officeNum: snapshot.val().officeNum,
                phoneNum: snapshot.val().phoneNum,
                department: snapshot.val().department,
                private_files: snapshot.val().private_files
                  ? snapshot.val().private_files
                  : {},
                private_nonimgfiles: snapshot.val().private_nonimgfiles
                  ? snapshot.val().private_nonimgfiles
                  : {},
                canUpload: snapshot.val().canUpload,
                canShare: snapshot.val().canShare,
                canMessage: snapshot.val().canMessage,
                canViewAllDocs: snapshot.val().canViewAllDocs,
              })
              .then(() => {
                managerRef.child(snapshot.key).remove();
                this.props.showComplete();
              })
              .catch((e) => {
                console.log(e);
                this.props.showError(e);
              });
          }
        });
    } else {
      // Authed user should not be here
      let message =
        "You need to be an employee, manager, or admin to perform this action.";
      this.props.showError(message);
    }
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.showAlert && (
              <PINAlert close={() => this.setState({ showAlert: false })} />
            )}

            <Paper elevation={3} style={styles.confirmdocmodalcontainer}>
              <h1 style={styles.uploadmodalheader}>
                Are you sure you want to upgrade this user?
              </h1>
              <h3 style={styles.uploadmodalheader}>
                If so, please enter your employee pin below.
              </h3>
              <form
                onSubmit={this.lookupPin}
                style={styles.confirmdocmodalform}
              >
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  style={styles.fullwidth}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.progressbar} />
                {this.state.visible && (
                  <LinearProgress
                    color="secondary"
                    style={styles.confirmdocprogressbar}
                  />
                )}
                <div style={styles.confirmdocbuttoncontainer}>
                  <CustomButton
                    variant="contained"
                    color="default"
                    style={styles.confirmdocleftbutton}
                    onClick={() => this.props.close()}
                  >
                    Close
                  </CustomButton>

                  <CustomButton2
                    variant="contained"
                    color="default"
                    disabled={this.state.visible}
                    style={styles.progressbar}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Modal to downgrade a user to the next available role.
 */
export class Downgrade extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: false };

    this.lookupPin = this.lookupPin.bind(this);
  }

  /* Find employee in database, check their pin, then downgrade the user. */
  lookupPin(e) {
    e.preventDefault();
    const pin = this.state.PIN;
    if (this.props.myRole === "Employee") {
      empRef
        .orderByChild("id")
        .equalTo(this.props.myId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == pin) {
            // emp pin is same as one added.
            this.downgradeUser();
          }
        });
    } else if (this.props.myRole === "Manager") {
      managerRef
        .orderByChild("id")
        .equalTo(this.props.myId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == pin) {
            // manager pin is same as one added
            this.downgradeUser();
          }
        });
    } else if (this.props.myRole === "Admin") {
      adminRef
        .orderByChild("id")
        .equalTo(this.props.myId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == pin) {
            // admin pin is same as one added
            this.downgradeUser();
          }
        });
    } else {
      // Authed user should not be here
      let message =
        "You need to be an employee, manager, or admin to perform this action.";
      this.props.showError(message);
    }
  }

  /* Find the selected user in realtimedb and downgrade them to the next available role. */
  downgradeUser() {
    if (this.props.userRole === "Customer") {
      // If we are demoting a customer to a user
      userRef
        .orderByChild("id")
        .equalTo(this.props.userId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists()) {
            userRef.child(snapshot.key).update({
              role: "User",
              canUpload: false,
              canShare: false,
              canMessage: true,
              canViewAllDocs: false,
            });
            userRef.child(snapshot.key).child("PIN").remove();
            userRef
              .child(snapshot.key)
              .child("rewardsPoints")
              .remove()
              .then(() => {
                this.props.showComplete();
              })
              .catch((e) => {
                console.log(e);
                this.props.showError(e);
              });
          }
        });
    } else if (
      this.props.userRole === "Employee" &&
      (this.props.myRole === "Manager" || this.props.myRole === "Admin")
    ) {
      // If we are demoting an employee to a manager

      // Find employee in database
      empRef
        .orderByChild("id")
        .equalTo(this.props.userId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists()) {
            // Push their data to the user node in realtime db
            userRef
              .push({
                role: "Customer",
                PIN: snapshot.val().PIN,
                avatar: snapshot.val().avatar,
                name: snapshot.val().name,
                email: snapshot.val().email,
                id: snapshot.val().id,
                rewardsPoints: 0,
                fcmToken: snapshot.val().fcmToken,
                private_files: snapshot.val().private_files
                  ? snapshot.val().private_files
                  : {},
                private_nonimgfiles: snapshot.val().private_nonimgfiles
                  ? snapshot.val().private_nonimgfiles
                  : {},
                canUpload: true,
                canShare: false,
                canMessage: true,
                canViewAllDocs: false,
              })
              .then(() => {
                empRef.child(snapshot.key).remove();
                this.props.showComplete();
              })
              .catch((e) => {
                console.log(e);
                this.props.showError(e);
              });
          }
        });
    } else if (
      this.props.userRole === "Manager" &&
      (this.props.myRole === "Manager" || this.props.myRole === "Admin")
    ) {
      // If we are demoting a manager to an employee

      // Find manager in manager node
      managerRef
        .orderByChild("id")
        .equalTo(this.props.userId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists()) {
            // Push their data to the employee node in realtime db
            empRef
              .push({
                role: "Employee",
                PIN: snapshot.val().PIN,
                avatar: snapshot.val().avatar,
                name: snapshot.val().name,
                email: snapshot.val().email,
                id: snapshot.val().id,
                rewardsPoints: 0,
                fcmToken: snapshot.val().fcmToken,
                officeNum: snapshot.val().officeNum,
                phoneNum: snapshot.val().phoneNum,
                department: snapshot.val().department,
                private_files: snapshot.val().private_files
                  ? snapshot.val().private_files
                  : {},
                private_nonimgfiles: snapshot.val().private_nonimgfiles
                  ? snapshot.val().private_nonimgfiles
                  : {},
                canUpload: snapshot.val().canUpload,
                canShare: snapshot.val().canShare,
                canMessage: snapshot.val().canMessage,
                canViewAllDocs: snapshot.val().canViewAllDocs,
              })
              .then(() => {
                managerRef.child(snapshot.key).remove();
                this.props.showComplete();
              })
              .catch((e) => {
                console.log(e);
                this.props.showError(e);
              });
          }
        });
    } else if (
      this.props.userRole === "Admin" &&
      this.props.myRole === "Admin"
    ) {
      // If we are demoting an admin to a manager

      // Find admin in admin node
      adminRef
        .orderByChild("id")
        .equalTo(this.props.userId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists()) {
            managerRef
              .push({
                role: "Manager",
                PIN: snapshot.val().PIN,
                avatar: snapshot.val().avatar,
                name: snapshot.val().name,
                email: snapshot.val().email,
                id: snapshot.val().id,
                rewardsPoints: 0,
                fcmToken: snapshot.val().fcmToken,
                officeNum: snapshot.val().officeNum,
                phoneNum: snapshot.val().phoneNum,
                department: snapshot.val().department,
                private_files: snapshot.val().private_files
                  ? snapshot.val().private_files
                  : {},
                private_nonimgfiles: snapshot.val().private_nonimgfiles
                  ? snapshot.val().private_nonimgfiles
                  : {},
                canUpload: snapshot.val().canUpload,
                canShare: snapshot.val().canShare,
                canMessage: snapshot.val().canMessage,
                canViewAllDocs: snapshot.val().canViewAllDocs,
              })
              .then(() => {
                adminRef.child(snapshot.key).remove();
                this.props.showComplete();
              })
              .catch((e) => {
                console.log(e);
                this.props.showError(e);
              });
          }
        });
    } else {
      // Authed user should not be here
      let message =
        "You need to be an employee, manager, or admin to perform this action.";
      this.props.showError(message);
    }
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.showAlert && (
              <PINAlert close={() => this.setState({ showAlert: false })} />
            )}

            <Paper elevation={3} style={styles.confirmdocmodalcontainer}>
              <h1 style={styles.uploadmodalheader}>
                Are you sure you want to downgrade this user?
              </h1>
              <h3 style={styles.uploadmodalheader}>
                If so, please enter your employee pin below.
              </h3>
              <form
                onSubmit={this.lookupPin}
                style={styles.confirmdocmodalform}
              >
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.progressbar} />
                {this.state.visible && (
                  <LinearProgress
                    color="secondary"
                    style={styles.confirmdocprogressbar}
                  />
                )}
                <div style={styles.confirmdocbuttoncontainer}>
                  <CustomButton
                    variant="contained"
                    color="default"
                    style={styles.confirmdocleftbutton}
                    onClick={() => this.props.close()}
                  >
                    Close
                  </CustomButton>

                  <CustomButton2
                    variant="contained"
                    color="default"
                    disabled={this.state.visible}
                    style={styles.progressbar}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a user is upgraded.
 */
export class CompleteUpgradeModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              {this.props.name} has been upgraded.
            </h1>
            <div>
              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a user is successfully downgraded.
 */
export class CompleteDowngradeModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              {this.props.name} has been downgraded.
            </h1>
            <div>
              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * If an error occurs during the upgrade/downgrade process, show this modal.
 */
export class ErrorModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              There was an error processing your request.
            </h1>
            <h1 style={styles.addpromoheader}>{this.props.message}</h1>
            <h1 style={styles.addpromoheader}>Please try again later.</h1>
            <div>
              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * If the user was successfully upgraded, display this modal.
 */
export class UserUpdateSuccess extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              {this.props.name}'s account has been updated.
            </h1>
            <div>
              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Form that shows the necessary fields (department, officeNum, phoneNum) of an employee
 * when a customer is upgraded.
 */
export class EmployeeEntryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { department: "Sales" };

    this.updateEmployee = this.updateEmployee.bind(this);
  }

  /* Find selected employee in realtimedb and update their department, phonenum, and officenum */
  updateEmployee(e) {
    e.preventDefault();

    empRef
      .orderByChild("id")
      .equalTo(this.props.userId)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          empRef
            .child(snapshot.key)
            .update({
              phoneNum: this.state.phoneNum,
              officeNum: this.state.officeNum,
              department: this.state.department,
            })
            .then(() => {
              this.props.showComplete();
            })
            .catch((e) => {
              console.log(e);
              this.props.showError();
            });
        }
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h2 style={styles.userempupgradeheader}>
              Please enter the office number, phone number, and department of
              this employee.
            </h2>

            <hr style={styles.usermodalhr} />

            {/* form stuff */}
            <form
              onSubmit={this.updateEmployee}
              style={styles.userempupgradeform}
            >
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="tel"
                style={styles.userempspacing}
                inputProps={{ maxLength: 16 }}
                value={this.state.officeNum}
                placeholder="000-000-0000"
                onChange={(e) => this.setState({ officeNum: e.target.value })}
              />
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="tel"
                style={styles.userempspacing}
                inputProps={{ maxLength: 16 }}
                value={this.state.phoneNum}
                placeholder="000-000-0000"
                onChange={(e) => this.setState({ phoneNum: e.target.value })}
              />
              <FormControl variant="filled" style={styles.userempformcontrol}>
                <InputLabel id="demo-simple-select-filled-label">
                  Department
                </InputLabel>
                <CustomSelect
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={this.state.department}
                  onChange={(e) =>
                    this.setState({ department: e.target.value })
                  }
                >
                  <MenuItem value="Sales">Sales</MenuItem>
                  <MenuItem value="Service">Service</MenuItem>
                  <MenuItem value="Parts">Parts</MenuItem>
                  <MenuItem value="Body Shop">Body Shop</MenuItem>
                  <MenuItem value="Office">Office</MenuItem>
                </CustomSelect>
              </FormControl>

              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                type="submit"
              >
                Close
              </CustomButton>
            </form>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}
