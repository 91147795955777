import colors from "./Colors";

const styles = {
  input: {
    marginBottom: 10,
  },
  header: {
    textAlign: "center",
  },
  addpromomodal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addpromocontainer: {
    backgroundColor: colors.white,
    display: "flex",
    padding: 20,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  addpromoform: {
    display: "flex",
    flexDirection: "column",
  },
  addpromoheader: {
    textAlign: "center",
    color: colors.black,
  },
  addpromotextfield: {
    marginTop: 10,
    marginBottom: 10,
  },
  addpromolabel: {
    color: colors.black,
  },
  addpromocalendar: {
    marginTop: 10,
    marginBottom: 10,
    cursor: "pointer",
  },
  editpromocontainer: {
    width: 600,
    padding: 20,
    backgroundColor: colors.white,
  },
  editpromoform: {
    display: "flex",
    flexDirection: "column",
  },
  editpromobutton: {
    width: "100%",
  },
  editpromobuttoncontainer: {
    margin: 20,
  },
  deletepromocontainer: {
    width: 350,
    padding: 20,
    backgroundColor: colors.white,
  },
  deleteconfirmmodalform: {
    display: "flex",
    flexDirection: "column",
  },
  deletepromoheader: {
    textAlign: "center",
    color: colors.black,
  },
  deleteunderline: {
    width: "100%",
  },
  deletebutton1: {
    width: "100%",
    marginRight: 5,
  },
  deletebutton: {
    width: "100%",
  },
  deletebuttoncontainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  disableContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cancelIcon: {
    transform: "scale(1.5)",
  },
  disablebuttoncontainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  // Upload document modal
  uploadmodal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadmodalcontainer: {
    width: 600,
    padding: 20,
    backgroundColor: colors.white,
  },
  uploadmodalheader: {
    textAlign: "center",
    color: colors.black,
  },
  uploadmodaltext: {
    color: colors.black,
  },
  progressbar: {
    width: "100%",
  },
  uploadmodalform: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
  },
  uploadmodalbuttoncontainer: {
    margin: 20,
    display: "flex",
    flexDirection: "row",
  },
  uploadmodalbutton1: {
    width: "100%",
    marginRight: 5,
  },
  uploadmodalbutton: {
    width: "100%",
  },
  fullwidth: {
    width: "100%",
  },

  // Confirmation document modal
  confirmdocmodalcontainer: {
    width: 400,
    padding: 20,
    backgroundColor: colors.white,
  },
  confirmdocmodalform: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  confirmdocprogressbar: {
    width: "100%",
    marginBottom: 5,
  },
  confirmdocleftbutton: {
    width: "100%",
    marginRight: 5,
  },
  confirmdocbuttoncontainer: {
    display: "flex",
    flexDirection: "row",
  },
  confirmdocbuttonbottomcontainer: {
    display: "flex",
    flexDirection: "column",
  },
  confirmdocbutton: {
    marginBottom: 5,
    width: "100%",
  },
  confirmtextfield: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  // User modals
  usermodal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  usercreationmodal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "scroll",
    padding: 100,
    paddingTop: 200,
    overflowY: "auto",
  },
  usermodalcontainer: {
    width: 600,
    padding: 20,
    backgroundColor: colors.white,
    overflowY: "auto",
  },
  usermodalsmallcontainer: {
    width: 300,
    padding: 20,
    backgroundColor: colors.white,
  },
  usermodalform: {
    display: "flex",
    flexDirection: "column",
  },
  usermodalradiogroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  usermodaltext: {
    textAlign: "center",
    color: colors.black,
  },
  usermodalhr: {
    width: "100%",
  },
  userlinear: {
    width: "100%",
    marginBottom: 10,
    marginTop: 5,
  },
  usermodalbuttoncontainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  usermodalbutton1: {
    width: "100%",
    marginRight: 5,
  },
  usermodalbutton: {
    width: "100%",
  },
  userempupgradeheader: {
    textAlign: "left",
    color: colors.black,
  },
  userempupgradeform: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  userempspacing: {
    marginBottom: 20,
  },
  userempformcontrol: {
    width: "100%",
    marginBottom: 20,
  },

  // Document modal stuff here
  documentmodal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  documentmodalcontainer: {
    width: 400,
    padding: 20,
    backgroundColor: colors.white,
  },
  documentmodalheader: {
    textAlign: "center",
    color: colors.black,
  },
  documentmodalbutton: {
    width: "100%",
  },
};

export default styles;
