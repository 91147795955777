import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Link,
  ButtonGroup,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import {
  auth,
  userRef,
  empRef,
  managerRef,
  adminRef,
} from '../Firebase/FirebaseConfig';

// Import Components
import {
  GivePromoModal,
  ShowCompletionModal,
  CreateUserModal,
  ShowErrorModal,
  ShowUserAddedModal,
} from '../Components/Modals/UserModals';
import Header from '../Components/Header';
import {
  CustomButton,
  CustomButton2Dark,
  CustomButtonDark,
  CustomTextField,
} from '../Components/Custom/CustomComponents';
import { getCurrentData } from '../Helpers/Data';

// import styles
import styles from '../Styles/Screens';

/*
 * This screen lists all users/customers/employees/managers/admins from realtimedb.
 * From here, an employee can message or edit someone's profile info (restrictions apply)
 */
export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filteredArray: [],
      showUserAddedModal: false,
      showErrorModal: false,
      selected: 'users',
      myId: null,
      myData: { email: '', fcmToken: '', role: '', avatar: '', id: '' },
    };

    this.showUserAddedModal = this.showUserAddedModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.showErrorModal = this.showErrorModal.bind(this);
  }

  /* Check auth status after first render, then get all users. */
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const role = localStorage.getItem('usersRole');
        if (role === 'Employee') {
          this.getEmployees(user.uid);
        } else if (role === 'Manager') {
          this.getManagers(user.uid);
        } else if (role === 'Admin') {
          this.getAdmins(user.uid);
        } else {
          this.getUsers(user.uid);
        }
        this.getMyData(user.email);
      } else {
        this.props.history.push('/login');
      }
    });
  }

  /* Get all users from realtimedb. */
  getUsers(uid) {
    const temp = [];
    userRef.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        if (!temp.includes(snap.val())) {
          temp.push(snap.val());
        }
      });
      this.setState({ users: temp, myId: uid, selected: 'users' });
    });
  }

  /* Get all employees from realtimedb. */
  getEmployees(myId) {
    const temp = [];
    empRef.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        if (!temp.includes(snap.val())) {
          temp.push(snap.val());
        }
      });
      this.setState({ users: temp, selected: 'emps', myId: myId });
    });
  }

  /* Get all managers from realtimedb. */
  getManagers(myId) {
    let temp = [];
    managerRef.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        if (!temp.includes(snap.val())) {
          temp.push(snap.val());
        }
      });
      this.setState({ users: temp, selected: 'managers', myId: myId });
    });
  }

  /* Get all admins from realtimedb. */
  getAdmins(myId) {
    let temp = [];
    adminRef.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        if (!temp.includes(snap.val())) {
          temp.push(snap.val());
        }
      });
      this.setState({ users: temp, selected: 'admins', myId: myId });
    });
  }

  /* Get all of my data so the system knows what role you have and so it can send it to 
     the messaging/userinfo components. */
  getMyData(email) {
    getCurrentData(email)
      .then((result) => {
        this.setState({ myData: result });
      })
      .catch((error) => {
        console.log(error);
        // Show error popup here.
      });
  }

  /* Handler for when search is updated. */
  updateSearch = (search) => {
    var updatedData = [];
    if (search === null || search === undefined || search === '') {
      updatedData = [];
    } else if (typeof search == 'object') {
      updatedData.push(search);
    } else {
      // User didn't click on a card, show all cases of users with first name
      // relative to the search.
      this.state.users.forEach((element) => {
        let lower = element.name.toLowerCase();
        let email = element.email.toLowerCase();

        if (
          lower.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase())
        ) {
          updatedData.push(element);
        }
      });
    }
    this.setState({ filteredArray: updatedData });
  };

  /* If coming here from the promotions screen, onclick show a confirmation modal that 
     you want to give this promotion to a user. */
  determineOnclick(user) {
    if (this.props.action === 'GivePromo') {
      this.setState({ showGivePromoModal: true });
    } else if (this.props.action === 'None') {
    } else if (this.props.action === undefined) {
      this.props.history.push({
        pathname: 'userinfo',
        state: { user: user, myId: this.state.myId },
      });
    }
  }

  /* Set localstorage back to normal. */
  componentWillUnmount() {
    localStorage.setItem('usersRole', 'User');
  }

  /* Close all modals. */
  closeModals = () => {
    this.setState({
      showGivePromoModal: false,
      showCompletionModal: false,
      showAddUserModal: false,
      showErrorModal: false,
      showUserAddedModal: false,
    });
  };

  /* Show a completion modal for when a user has been successfully added to the database. */
  showUserAddedModal = () => {
    this.setState({ showUserAddedModal: true });
  };

  /* If something goes wrong, show error modal to client. */
  showErrorModal = () => {
    this.setState({ showErrorModal: true });
  };

  /* Sorting handler for filtering out data properly. */
  sortData = (sort, comparator) => {
    console.log(sort, comparator);

    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.users;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    // Sort should be a string of the selected column
    if (sort === 'Name' && comparator === 'asc') {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'asc', sort: sort })
        : this.setState({ users: array, filter: 'asc', sort: sort });
    } else if (sort === 'Name' && comparator === 'desc') {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'desc', sort: sort })
        : this.setState({ users: array, filter: 'desc', sort: sort });
    } else if (sort === 'Email' && comparator === 'asc') {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'asc', sort: sort })
        : this.setState({ users: array, filter: 'asc', sort: sort });
    } else if (sort === 'Email' && comparator === 'desc') {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'desc', sort: sort })
        : this.setState({ users: array, filter: 'desc', sort: sort });
    } else if (sort === 'Role' && comparator === 'asc') {
      array = array.sort((a, b) => {
        if (a.role.toLowerCase() > b.role.toLowerCase()) {
          // no/yes
          return 1;
        }
        if (a.role.toLowerCase() === b.role.toLowerCase()) {
          // no/no yes/yes
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
        }
        if (a.role.toLowerCase() < b.role.toLowerCase()) {
          // yes/no
          return -1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'asc', sort: sort })
        : this.setState({ users: array, filter: 'asc', sort: sort });
    } else if (sort === 'Role' && comparator === 'desc') {
      array = array.sort((a, b) => {
        if (a.role.toLowerCase() < b.role.toLowerCase()) {
          // no/yes
          return 1;
        }
        if (a.role.toLowerCase() === b.role.toLowerCase()) {
          // no/no yes/yes
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
        }
        if (a.role.toLowerCase() > b.role.toLowerCase()) {
          // yes/no
          return -1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'desc', sort: sort })
        : this.setState({ users: array, filter: 'desc', sort: sort });
    }
  };

  /* Main render method of component. */
  render() {
    const { search } = this.state;
    const filter = createFilterOptions();
    return (
      <>
        <Header history={this.props.history} />

        <div style={styles.userscontainer}>
          <div style={styles.userscontentcontainer}>
            <h3 style={styles.usersheadertext}>
              *This screen shows all users and customers.
            </h3>
            <div>
              <CustomButton
                variant='contained'
                onClick={() => this.setState({ showAddUserModal: true })}
              >
                ADD USER
              </CustomButton>
            </div>
            <Autocomplete
              value={search}
              onChange={(event, newValue) => this.updateSearch(newValue)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id='free-solo-with-text-demo'
              options={this.state.users}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }

                // Regular option
                return option.name + ' - ' + option.email;
              }}
              renderOption={(option) => option.name + ' - ' + option.email}
              style={styles.userssearchbar}
              freeSolo
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  placeholder='Search...'
                  variant='outlined'
                />
              )}
            />
          </div>
          {/* Main container to sort by employee's or whomever */}

          <ButtonGroup
            style={styles.buttongroup}
            color='inherit'
            aria-label='outlined primary button group'
          >
            {this.state.selected === 'users' && (
              <CustomButton onClick={() => this.getUsers(this.state.myId)}>
                Non-Employees
              </CustomButton>
            )}
            {this.state.selected !== 'users' && (
              <CustomButton2Dark onClick={() => this.getUsers(this.state.myId)}>
                Non-Employees
              </CustomButton2Dark>
            )}
            {this.state.selected === 'emps' && (
              <CustomButton onClick={() => this.getEmployees(this.state.myId)}>
                Employees
              </CustomButton>
            )}
            {this.state.selected !== 'emps' && (
              <CustomButton2Dark
                onClick={() => this.getEmployees(this.state.myId)}
              >
                Employees
              </CustomButton2Dark>
            )}
            {this.state.selected === 'managers' && (
              <CustomButton onClick={() => this.getManagers(this.state.myId)}>
                Managers
              </CustomButton>
            )}
            {this.state.selected !== 'managers' && (
              <CustomButton2Dark
                onClick={() => this.getManagers(this.state.myId)}
              >
                Managers
              </CustomButton2Dark>
            )}
            {this.state.selected === 'admins' && (
              <CustomButton onClick={() => this.getAdmins(this.state.myId)}>
                Admin
              </CustomButton>
            )}
            {this.state.selected !== 'admins' && (
              <CustomButton2Dark
                onClick={() => this.getAdmins(this.state.myId)}
              >
                Admin
              </CustomButton2Dark>
            )}
          </ButtonGroup>

          {/* Main content table of screen. */}
          <TableContainer component={Paper} style={styles.tablecontainer}>
            <Table style={styles.table}>
              <TableHead style={styles.tablehead}>
                <TableRow>
                  <TableCell style={styles.rowheaderText}>
                    <TableSortLabel
                      active={this.state.sort === 'Name'}
                      direction={this.state.filter === 'desc' ? 'desc' : 'asc'}
                      onClick={() =>
                        this.state.filter === 'asc'
                          ? this.sortData('Name', 'desc')
                          : this.sortData('Name', 'asc')
                      }
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowheaderText}>
                    <TableSortLabel
                      active={this.state.sort === 'Email'}
                      direction={this.state.filter === 'desc' ? 'desc' : 'asc'}
                      onClick={() =>
                        this.state.filter === 'asc'
                          ? this.sortData('Email', 'desc')
                          : this.sortData('Email', 'asc')
                      }
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowheaderText}>
                    <TableSortLabel
                      active={this.state.sort === 'Role'}
                      direction={this.state.filter === 'desc' ? 'desc' : 'asc'}
                      onClick={() =>
                        this.state.filter === 'asc'
                          ? this.sortData('Role', 'desc')
                          : this.sortData('Role', 'asc')
                      }
                    >
                      Role
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowheaderText}>Message</TableCell>
                  {/* <TableCell style={styles.rowheaderText}>Action</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredArray.length === 0 &&
                  this.state.users.map((user, index) => (
                    <TableRow key={index} style={styles.rowClickable}>
                      <TableCell
                        style={styles.rowText}
                        onClick={() => this.determineOnclick(user)}
                      >
                        {user.name}
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        <Link href={`mailto:${user.email}`} color='inherit'>
                          {user.email}
                        </Link>
                      </TableCell>
                      <TableCell
                        style={styles.rowText}
                        onClick={() => this.determineOnclick(user)}
                      >
                        {user.role}
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        <CustomButtonDark
                          color='default'
                          variant='contained'
                          disabled={
                            (this.state.myId === user.id
                              ? true
                              : false || this.state.myData.canMessage === false
                              ? true
                              : false) ||
                            (this.state.myData.role === 'Employee' &&
                              user.role === 'Employee')
                          }
                          onClick={() =>
                            this.props.history.push({
                              pathname: '/messages',
                              state: { user: user, myData: this.state.myData },
                            })
                          }
                        >
                          Message
                        </CustomButtonDark>
                      </TableCell>
                      {/* <TableCell style={styles.rowText}>
                        <SendNotification user={user} />
                      </TableCell> */}
                    </TableRow>
                  ))}
                {this.state.filteredArray.map((user, index) => (
                  <TableRow key={index} style={styles.rowClickable}>
                    <TableCell
                      style={styles.rowText}
                      onClick={() => this.determineOnclick(user)}
                    >
                      {user.name}
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <Link href={`mailto:${user.email}`} color='inherit'>
                        {user.email}
                      </Link>
                    </TableCell>
                    <TableCell
                      style={styles.rowText}
                      onClick={() => this.determineOnclick(user)}
                    >
                      {user.role}
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <CustomButtonDark
                        color='default'
                        variant='contained'
                        disabled={
                          this.state.myId === user.id
                            ? true
                            : false || this.state.myData.canMessage === false
                            ? true
                            : false
                        }
                        onClick={() =>
                          this.props.history.push({
                            pathname: '/messages',
                            state: { user: user, myData: this.state.myData },
                          })
                        }
                      >
                        Message
                      </CustomButtonDark>
                    </TableCell>
                    {/* <TableCell style={styles.rowText}>
                      <SendNotification user={user} />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Show Modals */}
          {this.state.showGivePromoModal && (
            <GivePromoModal
              close={this.closeModals}
              showComplete={this.showCompleteModal}
            />
          )}

          {this.state.showCompletionModal && (
            <ShowCompletionModal close={this.closeModals} />
          )}

          {this.state.showAddUserModal && (
            <CreateUserModal
              close={this.closeModals}
              myRole={this.props.myRole}
              showError={this.showErrorModal}
              showComplete={this.showUserAddedModal}
            />
          )}
          {this.state.showErrorModal && (
            <ShowErrorModal close={this.closeModals} />
          )}
          {this.state.showUserAddedModal && (
            <ShowUserAddedModal close={this.closeModals} />
          )}
        </div>
      </>
    );
  }
}
