import React from "react";
import {
  Paper,
  Fade,
  Modal,
  Backdrop,
  LinearProgress,
} from "@material-ui/core";
import {
  auth,
  adminRef,
  notificationsRef,
  storageRef,
} from "../../Firebase/FirebaseConfig";

import styles from "../../Styles/Modal.js";
import {
  CustomTextField,
  CustomButton,
  CustomButton2,
} from "../Custom/CustomComponents";
import CancelIcon from "@material-ui/icons/Cancel";
import { PINAlert } from "../Alerts";

export class UploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Need to eventually add images to notifications
  async uploadToStorage(path) {
    const image = path[0];

    // Generate a unique name for the file.
    const uniqueId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    const ref = storageRef.child(`/notifications/${uniqueId}.jpg`);

    // Take filesystem path and upload a byte stream to firebase storage.
    await ref
      .put(image)
      .then(() => {
        // Get the downloadURL since it will be public to anyone who has the notification.
        ref
          .getDownloadURL()
          .then((url) => {
            const code = Math.floor(100000 + Math.random() * 900000);

            // Everything looks good, push to database.
            notificationsRef
              .push({
                body: this.state.body,
                image: url,
                title: this.state.title,
                code: code,
                name: uniqueId,
                url: this.state.link,
              })
              .then(() => {
                this.props.showComplete();
              });
          })
          .catch((e) => {
            console.log(e);
            this.setState({ disabled: false }, () => this.props.showError(e));
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ disabled: false }, () => this.props.showError(err));
      });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ disabled: true }, () =>
      this.uploadToStorage(this.state.path)
    );
  }

  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={5} style={styles.addpromocontainer}>
            <form onSubmit={this.handleSubmit} style={styles.addpromoform}>
              <h2 style={styles.addpromoheader}>Add Notification</h2>
              <CustomTextField
                id="outlined-basic"
                style={styles.addpromotextfield}
                variant="outlined"
                required
                type="text"
                value={this.state.title}
                placeholder="Title"
                onChange={(e) => this.setState({ title: e.target.value })}
              />
              <CustomTextField
                id="image"
                style={styles.addpromotextfield}
                variant="outlined"
                required
                type="file"
                value={this.state.image}
                onChange={(e) => this.setState({ path: e.target.files })}
              />
              <CustomTextField
                id="outlined-basic"
                style={styles.addpromotextfield}
                variant="outlined"
                required
                type="text"
                value={this.state.body}
                placeholder="Description"
                onChange={(e) => this.setState({ body: e.target.value })}
              />
              <CustomTextField
                id="outlined-basic"
                style={styles.addpromotextfield}
                variant="outlined"
                required
                type="text"
                value={this.state.link}
                placeholder="Custom Link"
                onChange={(e) => this.setState({ link: e.target.value })}
              />
              {this.state.disabled && (
                <LinearProgress color="primary" style={styles.progressbar} />
              )}
              <h3 style={styles.addpromoheader}>
                Please enter all above fields to submit a notification.
              </h3>
              <CustomButton
                variant="contained"
                color="default"
                type="submit"
                disabled={this.state.disabled}
              >
                SUBMIT
              </CustomButton>
            </form>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * This modal is shown when a user clicks on the "EDIT" button for a promotion.
 */
export class EditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disable: false };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  /* Main image upload handler. */
  async handleImageUpload(path) {
    this.setState({ disable: true });

    const image = path[0];

    // Create a unique id for the image.
    const uniqueId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    const ref = storageRef.child(`/notifications/${uniqueId}.jpg`);

    // Use the filepath to create a bytestream to firebase storage.
    await ref
      .put(image)
      .then(() => {
        // Get download url from firebase storage and save it to state so we can
        // use it in realtimedb.
        ref
          .getDownloadURL()
          .then((url) => {
            console.log("URL:", url);
            console.log("imagename:", uniqueId);
            this.setState({ url: url, imagename: uniqueId, disable: false });
          })
          .catch((e) => {
            console.log(e);
            this.setState({ disable: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ disable: false });
      });
  }

  /* Main submission handler. Prevents the page from reloading upon form submission. */
  handleSubmit(e) {
    e.preventDefault();
    // Verify the change is correct (all fields should be inputted when in form).

    notificationsRef
      .orderByChild("code")
      .equalTo(this.props.notification.code)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          notificationsRef
            .child(snapshot.key)
            .update({
              name: this.state.name
                ? this.state.name
                : this.props.notification.name,
              title: this.state.title
                ? this.state.title
                : this.props.notification.title,
              image: this.state.image
                ? this.state.image
                : this.props.notification.image,
              body: this.state.body
                ? this.state.body
                : this.props.notification.body,
              url: this.state.url
                ? this.state.url
                : this.props.notification.url,
            })
            .then(() => {
              if (this.props.notification.imagename) {
                const oldref = storageRef.child(
                  `/notifications/${this.props.notification.imagename}.jpg`
                );
                oldref.delete().then(() => {
                  // Notification has been successfully updated.
                  this.props.showComplete();
                });
              } else {
                this.props.showComplete();
              }
            });
        }
      });
  }

  /* Close snackbar if user chooses to do so. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.editpromocontainer}>
            <form onSubmit={this.handleSubmit} style={styles.editpromoform}>
              <h1 style={styles.addpromoheader}>Edit Notification</h1>
              <label style={styles.addpromolabel}>Title</label>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={this.state.title}
                placeholder={this.props.notification.title}
                style={styles.input}
                onChange={(e) => this.setState({ title: e.target.value })}
              />
              <label style={styles.addpromolabel}>Image</label>
              <CustomTextField
                id="image"
                variant="outlined"
                type="file"
                style={styles.input}
                value={this.state.image}
                onChange={(e) => this.handleImageUpload(e.target.files)}
              />
              <label style={styles.addpromolabel}>Description</label>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={this.state.body}
                rows={4}
                multiline
                style={styles.input}
                placeholder={this.props.notification.body}
                onChange={(e) => this.setState({ body: e.target.value })}
              />
              <label style={styles.addpromolabel}>Link (Website URL)</label>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={this.state.url}
                multiline
                style={styles.input}
                placeholder={
                  this.props.notification.url
                    ? this.props.notification.url
                    : "https://www.google.com"
                }
                onChange={(e) => this.setState({ url: e.target.value })}
              />
              <div style={styles.editpromobuttoncontainer}>
                <CustomButton
                  variant="contained"
                  color="default"
                  disabled={this.state.disable}
                  style={styles.editpromobutton}
                  type="submit"
                >
                  SUBMIT
                </CustomButton>
              </div>
            </form>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

export class DeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { PIN: "" };

    this.checkPin = this.checkPin.bind(this);
  }

  /* Close false pin snackbar. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Check if inputted pin belongs to the signed in admin. */
  checkPin(e) {
    e.preventDefault();
    adminRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snapshot) => {
        if (snapshot.exists() && this.state.PIN == snapshot.val().PIN) {
          this.removeNotification(this.props.notification);
        } else {
          this.setState({ alert: true });
        }
      });
  }

  /*  */
  removeNotification(notification) {
    notificationsRef
      .orderByChild("code")
      .equalTo(notification.code)
      .once("child_added", (snapshot) => {
        console.log("noti:", notification);
        if (snapshot.exists()) {
          notificationsRef
            .child(snapshot.key)
            .remove()
            .then(() => {
              this.props.showComplete();
            })
            .catch((error) => {
              this.props.showError(error);
            });
        }
      });
  }

  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}
            <Paper elevation={3} style={styles.deletepromocontainer}>
              <div style={styles.disableContainer}>
                <CancelIcon
                  fontSize="large"
                  color="secondary"
                  style={styles.cancelIcon}
                />
                <div>
                  <h1 style={styles.addpromolabel}>Are you sure?</h1>
                </div>
                <form
                  onSubmit={this.checkPin}
                  style={styles.deleteconfirmmodalform}
                >
                  <h3 style={styles.deletepromoheader}>
                    Do you really want to delete this notification? No one will
                    be able to give it to users.
                  </h3>
                  <h3 style={styles.deletepromoheader}>
                    Please enter your PIN below.
                  </h3>
                  <CustomTextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 6 }}
                    value={this.state.PIN}
                    placeholder="000000"
                    onChange={(e) => this.setState({ PIN: e.target.value })}
                  />
                  <hr style={styles.deleteunderline} />
                  <div style={styles.deletebuttoncontainer}>
                    <CustomButton
                      color="default"
                      variant="contained"
                      style={styles.deletebutton1}
                      onClick={() => this.props.close()}
                    >
                      CANCEL
                    </CustomButton>
                    <CustomButton2
                      color="secondary"
                      variant="contained"
                      disabled={this.state.PIN.length === 6 ? false : true}
                      style={styles.deletebutton}
                      type="submit"
                    >
                      DELETE
                    </CustomButton2>
                  </div>
                </form>
              </div>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class UploadCompleteModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              Your notification has been added.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class EditCompleteModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              Your notification has been updated.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class ErrorModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              There was an issue creating this notification, please try again
              later.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class DeleteCompleteModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              Your notification has been deleted.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class DeleteErrorModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              There was an issue deleting this notification, please try again
              later.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}
