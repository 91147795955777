import React from "react";
import {
  adminRef,
  auth,
  empRef,
  managerRef,
  userRef,
  storageRef,
} from "../Firebase/FirebaseConfig";
import { Card, CardMedia, CardActions } from "@material-ui/core";

// Import components and styles
import Header from "../Components/Header";
import {
  CustomButton2,
  CustomButtonDark,
} from "../Components/Custom/CustomComponents";
import {
  ConfirmationModal,
  ErrorModal,
  CompletionModal,
} from "../Components/Users/UserDocModals";
import styles from "../Styles/UserDocs";

/*
 * Screen that displays all documents the currently authed employee has uploaded/shared with the selected user.
 */
export default class UserDocs extends React.Component {
  constructor(props) {
    super(props);

    // Init empty arrays to stop prerender errors.
    this.state = { images: [], docs: [], disabled: false };

    // Bind modals in local namespace
    this.closeModals = this.closeModals.bind(this);
    this.closeModalsReload = this.closeModalsReload.bind(this);
  }

  componentDidMount() {
    // Pass current authed id to the search query (only return docs that the employee uploaded for this user).
    auth.onAuthStateChanged((user) => {
      user
        ? this.getDocs(this.props.location.state.myId)
        : this.props.history.push("/login");
    });
  }

  /* Parse through all documents passed to user object and return the ones uploaded by selected employee. */
  getDocs(empId) {
    // empId is the id of the currently authenticated employee
    // Should already have the user object passed in by props;
    const user = this.props.location.state.user;
    const myRole = localStorage.getItem("myRole");
    let userimages = user.private_files;
    let userdocs = user.private_nonimgfiles;

    let imgarr = [];
    let docarr = [];
    let idarr = [];

    let ref;
    let canViewDocs;

    console.log("REF", myRole);

    if (myRole === "Employee") {
      ref = empRef;
    } else if (myRole === "Manager") {
      ref = managerRef;
    } else if (myRole === "Admin") {
      ref = adminRef;
    } else {
      return null;
    }

    // Get all id's of your role (applicable to employee's/manager's)
    ref.once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        idarr.push(snap.val().id);
        if (snap.val().id === empId) {
          canViewDocs = snap.val().canViewAllDocs;
          console.log("VIEW:", canViewDocs);
          if (canViewDocs || canViewDocs === undefined) {
            // Don't show any data from other staff, only your own.
            this.getUserData(
              userimages,
              userdocs,
              empId,
              imgarr,
              docarr,
              user.id
            );
          } else {
            this.getUserEmpData(userimages, userdocs, empId, imgarr, docarr);
          }
        }
      });
    });
  }

  /* Get all documents from user if this is the case (minus their own) */
  getUserData(userimages, userdocs, empId, imgarr, docarr, userId) {
    if (userimages) {
      userimages = Object.values(userimages); // Parse out the firebase key crap.

      // Find what belongs to the employee viewing this page.
      for (let i = 0; i < userimages.length; i++) {
        if (userimages[i].originalId !== userId) {
          imgarr.push(userimages[i]);
        }
      }
    }

    if (userdocs) {
      userdocs = Object.values(userdocs); // Parse out the firebase key crap.

      // Find what belongs to the employee viewing this page.
      for (let i = 0; i < userdocs.length; i++) {
        if (userdocs[i].originalId !== userId) {
          docarr.push(userdocs[i]);
        }
      }
    }

    // Set what we found to the state.
    this.setState({
      images: imgarr.length > 0 ? imgarr : [],
      docs: docarr.length > 0 ? docarr : [],
    });
  }

  /* Get all documents from user if this is the case (minus their own) */
  getUserEmpData(userimages, userdocs, empId, imgarr, docarr) {
    if (userimages) {
      userimages = Object.values(userimages); // Parse out the firebase key crap.

      // Find what belongs to the employee viewing this page.
      for (let i = 0; i < userimages.length; i++) {
        if (userimages[i].originalId === empId) {
          imgarr.push(userimages[i]);
        }
      }
    }

    if (userdocs) {
      userdocs = Object.values(userdocs); // Parse out the firebase key crap.

      // Find what belongs to the employee viewing this page.
      for (let i = 0; i < userdocs.length; i++) {
        if (userdocs[i].originalId === empId) {
          docarr.push(userdocs[i]);
        }
      }
    }

    // Set what we found to the state.
    this.setState({
      images: imgarr.length > 0 ? imgarr : [],
      docs: docarr.length > 0 ? docarr : [],
    });
  }

  /* Remove deleted document from state and rerender. */
  reloadData() {
    let docs = this.state.docs;
    let images = this.state.images;

    if (this.state.selecteddoc && this.state.selectedtype === "Doc") {
      for (let i = 0; i < docs.length; i++) {
        if (this.state.selecteddoc === docs[i]) {
          docs.splice(docs.indexOf(docs[i]), 1);
        }
        if (docs.length === 1) {
          // Clear the array
          docs = [];
        }
      }
    } else if (this.state.selecteddoc && this.state.selectedtype === "Images") {
      for (let i = 0; images.length; i++) {
        if (this.state.selecteddoc === images[i]) {
          images.splice(images.indexOf(images[i]), 1);
        }
        if (images.length === 1) {
          // Clear the array
          images = [];
        }
      }
    }

    console.log("Reload:", docs.length, images.length);

    this.setState({
      docs: docs,
      images: images,
      selecteddoc: null,
      selectedtype: null,
    });
  }

  /* Find document in firebase storage and realtimedb. */
  deleteDocument = () => {
    const docname = this.state.selecteddoc.name;
    const userId = this.props.location.state.user.id;
    const userrole = this.props.location.state.user.role;
    let ref;
    if (userrole === "User" || userrole === "Customer") {
      ref = userRef;
    } else if (userrole === "Employee") {
      ref = empRef;
    } else if (userrole === "Manager") {
      ref = managerRef;
    } else if (userrole === "Admin") {
      ref = adminRef;
    }

    // Find user in realtime db.
    ref
      .orderByChild("id")
      .equalTo(userId)
      .once("child_added", (snapshot) => {
        if (
          snapshot.exists() &&
          this.state.selectedtype === "Image" &&
          snapshot.val().private_files
        ) {
          // User exists and the document is an image.
          const dbdocref = ref.child(snapshot.key).child("private_files");
          dbdocref
            .orderByChild("name")
            .equalTo(docname)
            .once("child_added", (snap) => {
              if (snap.exists()) {
                dbdocref.child(snap.key).remove();
                const docref = storageRef.child(
                  `/${userId}/Uploads/${docname}.jpg`
                );

                docref.delete().then(() => {
                  this.setState({ disabled: false, showCompletionModal: true });
                });
              } else {
                this.setState({ showErrorModal: true });
              }
            });
        } else if (
          snapshot.exists() &&
          this.state.selectedtype === "Doc" &&
          snapshot.val().private_nonimgfiles
        ) {
          // User exists and the document is not an image.
          const dbdocref = ref.child(snapshot.key).child("private_nonimgfiles");
          dbdocref
            .orderByChild("name")
            .equalTo(docname)
            .once("child_added", (snap) => {
              if (snap.exists()) {
                dbdocref.child(snap.key).remove();
                const docref = storageRef.child(
                  `/${userId}/Uploads/${docname}.jpg`
                );

                docref.delete().then(() => {
                  this.setState({ disabled: false, showCompletionModal: true });
                });
              } else {
                this.setState({ showErrorModal: true });
              }
            });
        } else {
          // Something went wrong or the document doesn't exist anymore.
          this.setState({ showErrorModal: true });
        }
      });
  };

  /* Close all modals in view. */
  closeModals = () => {
    this.setState({
      showConfirmationModal: false,
      showErrorModal: false,
      showCompletionModal: false,
      disabled: false,
    });
  };

  /* Close all modals in view and reload data. */
  closeModalsReload = () => {
    this.setState(
      {
        showConfirmationModal: false,
        showErrorModal: false,
        showCompletionModal: false,
        disabled: false,
      },
      () => {
        this.reloadData();
      }
    );
  };

  render() {
    const myId = this.props.location.state.myId;
    return (
      <>
        <Header history={this.props.history} />

        {/* Main container for view */}
        <div style={styles.maincontainer}>
          <div style={styles.seccontainer}>
            <h2 style={styles.text}>
              This screen shows all documents you have given/shared with{" "}
              {this.props.location.state.user.name}.
            </h2>
            <h2 style={styles.text}>
              From here, you can view or delete any documents.
            </h2>
          </div>
          {/* Secondary container for images */}
          <div style={styles.cardcontainer}>
            {/* Map through the images array and render what belongs to the employee. */}
            {this.state.images.map((img, index) => (
              <Card key={index} style={styles.card}>
                <CardMedia
                  image={img.url}
                  title={img.name}
                  style={styles.cardimg}
                  onClick={() => window.open(img.url, "_blank")}
                />
                <CardActions>
                  <CustomButton2
                    color="secondary"
                    variant="contained"
                    disabled={this.state.disabled}
                    onClick={() =>
                      this.setState({
                        selecteddoc: img,
                        selectedtype: "Image",
                        disabled: true,
                        showConfirmationModal: true,
                      })
                    }
                  >
                    DELETE
                  </CustomButton2>
                </CardActions>
              </Card>
            ))}

            {/* Map through the images array and render what belongs to the employee. */}
            {this.state.docs.map((img, index) => (
              <Card key={index}>
                <h3 onClick={() => window.open(img.url, "_blank")}>
                  Click To View
                </h3>
                <CardActions>
                  <CustomButton2
                    color="secondary"
                    variant="contained"
                    disabled={this.state.disabled}
                    onClick={() =>
                      this.setState({
                        selecteddoc: img,
                        selectedtype: "Doc",
                        disabled: true,
                        showConfirmationModal: true,
                      })
                    }
                  >
                    DELETE
                  </CustomButton2>
                </CardActions>
              </Card>
            ))}

            {this.state.docs.length === 0 && this.state.images.length === 0 && (
              <div style={styles.emptycontainer}>
                <h3 style={styles.emptytextcontainer}>
                  You have not uploaded any documents for this user.
                </h3>
                <CustomButtonDark
                  color="inherit"
                  variant="contained"
                  onClick={() => this.props.history.goBack()}
                >
                  Back
                </CustomButtonDark>
              </div>
            )}
          </div>
        </div>

        {/* All Modals relevant to screen here. */}
        {this.state.showConfirmationModal && (
          <ConfirmationModal
            close={this.closeModals}
            myId={myId}
            username={this.props.location.state.user.name}
            deleteDoc={this.deleteDocument}
          />
        )}
        {this.state.showErrorModal && <ErrorModal close={this.closeModals} />}
        {this.state.showCompletionModal && (
          <CompletionModal close={this.closeModalsReload} />
        )}
      </>
    );
  }
}
