import {
  adminRef,
  empRef,
  managerRef,
  userRef,
} from "../Firebase/FirebaseConfig";

/*
 * Gets the current authed user's data when they try to sign in.
 * If a user's data is not found, timeout and throw error in login.js
 */
export async function getCurrentData(inputted_email) {
  const fbData = new Promise((resolve, reject) => {
    const email = inputted_email.toLowerCase();
    empRef
      .orderByChild("email")
      .equalTo(email)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          resolve(data);
        }
      });

    managerRef
      .orderByChild("email")
      .equalTo(email)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          resolve(data);
        }
      });

    adminRef
      .orderByChild("email")
      .equalTo(email)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          resolve(data);
        }
      });
  });

  const timedPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject("Timed Out.");
    }, 4000);
  });

  return Promise.race([fbData, timedPromise]);
}
