import React from "react";

import Header from "../Components/Header";
import PromoStatistics from "../Components/Analytics/PromoStatistics";
import PromoRemaining from "../Components/Analytics/PromoRemaining";
import styles from "../Styles/Screens";
import { CustomButtonDark } from "../Components/Custom/CustomComponents";

/*
 * Shows either the employee's that have redeemed promotions or
 * all users who have the selected promotion.
 */
export default class PromoData extends React.Component {
  render() {
    return (
      <>
        <Header history={this.props.history} />
        <div style={styles.promodatamaincontainer}>
          <div style={styles.promodataseccontainer}>
            <div style={styles.promodatabuttoncontainer}>
              <CustomButtonDark
                color="inherit"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </CustomButtonDark>
            </div>
            {this.props.location.state.table === "emps" && (
              <PromoStatistics
                history={this.props.history}
                promo={this.props.location.state.promo}
              />
            )}
            {this.props.location.state.table === "users" && (
              <PromoRemaining
                history={this.props.history}
                promo={this.props.location.state.promo}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
