import colors from "./Colors";

const styles = {
  // Header component
  appbar: {
    width: "100%",
  },
  toolbar: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.primarybackground,
  },
  menubutton: {
    color: colors.gray,
  },
  headertext: {
    width: "90%",
    color: colors.black,
  },
  signoutcontainer: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  signoutbutton: {
    width: 150,
    marginRight: 50,
  },

  // AllUsersWith component
  allusersmaincontainer: {
    backgroundColor: colors.primarybackground,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 80,
    height: "100%",
    minHeight: "100vh",
  },
  alluserssecondarycontainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  allusersheader: {
    fontWeight: "normal",
    color: colors.black,
  },
  alluserssearchbar: {
    width: "50%",
  },
  tablecontainer: {
    backgroundColor: colors.white,
  },
  table: {
    backgroundColor: colors.white,
  },
  tablehead: {
    backgroundColor: colors.white,
    color: colors.black,
  },
  tablerow: {
    cursor: "pointer",
  },
  rowText: {
    color: colors.black,
  },
  rowheaderText: {
    color: colors.black,
    fontWeight: "bold",
  },
  rowHasPromoText: {
    color: colors.black,
  },

  // User Promotions component
  userpromomain: {
    height: "100%",
    minHeight: "100vh",
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 100,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  nopromostext: {
    color: colors.black,
  },
  promosremain: {
    maxWidth: 400,
    margin: 5,
    backgroundColor: colors.white,
  },
  nopromosremain: {
    maxWidth: 400,
    margin: 5,
    backgroundColor: colors.disabled,
  },
  underlinebar: {
    width: "100%",
  },
  userpromosbuttoncontainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  userpromobutton: {
    width: "100%",
  },

  // Promo listview
  listpromomain: {
    padding: 30,
    backgroundColor: colors.white,
  },
  listpromotopcontainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  listpromostatsbar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  listpromostatstext: {
    color: colors.black,
  },
  promofilter: {
    marginBottom: 20,
    color: colors.black,
  },
  listpromosearchcontainer: {
    width: "100%",
    paddingBottom: 20,
  },
  collectioncontainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  collectionText: {
    color: colors.black,
  },
  collectionNumber: {
    marginLeft: 10,
    color: colors.black,
  },

  // Remaining promos out there
  mainremainingcontainer: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: colors.darkgray,
    borderRadius: 20,
    borderWidth: 20,
    color: colors.white,
    display: "flex",
    fontWeight: "bold",
    flexDirection: "row",
    alignItems: "center",
  },
  remainingcount: {
    color: colors.white,
    marginLeft: 10,
    fontWeight: "bold",
  },

  // Disable Card component
  disablecard: {
    marginTop: 30,
    backgroundColor: colors.disabled,
  },
  disablecontainer: {
    marginTop: 30,
    backgroundColor: colors.white,
  },
  disableswitch: {
    color: colors.disabled,
  },

  // Send Notification stuff
  mainnotificationcontainer: {
    backgroundColor: colors.primarybackground,
    height: "100%",
    minHeight: "100vh",
  },
  secondarynotificationcontainer: {
    marginLeft: 30,
    marginRight: 30,
    paddingTop: 80,
  },
  topbuttoncontainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
};

export default styles;
