import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Link,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { getAllUsers, getUsersWithNotification } from "../Helpers/FindUsers";
import {
  auth,
  firebaseRef as firebase,
  userRef,
} from "../Firebase/FirebaseConfig";
import {
  CompletionModal,
  ErrorModal,
  SendNotificationModal,
} from "../Components/Modals/NotificationUserModals";
import Header from "../Components/Header";
import NotificationButtons from "../Components/Notifications/NotificationButtons";

// Import styles
import styles from "../Styles/Components";
import {
  CustomButtonDark,
  CustomButton,
  CustomButton2,
  CustomTextField,
} from "../Components/Custom/CustomComponents";
import { InvalidPermission } from "../Components/Alerts";

// Default variables
let myRole = localStorage.getItem("myRole");

export default class SendNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = { users: [], filteredArray: [] };

    this.giveNotification = this.giveNotification.bind(this);
  }

  componentDidMount() {
    myRole = localStorage.getItem("myRole");
    this.getUsers();
  }

  /* Get all users with the inputted notification. */
  async getUsers() {
    let temp = [];
    // await getUsersWithNotification(
    //   this.props.location.state.notification.code
    // ).then((r) => {
    //   temp.push(r);
    // });

    await getAllUsers("User+Customer").then((r) => {
      temp = r;
    });

    for (let i = 0; i < temp.length; i++) {
      temp[i].hasnotification = "No";
      if (temp[i].notifications) {
        if (
          Object.values(temp[i].notifications).find(
            (e) => e.code === this.props.location.state.notification.code
          )
        ) {
          temp[i].hasnotification = "Yes";
        }
      }
    }

    this.setState({ users: temp });
  }

  /* If user already has the notification, prompt emp if they want to remove all occurances of the notification
     for the user or just the oldest/newest one.
     If user does not have the notification, prompt emp if they want to give it to that user. */
  determineOnclick(user) {
    if (myRole === "Admin" || myRole === "Manager") {
      this.setState({ showSendNotificationModal: true, user: user });
    } else {
      this.setState({ showInvalidPermission: true });
    }
  }

  /* Handler for when search is updated. */
  updateSearch = (search) => {
    var updatedData = [];
    if (search === null || search === undefined || search === "") {
      updatedData = [];
    } else if (typeof search == "object") {
      updatedData.push(search);
    } else {
      // User didn't click on a card, show all cases of users with first name
      // relative to the search.
      this.state.users.forEach((element) => {
        let lower = element.name.toLowerCase();
        let email = element.email.toLowerCase();

        if (
          lower.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase())
        ) {
          updatedData.push(element);
        }
      });
    }
    this.setState({ filteredArray: updatedData });
  };

  /* Close all modals from UI */
  closeModals = () => {
    this.setState({
      showSendNotificationModal: false,
      showCompletionModal: false,
      showCompleteModal: false,
      showInvalidPermission: false,
      showErrorModal: false,
    });
  };

  /* Show the completion modal. */
  showCompleteModal = () => {
    this.setState(
      {
        showSendNotificationModal: false,
        showCompleteModal: true,
        showCompletionModal: false,
        showInvalidPermission: false,
        showErrorModal: false,
      },
      () => this.getUsers()
    );
  };

  /* Give the notification to the selected user in firebase. */
  async giveNotification() {
    const code = Math.floor(100000 + Math.random() * 900000);

    var notification = {};
    notification.title = this.props.location.state.notification.title;
    notification.givenByEmail = auth.currentUser.email;
    notification.givenBy = auth.currentUser.uid;
    notification.body = this.props.location.state.notification.body;
    notification.image = this.props.location.state.notification.image;
    notification.code = this.props.location.state.notification.code;
    notification.userCode = code;
    notification.time = firebase.database.ServerValue.TIMESTAMP;
    notification.url = this.props.location.state.notification.url;

    userRef
      .orderByChild("id")
      .equalTo(this.state.user.id)
      .once("child_added", (snapshot) => {
        if (snapshot.val()) {
          userRef
            .child(snapshot.key)
            .child("notifications")
            .push(notification)
            .then(() => this.setState({ showCompleteModal: true }))
            .catch((error) => {
              console.log("Error updating firebase:", error);
              this.setState({ showErrorModal: true });
            });
        }
      });
  }

  /* Sorting handler for filtering out data properly. */
  sortData = (sort, comparator) => {
    console.log(sort, comparator);

    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.users;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    // Sort should be a string of the selected column
    if (sort === "Name" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ users: array, filter: "asc", sort: sort });
    } else if (sort === "Name" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ users: array, filter: "desc", sort: sort });
    } else if (sort === "Email" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ users: array, filter: "asc", sort: sort });
    } else if (sort === "Email" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ users: array, filter: "desc", sort: sort });
    } else if (sort === "Role" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.role.toLowerCase() < b.role.toLowerCase()) {
          return -1;
        }
        if (a.role.toLowerCase() > b.role.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ users: array, filter: "asc", sort: sort });
    } else if (sort === "Role" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.role.toLowerCase() > b.role.toLowerCase()) {
          return -1;
        }
        if (a.role.toLowerCase() < b.role.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ users: array, filter: "desc", sort: sort });
    } else if (sort === "Notification" && comparator === "asc") {
      array = array.sort((a, b) => {
        // -1 represents we are moving b behind a.
        // 1 represents we are moving a behind b.

        if (
          a.hasnotification.toLowerCase() <= b.hasnotification.toLowerCase() &&
          a.name.toLowerCase() > b.name.toLowerCase()
        ) {
          return -1;
        }
        if (a.hasnotification.toLowerCase() > b.hasnotification.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ users: array, filter: "asc", sort: sort });
    } else if (sort === "Notification" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.hasnotification.toLowerCase() > b.hasnotification.toLowerCase()) {
          return -1;
        }
        if (
          a.hasnotification.toLowerCase() <= b.hasnotification.toLowerCase() &&
          a.name.toLowerCase() > b.name.toLowerCase()
        ) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ users: array, filter: "desc", sort: sort });
    }
  };

  /* Main render method of component. */
  render() {
    const { search } = this.state;
    const filter = createFilterOptions();
    return (
      <>
        <Header history={this.props.history} />

        <div style={styles.mainnotificationcontainer}>
          <div style={styles.secondarynotificationcontainer}>
            <div style={styles.topbuttoncontainer}>
              {/* Should have a back button at the top of the screen. */}
              <CustomButtonDark
                color="inherit"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </CustomButtonDark>
              <NotificationButtons
                notification={this.props.location.state.notification}
                invalidPermission={() =>
                  this.setState({
                    showInvalidPermission: true,
                    showSendNotificationModal: false,
                  })
                }
              />
            </div>
            <div style={styles.alluserssecondarycontainer}>
              <h3 style={styles.allusersheader}>
                *This screen shows all users and customers who have/can receive
                the selected notification.
              </h3>
              <Autocomplete
                value={search}
                onChange={(event, newValue) => this.updateSearch(newValue)}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={this.state.users}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }

                  // Regular option
                  return option.name + option.email;
                }}
                renderOption={(option) => option.name + " - " + option.email}
                style={styles.alluserssearchbar}
                freeSolo
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    placeholder="Search..."
                    variant="outlined"
                  />
                )}
              />
            </div>

            {/* Table Container */}
            <TableContainer component={Paper} style={styles.tablecontainer}>
              <Table style={styles.table}>
                <TableHead style={styles.tablehead}>
                  <TableRow>
                    <TableCell style={styles.rowText}>
                      <TableSortLabel
                        active={this.state.sort === "Name"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Name", "desc")
                            : this.sortData("Name", "asc")
                        }
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <TableSortLabel
                        active={this.state.sort === "Email"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Email", "desc")
                            : this.sortData("Email", "asc")
                        }
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <TableSortLabel
                        active={this.state.sort === "Role"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Role", "desc")
                            : this.sortData("Role", "asc")
                        }
                      >
                        Role
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <TableSortLabel
                        active={this.state.sort === "Notification"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Notification", "desc")
                            : this.sortData("Notification", "asc")
                        }
                      >
                        Has Notification?
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowText}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.filteredArray.length === 0 &&
                    this.state.users.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell style={styles.rowText}>
                          {user.name}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          <Link href={`mailto:${user.email}`} color="inherit">
                            {user.email}
                          </Link>
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {user.role}
                        </TableCell>
                        {user.notifications && user.hasnotification === "Yes" && (
                          <>
                            <TableCell style={styles.rowHasPromoText}>
                              {user.hasnotification}
                            </TableCell>
                            <TableCell style={styles.rowHasPromoText}>
                              <CustomButton
                                variant="contained"
                                color="default"
                                onClick={() => this.determineOnclick(user)}
                              >
                                Send Again
                              </CustomButton>
                            </TableCell>
                          </>
                        )}
                        {user.notifications && user.hasnotification === "No" && (
                          <>
                            <TableCell style={styles.rowText}>
                              {user.hasnotification}
                            </TableCell>
                            <TableCell style={styles.rowText}>
                              <CustomButton2
                                variant="contained"
                                color="default"
                                onClick={() => this.determineOnclick(user)}
                              >
                                Send
                              </CustomButton2>
                            </TableCell>
                          </>
                        )}
                        {!user.notifications && (
                          <>
                            <TableCell style={styles.rowText}>No</TableCell>
                            <TableCell style={styles.rowText}>
                              <CustomButton2
                                variant="contained"
                                color="default"
                                onClick={() => this.determineOnclick(user)}
                              >
                                Send
                              </CustomButton2>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  {this.state.filteredArray.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell style={styles.rowText}>{user.name}</TableCell>
                      <TableCell style={styles.rowText}>
                        <Link href={`mailto:${user.email}`} color="inherit">
                          {user.email}
                        </Link>
                      </TableCell>
                      <TableCell style={styles.rowText}>{user.role}</TableCell>
                      {user.notifications && user.hasnotification === "Yes" && (
                        <>
                          <TableCell style={styles.rowHasPromoText}>
                            {user.notification}
                          </TableCell>
                          <TableCell style={styles.rowHasPromoText}>
                            <CustomButton
                              variant="contained"
                              color="default"
                              onClick={() => this.determineOnclick(user)}
                            >
                              Send Again
                            </CustomButton>
                          </TableCell>
                        </>
                      )}
                      {user.notifications && user.hasnotification === "No" && (
                        <>
                          <TableCell style={styles.rowText}>
                            {user.hasnotification}
                          </TableCell>
                          <TableCell style={styles.rowText}>
                            <CustomButton2
                              variant="contained"
                              color="default"
                              onClick={() => this.determineOnclick(user)}
                            >
                              Send Again
                            </CustomButton2>
                          </TableCell>
                        </>
                      )}
                      {!user.notifications && (
                        <>
                          <TableCell style={styles.rowText}>No</TableCell>
                          <TableCell style={styles.rowText}>
                            <CustomButton2
                              variant="contained"
                              color="default"
                              onClick={() => this.determineOnclick(user)}
                            >
                              Send
                            </CustomButton2>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Show Modals */}
            {this.state.showSendNotificationModal && (
              <SendNotificationModal
                user={this.state.user}
                notification={this.props.location.state.notification}
                close={this.closeModals}
                showComplete={this.showCompleteModal}
                invalidPermission={() =>
                  this.setState({
                    showInvalidPermission: true,
                    showSendNotificationModal: false,
                  })
                }
                submit={this.giveNotification}
              />
            )}

            {this.state.showCompleteModal && (
              <CompletionModal
                user={this.state.user}
                close={this.closeModals}
              />
            )}

            {this.state.showErrorModal && (
              <ErrorModal close={this.closeModals} />
            )}

            {this.state.showInvalidPermission && (
              <InvalidPermission
                close={() => this.setState({ showInvalidPermission: false })}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
