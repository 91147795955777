import React from "react";
import {
  auth,
  firebaseRef as firebase,
  chatrooms,
  storageRef,
} from "../Firebase/FirebaseConfig";

import Header from "../Components/Header";
import styles from "../Styles/Screens";
import {
  CustomButtonDark,
  CustomButton2Dark,
} from "../Components/Custom/CustomComponents";
import { Button } from "@material-ui/core";

/*
 * Chatroom component that shows the messages between two users.
 */
export default class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      receiverId: "",
      senderToken: "",
      visible: false,
      receiverEmail: "",
      receiverRole: "",
      receiverToken: "",
      mydata: { uid: "", displayName: "", photoURL: "" },
    };
  }

  /* Check authentication status as the component loads. */
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.findMessages(user);
      } else {
        // redirect user to login form.
        this.props.history.push("/login");
      }
    });
  }

  /* Get all messages from the chatroom of two users. */
  findMessages(mydata) {
    // At this point, the id of the user and id of the authed user should have been passed.
    let chatkey = "";
    let temparr = [];
    if (
      this.props.location.state.user.id < this.props.location.state.myData.id
    ) {
      chatkey =
        this.props.location.state.myData.id + this.props.location.state.user.id;
    } else {
      chatkey =
        this.props.location.state.user.id + this.props.location.state.myData.id;
    }

    this.setState({ chatkey: chatkey });

    // Just making sure we actually got a key
    if (chatkey.length > 5) {
      try {
        // We have a database listener for new messages.
        chatrooms.child(chatkey).on("child_added", (snapshot) => {
          const message = {
            _id: snapshot.key,
            createdAt: snapshot.val().createdAt,
            image: snapshot.val().image ? snapshot.val().image : "",
            text: snapshot.val().text,
            senderId: snapshot.val().user._id,
            senderRole: snapshot.val().senderRole,
            senderToken: snapshot.val().senderToken,
            user: {
              _id: snapshot.val().user._id,
              name: snapshot.val().user.name,
              avatar: snapshot.val().user.avatar,
            },
          };

          // Push all messages into array.
          temparr.push(message);

          for (const value of temparr) {
            if (this.state.messages.includes(value._id)) {
              // Do nothing if the message is already rendered to UI. No duplicates.
            } else {
              // Message has not been rendered to UI. Sort our messages by creation date.
              // const sortedMessages = temparr.sort(
              //   (a, b) => b.createdAt - a.createdAt
              // );
              this.setState({
                messages: temparr,
                refreshing: false,
                mydata: mydata,
                chatkey: chatkey,
              }); // Dunno if we still need to handle the refreshing state on web...
            }
          }
        });

        // Once the chat messages load, the user will have read the unread message and we can remove it from the database.
        this.removeUnreadMessage(this.state.receiverEmail); // Also dunno if we'll handle this on web. More or less just need basic messaging.
      } catch (e) {
        console.log(e);
        // Also need to show alert here saying that something went wrong with getting the user messages.
        this.setState({ showAlert: true });
      }
    }
  }

  /* If we add a notifications flow to the site, we need to remove an unread messages bubble here. */
  removeUnreadMessage(receiverEmail) {
    // Some stuff here if we want to implement this.
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextState.visible !== this.state.visible) {
  //     return true;
  //   } else if (nextState.messages !== this.state.messages) {
  //     console.log("updating");
  //     return true;
  //   } else if (nextState.text !== this.state.text) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  /* Send message handler. */
  sendMessage = async (e) => {
    e.preventDefault();

    if (this.state.text !== undefined || this.state.downloadUrl !== undefined) {
      const id = this.uuidv4();

      const params = this.props.location.state;

      try {
        // Push message object to the appropriate chatroom.
        chatrooms
          .child(this.state.chatkey)
          .push({
            _id: id,
            senderRole: params.myData.role,
            receiverRole: params.user.role,
            senderEmail: params.myData.email,
            receiverEmail: params.user.email,
            senderToken: params.myData.fcmToken,
            text: this.state.text,
            image: this.state.downloadUrl ? this.state.downloadUrl : "",
            createdAt: firebase.database.ServerValue.TIMESTAMP,
            user: {
              _id: params.myData.id,
              avatar: params.myData.avatar,
              name: params.myData.name,
              receiverToken: params.user.fcmToken,
            },
          })
          .then(() => {
            console.log("Message successfully posted.");
            // Clean up state after message has been sent.
            this.setState({ text: "", downloadUrl: "" });
            window.scrollTo({ bottom: 1000, behavior: "smooth" });
          })
          .catch((err) => {
            console.log("SEND MESSAGE ERROR");
            console.log(err);
            this.setState({ showAlert: true, visible: false });
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  /* Generator for message id's */
  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        // eslint-disable-next-line eqeqeq
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  /* 
     This is our photo handler, it will wait for a response from the devices file system
     and return us the contents. Currently, I do not know if we need a different handler 
     for mobile until I get my hands on a physical ipad. */
  handleChoosePhoto = async () => {
    console.log("attempting to access photos");
    try {
      // Stuff for grabbing pics from desktop
      // Probably should just prompt the user for their native filesystem, save the path to state,
      // show a preview in their message in the component, then once they click send, show a minor spinner and upload the pic to storage and save in chatroom ref.
    } catch (e) {
      console.log(e);
      this.setState({ showAlert: true });
    }
  };

  /* Handler for uploading the photo to firebase storage then saving it in the database for the message. */
  async handleUploadPhoto(path) {
    // Only allow sending one picture at a time.

    try {
      console.log("Path:", path[0]);

      const params = this.props.location.state;

      // Create a uniqueId with uuidv4

      const uniqueId =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      const id = this.uuidv4();

      const ref = storageRef.child(
        `/${params.myData.uid}/Messages/${uniqueId}.jpg`
      );

      console.log("After creating reference.");

      await ref
        .put(path[0])
        .then(() => {
          console.log("after put");
          ref
            .getDownloadURL()
            .then((url) => {
              console.log("got download url");
              this.setState({ downloadUrl: url, visible: false }, () => {
                chatrooms.child(this.state.chatkey).push({
                  _id: id,
                  senderRole: params.myData.role,
                  receiverRole: params.user.role,
                  senderEmail: params.myData.email,
                  receiverEmail: params.user.email,
                  senderToken: params.myData.fcmToken,
                  text: this.state.text ? this.state.text : "",
                  image: this.state.downloadUrl ? this.state.downloadUrl : "",
                  createdAt: firebase.database.ServerValue.TIMESTAMP,
                  user: {
                    _id: params.myData.id,
                    avatar: params.myData.avatar,
                    name: params.myData.name,
                    receiverToken: params.user.fcmToken,
                  },
                });
              });

              this.setState({ downloadUrl: "", text: "", visible: false });
            })
            .catch((err) => {
              console.log(err);
              this.setState({ showAlert: true, visible: false });
            });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ showAlert: true, visible: false });
        });
    } catch (e) {
      console.log(e);
      this.setState({ showAlert: true, visible: false });
    }
  }

  /* Main render function for component. */
  render() {
    return (
      <>
        <Header history={this.props.history} />

        {/* Main will be the center container for all messages */}
        <div style={styles.messagescontainer}>
          {/* Back button here */}
          <div style={styles.messagesstickybutton}>
            <CustomButtonDark
              color="default"
              variant="contained"
              onClick={() => this.props.history.goBack()}
            >
              Back
            </CustomButtonDark>
          </div>
          {this.state.messages &&
            this.state.messages.map((msg) => (
              <ChatMessage
                message={msg}
                authId={this.props.location.state.myData.id}
              />
            ))}
        </div>
        {/* This form will essentially be the text bar at the bottom of the screen that people will use to send messages. */}
        <form onSubmit={this.sendMessage} style={styles.messagesform}>
          {/* style this div to look like a button */}
          <div style={styles.uploadbuttoncontainer}>
            <label
              htmlFor="contained-button-file"
              style={styles.uploadbuttonlabel}
            >
              <Button
                variant="contained"
                color="primary"
                component="span"
                style={styles.uploadbutton}
              >
                Upload
              </Button>
            </label>
            <input
              accept="image/"
              id="contained-button-file"
              type="file"
              value={this.state.image}
              style={{ display: "none" }}
              onChange={(e) => this.handleUploadPhoto(e.target.files)}
            />
          </div>
          <input
            style={styles.messagesinput}
            type="text"
            autocomplete="on"
            value={this.state.text}
            onChange={(e) => this.setState({ text: e.target.value })}
            placeholder="Type here..."
          />
          <CustomButton2Dark
            type="submit"
            disabled={this.state.visible}
            color="default"
          >
            Send
          </CustomButton2Dark>
        </form>
      </>
    );
  }
}

// TL;DR gifted chat isn't gonna work for web. We're building our own!
/* Our custom chat message container object. */
function ChatMessage(props) {
  const { text, user, createdAt, image } = props.message;
  const messageClass = user._id === props.authId ? "sent" : "received";

  return (
    <>
      <div style={messageClass === "sent" ? styles.send : styles.received}>
        {messageClass === "received" && (
          <img src={user.avatar} alt="avatar" style={styles.messageavatar} />
        )}

        <div style={styles.messagebody}>
          <p
            style={
              messageClass === "sent" ? styles.sendbody : styles.receivedbody
            }
          >
            {image && (
              <img
                src={image}
                alt="sentimage"
                style={styles.sentimage}
                onClick={() => {
                  window.open(image, "Image");
                }}
              />
            )}

            {text}
          </p>
          <p
            style={
              messageClass === "sent"
                ? styles.messagesendtime
                : styles.messagereceivedtime
            }
          >
            {renderDate(createdAt)}
          </p>
        </div>
      </div>
    </>
  );
}

// function ImageButton(props) {
//   return (
//     <>
//       <div style={{ backgroundColor: "#000" }}>
//         <CustomButton2Dark color="inherit" variant="contained">
//           <input
//             type="file"
//             value={this.state.image}
//             style={{ display: "none" }}
//             onChange={(e) => this.setState({ path: e.target.files })}
//           />
//         </CustomButton2Dark>
//       </div>
//     </>
//   );
// }

/* Helper method to format the timestamp of a message. */
function renderDate(timestamp) {
  const n = new Date(timestamp);
  const d = n.getDate();
  const y = n.getFullYear();

  let m = n.getMonth();
  // eslint-disable-next-line default-case
  switch (m) {
    case 0:
      m = "Jan";
      break;
    case 1:
      m = "Feb";
      break;
    case 2:
      m = "Mar";
      break;
    case 3:
      m = "Apr";
      break;
    case 4:
      m = "May";
      break;
    case 5:
      m = "Jun";
      break;
    case 6:
      m = "Jul";
      break;
    case 7:
      m = "Aug";
      break;
    case 8:
      m = "Sep";
      break;
    case 9:
      m = "Oct";
      break;
    case 10:
      m = "Nov";
      break;
    case 11:
      m = "Dec";
      break;
  }

  return m + " " + d + ", " + y;
}
