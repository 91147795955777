import React from "react";
import { CustomButtonDark } from "../Components/Custom/CustomComponents";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../Components/Header";
import styles from "../Styles/Screens";

/**
 * This component is used for ONLY displaying the chatroom of a user that is
 * not currently authed.
 */
export default class UserChatroom extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messages: [], visible: true };
  }

  /* Parse inputted messages after first render. */
  componentDidMount() {
    this.parseChatroom(this.props.location.state.data.chatroom);
  }

  /* Parse out the realtimedb key and save the messages to state. */
  parseChatroom(chatdata) {
    let temparr = [];
    for (let prop in chatdata) {
      temparr.push(chatdata[prop]);
    }

    // Set state of messages once they're parsed.
    this.setState({ messages: temparr, visible: false });
  }

  /* Main render method of component. */
  render() {
    return (
      <>
        <Header history={this.props.history} />

        <div style={styles.messagescontainer}>
          {/* Back button here */}
          <div style={styles.messagesstickybutton}>
            <CustomButtonDark
              color="default"
              variant="contained"
              onClick={() => this.props.history.goBack()}
            >
              Back
            </CustomButtonDark>
          </div>

          {/* Spinner while we parse the data passed to this component. */}
          {this.state.visible && (
            <div style={styles.spinnertopcontainer}>
              <div style={styles.spinnerseccontainer}>
                <CircularProgress />
              </div>
            </div>
          )}

          {/* Main content view */}
          {this.state.messages &&
            this.state.messages.map((msg) => (
              <ChatMessage
                message={msg}
                empemail={this.props.location.state.data.empemail}
              />
            ))}
        </div>
      </>
    );
  }
}

/*
 * Custom chat message component (display the text in a chat bubble with avatar and timestamp.)
 */
function ChatMessage(props) {
  const { text, user, createdAt, image, receiverEmail } = props.message;
  const messageClass = receiverEmail === props.empemail ? "sent" : "received";

  return (
    <>
      <div style={messageClass === "sent" ? styles.send : styles.received}>
        <img src={user.avatar} alt="avatar" style={styles.messageavatar} />

        <div style={styles.messagebody}>
          <p
            style={
              messageClass === "sent" ? styles.sendbody : styles.receivedbody
            }
          >
            {image && (
              <img
                src={image}
                alt="sentimage"
                style={styles.sentimage}
                onClick={() => {
                  window.open(image, "Image");
                }}
              />
            )}

            {text}
          </p>
          <p
            style={
              messageClass === "sent"
                ? styles.messagesendtime
                : styles.messagereceivedtime
            }
          >
            {renderDate(createdAt)}
          </p>
        </div>
      </div>
    </>
  );
}

/*
 * Helper method for parsing the timestamp into a user-friendly date.
 */
function renderDate(timestamp) {
  const n = new Date(timestamp);
  const d = n.getDate();
  const y = n.getFullYear();

  let m = n.getMonth();
  // eslint-disable-next-line default-case
  switch (m) {
    case 0:
      m = "Jan";
      break;
    case 1:
      m = "Feb";
      break;
    case 2:
      m = "Mar";
      break;
    case 3:
      m = "Apr";
      break;
    case 4:
      m = "May";
      break;
    case 5:
      m = "Jun";
      break;
    case 6:
      m = "Jul";
      break;
    case 7:
      m = "Aug";
      break;
    case 8:
      m = "Sep";
      break;
    case 9:
      m = "Oct";
      break;
    case 10:
      m = "Nov";
      break;
    case 11:
      m = "Dec";
      break;
  }

  return m + " " + d + ", " + y;
}
