import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Analytics from "./Screens/Analytics";
import PromoData from "./Screens/PromoData";
import Login from "./Screens/Login";
import Documents from "./Screens/Documents";
import Promos from "./Screens/Promos";
import UnarchivePromo from "./Screens/UnarchivePromo";
import Users from "./Screens/Users";
import Notifications from "./Screens/Notifications";
import SendNotification from "./Screens/SendNotification";
import ShareWithUser from "./Screens/ShareWithUser";
import Messages from "./Screens/Messages";
import ShareDocWithEmp from "./Screens/ShareDocWithEmp";
import AllUsersWith from "./Screens/AllUsersWith";
import UserPromotions from "./Screens/UserPromotions";
import EmpPromos from "./Screens/EmpPromos";
import UserInfo from "./Screens/UserInfo";
import UserDocs from "./Screens/UserDocs";
import AllMessages from "./Screens/AllMessages";
import UserChatroom from "./Screens/UserChatroom";
import More from "./Screens/More";

/*
 * Main app container for the site, handles routing and redirects when needed be.
 */
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/analytics" />
        </Route>
        <Route component={Login} path="/login" />
        <Route component={Analytics} path="/analytics" />
        <Route component={PromoData} path="/promodata" />
        <Route component={Promos} path="/promos" />
        <Route component={UnarchivePromo} path="/unarchivepromo" />
        <Route component={Documents} path="/documents" />
        <Route component={Users} path="/users" />
        <Route component={UserInfo} path="/userinfo" />
        <Route component={Notifications} path="/notifications" />
        <Route component={SendNotification} path="/sendnotification" />
        <Route component={Messages} path="/messages" />
        <Route component={ShareWithUser} path="/sharewithuser" />
        <Route component={ShareDocWithEmp} path="/sharedocwithemp" />
        <Route component={AllUsersWith} path="/givepromo" />
        <Route component={UserPromotions} path="/userpromotions" />
        <Route component={EmpPromos} path="/emppromos" />
        <Route component={AllMessages} path="/chatrooms" />
        <Route component={UserChatroom} path="/userchatroom" />
        <Route component={UserDocs} path="/userdocs" />
        <Route component={More} path="/more" />
      </Switch>
    </Router>
  );
}

export default App;
