import React from "react";
import Header from "../Components/Header";
import { PARENT_SITE } from "../Firebase/FirebaseConfig";
import styles from "../Styles/More";

export default class More extends React.Component {
  render() {
    return (
      <>
        <Header history={this.props.history} />

        <div style={styles.maincontainer}>
          {/* Terms link */}
          <div>
            <a
              style={styles.link}
              href="https://www.autostringer.com/terms-of-use"
            >
              <h1 style={styles.linktitle}>Terms And Conditions</h1>
            </a>
          </div>
          {/* Privacy policy */}
          <div>
            <a
              style={styles.link}
              href="https://www.autostringer.com/privacy-policy"
            >
              <h1 style={styles.linktitle}>Privacy Policy</h1>
            </a>
          </div>
          {/* Parent site */}
          <div>
            <a style={styles.link} href={PARENT_SITE}>
              <h1 style={styles.linktitle}>Parent Site</h1>
            </a>
          </div>
          {/* Help link */}
          <div>
            <a style={styles.link} href="https://www.autostringer.com/help">
              <h1 style={styles.linktitle}>Help</h1>
            </a>
          </div>
        </div>
      </>
    );
  }
}
