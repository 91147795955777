import React from "react";
import { auth } from "../Firebase/FirebaseConfig";
import { Paper, IconButton, Collapse, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { getCurrentData } from "../Helpers/Data";

// Import styles
import {
  CustomButton,
  CustomTextField,
} from "../Components/Custom/CustomComponents";
import CloseIcon from "@material-ui/icons/Close";
import styles from "../Styles/Screens";

// If doing this to create a user, make sure to update the photoURL so you don't get a crash on the mobile app.
// adminRef.push({
//   PIN: "000000",
//   avatar:
//     "https://firebasestorage.googleapis.com/v0/b/autostringer-4c687.appspot.com/o/south-county-autos%2FB9oPFHzAoJdav3nkNeCQTio6gIU2%2FProfilePics%2Fi8mw50p4o08vkvc4jeyy4q.jpg?alt=media&token=ef7839de-6c27-4f58-b431-f8b2b76cf9a5",
//   email: "lundj@mail.gvsu.edu",
//   fcmToken: "",
//   id: "JiXXMR6bE5dDFGcbLc3tWlZQsyJ2",
//   name: "Admin James",
//   officeNum: "0000000000",
//   phoneNum: "1111111111",
//   role: "Admin",
// });

/*
 * Main login handler for the app.
 * On the webapp, we don't sign up users, an admin should already have an account
 * and they can create new users. (This is done for security)
 */
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      message: "We're sorry but only employees can use this site.",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /* Check to see if a user is already logged in. If so send them to the users screen. */
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.props.history.push("/users");
      }
    });
  }

  /* Submission handler for when a user is signing in. */
  handleSubmit(e) {
    e.preventDefault();

    // Start spinner for 2 seconds
    this.setState({ loading: true });

    // Check if user is emp-admin in database then sign them in.
    getCurrentData(this.state.email.trim())
      .then((res) => {
        auth
          .signInWithEmailAndPassword(
            this.state.email.trim(),
            this.state.password
          )
          .then(() => {
            // Stop spinner
            this.setState({ loading: false });

            // Save for later.
            console.log("ROLE:", res.role);
            localStorage.setItem("myRole", res.role);
            this.props.history.push("/users", { fbData: res });
          })
          .catch((e) => {
            let message = "";

            switch (e.code) {
              case "auth/wrong-password":
                message = "Your password is incorrect.";
                break;
              case "auth/user-not-found":
                message = "There is no employee account with that email.";
                break;
              case "auth/network-request-failed":
                message =
                  "We're having some trouble signing you in. Please try again later.";
                break;
              case "auth/user-disabled":
                message = "That email address has been disabled";
                break;
              default:
                message =
                  "We're having some trouble signing you in. Please try again later.";
            }
            this.setState({
              alert: true,
              loading: false,
              message: message,
            });
          });
      })
      .catch((e) => {
        this.setState({
          alert: true,
          loading: false,
          message: "Only Employee's may use this website.",
        });
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <div style={styles.logincontainer}>
        {/* Show alert if user isn't supposed to login to this site or if password is incorrect. */}
        <Collapse in={this.state.alert} style={styles.logincollapse}>
          <Alert
            variant="filled"
            severity="error"
            style={styles.loginalert}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => this.setState({ alert: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {this.state.message}
          </Alert>
        </Collapse>

        {/* Main component for logging in a user. */}
        <Paper elevation={3} style={styles.loginpaper}>
          <form style={styles.loginform} onSubmit={this.handleSubmit}>
            <div style={styles.loginformcontainer}>
              <h1 style={styles.loginheader}>South County Auto's Admin</h1>
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="email"
                value={this.state.email}
                placeholder="Email"
                required
                style={styles.logintextfield}
                onChange={(email) =>
                  this.setState({ email: email.target.value })
                }
              />
              <CustomTextField
                id="outlined-basic"
                variant="outlined"
                type="password"
                required
                value={this.state.password}
                placeholder="Password"
                onChange={(password) =>
                  this.setState({ password: password.target.value })
                }
              />

              {/* Show loading spinner. */}
              {this.state.loading && (
                <LinearProgress style={{ marginTop: 20, marginBottom: -10 }} />
              )}

              {/* Submission button. */}
              <CustomButton
                variant="contained"
                color="default"
                type="submit"
                style={styles.loginbutton}
              >
                Login
              </CustomButton>
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}
