import React from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Paper,
} from "@material-ui/core";
import styles from "../../Styles/Components";

let myRole = localStorage.getItem("myRole");
/*
 * The component that allows employee's to toggle the abilities of
 * users customers employees managers and admins. Logic to change this in
 * the database is contained in the UserInfo.js screen.
 */
export default class DisableSwitches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canUpload: this.props.user.canUpload,
      canShare: this.props.user.canShare,
      canMessage: this.props.user.canMessage,
      canViewAllDocs: this.props.user.canViewAllDocs,
    };
  }

  componentDidMount() {
    myRole = localStorage.getItem("myRole");
  }

  /* Main render method of component. */
  render() {
    return (
      <Paper elevation={3} style={styles.disablecontainer}>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              value={this.state.canUpload}
              style={styles.disableswitch}
              disabled={
                (this.props.userRole === "Employee" && myRole === "Employee") ||
                (this.props.user.id === this.props.myId
                  ? true
                  : this.props.isUser
                  ? true
                  : false)
              }
              control={
                <Switch
                  color="primary"
                  checked={this.state.canUpload}
                  onChange={(e) =>
                    this.setState({ canUpload: e.target.checked }, () =>
                      this.props.saveState(this.state)
                    )
                  }
                />
              }
              label="Can Upload Documents"
              labelPlacement="start"
            />
            <FormControlLabel
              value={this.state.canShare}
              style={styles.disableswitch}
              disabled={
                (this.props.userRole === "Employee" && myRole === "Employee") ||
                (this.props.user.id === this.props.myId
                  ? true
                  : this.props.isEmp
                  ? false
                  : true)
              }
              control={
                <Switch
                  color="primary"
                  checked={this.state.canShare}
                  onChange={(e) =>
                    this.setState({ canShare: e.target.checked }, () =>
                      this.props.saveState(this.state)
                    )
                  }
                />
              }
              label="Can Share Documents"
              labelPlacement="start"
            />
            <FormControlLabel
              value={this.state.canMessage}
              style={styles.disableswitch}
              disabled={
                (this.props.userRole === "Employee" && myRole === "Employee") ||
                (this.props.user.id === this.props.myId ? true : false)
              }
              control={
                <Switch
                  color="primary"
                  checked={this.state.canMessage}
                  onChange={(e) =>
                    this.setState({ canMessage: e.target.checked }, () =>
                      this.props.saveState(this.state)
                    )
                  }
                />
              }
              label="Can Send Messages"
              labelPlacement="start"
            />
            {this.props.userRole !== "User" &&
              this.props.userRole !== "Customer" && (
                <FormControlLabel
                  value={this.state.canViewAllDocs}
                  style={styles.disableswitch}
                  disabled={myRole !== "Admin"}
                  control={
                    <Switch
                      color="primary"
                      checked={this.state.canViewAllDocs}
                      onChange={(e) =>
                        this.setState(
                          { canViewAllDocs: e.target.checked },
                          () => this.props.saveState(this.state)
                        )
                      }
                    />
                  }
                  label="Can View Other's Documents"
                  labelPlacement="start"
                />
              )}
          </FormGroup>
        </FormControl>
      </Paper>
    );
  }
}
