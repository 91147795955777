import React from "react";
import {
  archivePromosRef,
  promosRef,
  userRef,
} from "../../Firebase/FirebaseConfig";

// Styled components
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import MenuIcon from "@material-ui/icons/Menu";
import { CustomButton2, CustomTextField } from "../Custom/CustomComponents";
import styles from "../../Styles/Components";

/*
 * This component should return the name and code of each promotion that we are
 * currently offering. The table will also have buttons that lead to a screen that
 * shows all employee's and how many promos they redeemed and the other will show
 * all users who have the promo and how many redemptions that still have that are
 * valid.
 */
export default class ListPromos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promos: [],
      allpromos: [],
      userarr: [],
      filteredArray: [],
      archivearr: [],
      anchor: null,
      menu: false,
    };
  }

  /* Get the necessary data everytime the component is mounted. */
  componentDidMount() {
    this.getData();
  }

  /* Get all of the promos once the component has been loaded. */
  getData() {
    let temparr = [];
    let userarr = [];
    let archivedarr = [];

    // Grab the promo node once from realtimedb.
    promosRef.once("value", (snapshot) => {
      // Iterate over the data and push each promotion to an array to set state.
      snapshot.forEach((snap) => {
        temparr.push(snap.val());
        console.log(temparr.length);
        this.setState({ promos: temparr, allpromos: temparr }, () =>
          this.filterTable("Enabled")
        );
      });
    });

    userRef.once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        userarr.push(snap.val());
        this.setState({ userarr: userarr });
      });
    });

    archivePromosRef.once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        archivedarr.push(snap.val());
        this.setState({ archivearr: archivedarr });
      });
    });
  }

  /* Inputs a filter property then reloads data from specific node. */
  filterTable(filterBy) {
    let temparr = [];
    let indexarr = [];
    let anonarr = [];
    let remainingPromotions = 0;
    let redeemedPromotions = 0;
    let promoarray = this.state.allpromos;
    let allpromos = promoarray;
    let userarray = this.state.userarr;

    // Clear promo array of redeemed promos
    // for (let i = 0; i < promoarray.length; i++) {
    //   promoarray[i].remaining = 0;
    // }

    console.log("prom:", JSON.stringify(promoarray));

    if (filterBy === "Disabled") {
      for (let i = 0; i < promoarray.length; i++) {
        if (promoarray[i].disabled) {
          temparr.push(promoarray[i]);
        }
      }
      promoarray = temparr;
    }

    console.log("filterby:", filterBy);
    console.log("ARR:", JSON.stringify(promoarray));

    if (filterBy !== "Archived") {
      // For each promo, find it amongst all users.
      for (let i = 0; i < promoarray.length; i++) {
        let remaining = 0;
        let redeemed = 0;
        let total = 0;

        if (promoarray[i].disabled && filterBy === "Disabled") {
          console.log("wow");
          console.log("here", promoarray[i].disabled);

          // For each user, search their promos for the selected promo.
          for (let j = 0; j < userarray.length; j++) {
            // If the user has any promotions at all.
            if (userarray[j].promos) {
              let userpromos = Object.values(userarray[j].promos);

              // Check each promo to see if it is the same as the selected one.
              for (let k = 0; k < userpromos.length; k++) {
                // If promo is the same as the selected one.
                if (userpromos[k].id === promoarray[i].id) {
                  let uses = userpromos[k].uses;
                  total += parseInt(promoarray[i].uses, 10);
                  remaining += parseInt(uses, 10);
                  redeemed = total - remaining;
                  remaining = remaining !== undefined ? remaining : 0;
                  redeemed = redeemed !== undefined ? redeemed : 0;
                  total = total !== undefined ? total : 0;

                  // Update values for promoarray
                  promoarray[i].remaining = remaining;
                  promoarray[i].redeemed = redeemed;
                  promoarray[i].total = total;
                }
              }
            }
          }
          anonarr.push(promoarray[i]);
        } else if (promoarray[i].disabled !== true && filterBy === "Enabled") {
          console.log("here", promoarray[i].disabled);
          // For each user, search their promos for the selected promo.
          for (let j = 0; j < userarray.length; j++) {
            // If the user has any promotions at all.
            if (userarray[j].promos) {
              let userpromos = Object.values(userarray[j].promos);

              // Check each promo to see if it is the same as the selected one.
              for (let k = 0; k < userpromos.length; k++) {
                // If promo is the same as the selected one.
                if (userpromos[k].id === promoarray[i].id) {
                  let uses = userpromos[k].uses;
                  total += parseInt(promoarray[i].uses, 10);
                  remaining += parseInt(uses, 10);
                  redeemed = total - remaining;
                  remaining = remaining !== undefined ? remaining : 0;
                  redeemed = redeemed !== undefined ? redeemed : 0;
                  total = total !== undefined ? total : 0;

                  // Update values for promoarray
                  promoarray[i].remaining = remaining;
                  promoarray[i].redeemed = redeemed;
                  promoarray[i].total = total;
                }
              }
            }
          }
          anonarr.push(promoarray[i]);
        } else {
          console.log("nope im here");
          remainingPromotions = 0;
          redeemedPromotions = 0;
          indexarr.push(i);
        }

        remainingPromotions += promoarray[i].remaining =
          promoarray[i].remaining !== undefined ? promoarray[i].remaining : 0;
        redeemedPromotions += promoarray[i].redeemed =
          promoarray[i].redeemed !== undefined ? promoarray[i].redeemed : 0;
      }

      // for (let i = 0; i < indexarr.length; i++) {
      //   promoarray.splice(indexarr[i], 1);
      // }

      this.setState({
        promos: anonarr,
        allpromos: allpromos,
        filterBy: filterBy,
        redeemedPromotions: redeemedPromotions,
        remainingPromotions: remainingPromotions,
      });
    } else {
      // Download this data on the client when the page loads instead of here.

      promoarray = this.state.archivearr;

      // Clear promo array of redeemed promos
      // for (let i = 0; i < promoarray.length; i++) {
      //   promoarray[i].remaining = 0;
      // }

      // For each promo, find it amongst all users.
      for (let i = 0; i < promoarray.length; i++) {
        let remaining = 0;
        let redeemed = 0;
        let total = 0;

        // For each user, search their promos for the selected promo.
        for (let j = 0; j < userarray.length; j++) {
          // If the user has any promotions at all.
          if (userarray[j].promos) {
            let userpromos = Object.values(userarray[j].promos);

            // Check each promo to see if it is the same as the selected one.
            for (let k = 0; k < userpromos.length; k++) {
              // If promo is the same as the selected one.
              if (userpromos[k].id === promoarray[i].id) {
                let uses = userpromos[k].uses;
                total += parseInt(promoarray[i].uses, 10);
                remaining += parseInt(uses, 10);
                redeemed = total - remaining;
                remaining = remaining !== undefined ? remaining : 0;
                redeemed = redeemed !== undefined ? redeemed : 0;
                total = total !== undefined ? total : 0;

                // Update promo array values
                promoarray[i].remaining = remaining;
                promoarray[i].redeemed = redeemed;
                promoarray[i].total = total;
              }
            }
          }
        }

        remainingPromotions += promoarray[i].remaining =
          promoarray[i].remaining !== undefined ? promoarray[i].remaining : 0;
        redeemedPromotions += promoarray[i].redeemed =
          promoarray[i].redeemed !== undefined ? promoarray[i].redeemed : 0;
      }

      // Double checked on 12/15/2020 and this all works fine.

      this.setState({
        promos: promoarray,
        allpromos: allpromos,
        filterBy: "Archived",
        redeemedPromotions: redeemedPromotions,
        remainingPromotions: remainingPromotions,
      });
    }
  }

  /* Handler for when search is updated. */
  updateSearch = (search) => {
    var updatedData = [];
    if (search === null || search === undefined || search === "") {
      updatedData = [];
    } else if (typeof search == "object") {
      updatedData.push(search);
    } else {
      // User didn't click on a card, show all cases of users with first name
      // relative to the search.
      this.state.promos.forEach((element) => {
        let lower = element.name.toLowerCase();
        let code = element.code.toLowerCase();

        if (
          lower.includes(search.toLowerCase()) ||
          code.includes(search.toLowerCase())
        ) {
          updatedData.push(element);
        }
      });
    }
    this.setState({ filteredArray: updatedData });
  };

  /* Sorting handler for filtering out data properly. */
  sortData = (sort, comparator) => {
    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.promos;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    // Sort should be a string of the selected column
    if (sort === "Name" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "Name" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    } else if (sort === "Code" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.code.toLowerCase() < b.code.toLowerCase()) {
          return -1;
        }
        if (a.code.toLowerCase() > b.code.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "Code" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.code.toLowerCase() > b.code.toLowerCase()) {
          return -1;
        }
        if (a.code.toLowerCase() < b.code.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    } else if (sort === "Redempt" && comparator === "asc") {
      array = array.sort((a, b) => {
        a.uses = a.uses === undefined ? 0 : a.uses;
        b.uses = b.uses === undefined ? 0 : b.uses;

        if (a.uses < b.uses) {
          return -1;
        }
        if (a.uses > b.uses) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "Redempt" && comparator === "desc") {
      array = array.sort((a, b) => {
        a.uses = a.uses === undefined ? 0 : a.uses;
        b.uses = b.uses === undefined ? 0 : b.uses;

        if (a.uses > b.uses) {
          return -1;
        }
        if (a.uses < b.uses) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    } else if (sort === "Remain" && comparator === "asc") {
      array = array.sort((a, b) => {
        a.remaining = a.remaining === undefined ? 0 : a.remaining;
        b.remaining = b.remaining === undefined ? 0 : b.remaining;

        if (a.remaining < b.remaining) {
          return -1;
        }
        if (a.remaining > b.remaining) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "Remain" && comparator === "desc") {
      array = array.sort((a, b) => {
        a.remaining = a.remaining === undefined ? 0 : a.remaining;
        b.remaining = b.remaining === undefined ? 0 : b.remaining;

        if (a.remaining > b.remaining) {
          return -1;
        }
        if (a.remaining < b.remaining) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    } else if (sort === "Redeemed" && comparator === "asc") {
      array = array.sort((a, b) => {
        a.redeemed = a.redeemed === undefined ? 0 : a.redeemed;
        b.redeemed = b.redeemed === undefined ? 0 : b.redeemed;

        if (a.redeemed < b.redeemed) {
          return -1;
        }
        if (a.redeemed > b.redeemed) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "Redeemed" && comparator === "desc") {
      array = array.sort((a, b) => {
        a.redeemed = a.redeemed === undefined ? 0 : a.redeemed;
        b.redeemed = b.redeemed === undefined ? 0 : b.redeemed;

        if (a.redeemed > b.redeemed) {
          return -1;
        }
        if (a.redeemed < b.redeemed) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    }
  };

  /* Main render method of component. */
  render() {
    const { search } = this.state;
    const filter = createFilterOptions();
    return (
      <Paper elevation={3} style={styles.listpromomain}>
        <div style={styles.listpromotopcontainer}>
          <IconButton
            aria-label="menu"
            size="medium"
            style={styles.promofilter}
            onClick={(event) => {
              this.setState({
                menu: !this.state.menu,
                anchor: event.currentTarget,
              });
            }}
          >
            <MenuIcon size="medium" />
            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={this.state.anchor}
              open={this.state.menu}
              onClose={() => {
                this.setState({ menu: false, anchor: null });
              }}
            >
              <MenuItem onClick={() => this.filterTable("Enabled")}>
                Enabled Promos
              </MenuItem>
              <MenuItem onClick={() => this.filterTable("Disabled")}>
                Disabled Promos
              </MenuItem>
              <MenuItem onClick={() => this.filterTable("Archived")}>
                Archived Promos
              </MenuItem>
            </Menu>
          </IconButton>
          <div style={styles.listpromosearchcontainer}>
            <Autocomplete
              value={search}
              onChange={(event, newValue) => this.updateSearch(newValue)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={this.state.promos}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }

                // Regular option
                return option.name + " - " + option.code;
              }}
              renderOption={(option) => option.name + " - " + option.code}
              style={styles.userssearchbar}
              freeSolo
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  placeholder="Search..."
                  variant="outlined"
                />
              )}
            />
          </div>
        </div>

        {/* Filter by container with some summarization. */}
        <div style={styles.listpromostatsbar}>
          <h2 style={styles.listpromostatstext}>
            Filtering By: {this.state.filterBy}
          </h2>
          <h4 style={styles.listpromostatstext}>
            Remaining Redemptions: {this.state.remainingPromotions}
          </h4>
          <h4 style={styles.listpromostatstext}>
            Redemptions: {this.state.redeemedPromotions}
          </h4>
        </div>
        <TableContainer component={Paper} style={styles.tablecontainer}>
          <Table style={styles.table}>
            <TableHead style={styles.tableHead}>
              <TableRow>
                <TableCell style={styles.rowText}>
                  <TableSortLabel
                    active={this.state.sort === "Name"}
                    direction={this.state.filter === "desc" ? "desc" : "asc"}
                    onClick={() =>
                      this.state.filter === "asc"
                        ? this.sortData("Name", "desc")
                        : this.sortData("Name", "asc")
                    }
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell style={styles.rowText}>
                  <TableSortLabel
                    active={this.state.sort === "Code"}
                    direction={this.state.filter === "desc" ? "desc" : "asc"}
                    onClick={() =>
                      this.state.filter === "asc"
                        ? this.sortData("Code", "desc")
                        : this.sortData("Code", "asc")
                    }
                  >
                    Code
                  </TableSortLabel>
                </TableCell>
                <TableCell style={styles.rowText}>Employee Data</TableCell>
                <TableCell style={styles.rowText}>User Data</TableCell>
                <TableCell style={styles.rowText}>
                  <TableSortLabel
                    active={this.state.sort === "Redempt"}
                    direction={this.state.filter === "desc" ? "desc" : "asc"}
                    onClick={() =>
                      this.state.filter === "asc"
                        ? this.sortData("Redempt", "desc")
                        : this.sortData("Redempt", "asc")
                    }
                  >
                    Redemptions Per Promo
                  </TableSortLabel>
                </TableCell>
                <TableCell style={styles.rowText}>
                  <TableSortLabel
                    active={this.state.sort === "Remain"}
                    direction={this.state.filter === "desc" ? "desc" : "asc"}
                    onClick={() =>
                      this.state.filter === "asc"
                        ? this.sortData("Remain", "desc")
                        : this.sortData("Remain", "asc")
                    }
                  >
                    Remaining
                  </TableSortLabel>
                </TableCell>
                <TableCell style={styles.rowText}>
                  <TableSortLabel
                    active={this.state.sort === "Redeemed"}
                    direction={this.state.filter === "desc" ? "desc" : "asc"}
                    onClick={() =>
                      this.state.filter === "asc"
                        ? this.sortData("Redeemed", "desc")
                        : this.sortData("Redeemed", "asc")
                    }
                  >
                    Redeemed
                  </TableSortLabel>
                </TableCell>
                {this.state.filterBy === "Archived" && (
                  <TableCell style={styles.rowText}>Unarchive</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.filteredArray.length === 0 &&
                this.state.promos.map((promo, index) => (
                  <TableRow key={index}>
                    <TableCell style={styles.rowText}>{promo.name}</TableCell>
                    <TableCell style={styles.rowText}>{promo.code}</TableCell>
                    <TableCell style={styles.rowText}>
                      <CustomButton2
                        onClick={() =>
                          this.props.history.push({
                            pathname: "promodata",
                            state: {
                              promo: promo,
                              promoId: promo.id,
                              table: "emps",
                            },
                          })
                        }
                      >
                        View Data
                      </CustomButton2>
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <CustomButton2
                        onClick={() =>
                          this.props.history.push({
                            pathname: "promodata",
                            state: {
                              promo: promo,
                              promoId: promo.id,
                              table: "users",
                            },
                          })
                        }
                      >
                        View Data
                      </CustomButton2>
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      {promo.uses ? promo.uses : 0}
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      {promo.remaining ? promo.remaining : 0}
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      {promo.redeemed ? promo.redeemed : 0}
                    </TableCell>
                    {this.state.filterBy === "Archived" && (
                      <TableCell style={styles.rowText}>
                        <CustomButton2
                          color="default"
                          variant="contained"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "unarchivepromo",
                              state: { promo: promo },
                            });
                          }}
                        >
                          Unarchive
                        </CustomButton2>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              {this.state.filteredArray.map((promo, index) => (
                <TableRow key={index}>
                  <TableCell style={styles.rowText}>{promo.name}</TableCell>
                  <TableCell style={styles.rowText}>{promo.code}</TableCell>
                  <TableCell style={styles.rowText}>
                    <CustomButton2
                      onClick={() =>
                        this.props.history.push({
                          pathname: "promodata",
                          state: {
                            promo: promo,
                            promoId: promo.id,
                            table: "emps",
                          },
                        })
                      }
                    >
                      View Data
                    </CustomButton2>
                  </TableCell>
                  <TableCell style={styles.rowText}>
                    <CustomButton2
                      onClick={() =>
                        this.props.history.push({
                          pathname: "promodata",
                          state: {
                            promo: promo,
                            promoId: promo.id,
                            table: "users",
                          },
                        })
                      }
                    >
                      View Data
                    </CustomButton2>
                  </TableCell>
                  <TableCell style={styles.rowText}>
                    {promo.uses ? promo.uses : 0}
                  </TableCell>
                  <TableCell style={styles.rowText}>
                    {promo.remaining ? promo.remaining : 0}
                  </TableCell>
                  <TableCell style={styles.rowText}>
                    {promo.redeemed ? promo.redeemed : 0}
                  </TableCell>
                  {this.state.filterBy === "Archived" && (
                    <TableCell style={styles.rowText}>
                      <CustomButton2
                        color="default"
                        variant="contained"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "unarchivepromo",
                            state: { promo: promo },
                          });
                        }}
                      >
                        Unarchive
                      </CustomButton2>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}
