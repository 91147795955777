import colors from "./Colors";

const styles = {
  maincontainer: {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: colors.primarybackground,
  },
  secondarycontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    marginTop: "10vh",
    display: "flex",
    padding: 20,
    backgroundColor: colors.white,
    maxWidth: 600,
    marginBottom: "5vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formheader: {
    textAlign: "center",
    color: colors.black,
  },
  formtext: {
    color: colors.black,
    marginTop: 5,
    marginBottom: 5,
  },
  buttoncontainer: {
    alignSelf: "center",
    width: "100%",
  },
  button: {
    width: "100%",
    marginTop: 10,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalpaper: {
    width: 500,
    padding: 20,
    backgroundColor: colors.white,
  },
  modalpaperheader: {
    textAlign: "center",
    color: colors.black,
  },
  modalbutton: {
    width: "100%",
  },
};

export default styles;
