import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Link,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { auth, userRef } from '../Firebase/FirebaseConfig';

// Import modals
import {
  ShowConfirmGiveDocument,
  ErrorModal,
  CompleteModal,
} from '../Components/Modals/DocumentModals';

// Import components
import Header from '../Components/Header';

// Import styles
import styles from '../Styles/Screens';
import colors from '../Styles/Colors';

// Import custom components
import {
  CustomButtonDark,
  CustomButton2Dark,
  CustomTextField,
} from '../Components/Custom/CustomComponents';

/*
 * Screen that displays all user's an employee can share a promotion with.
 */
export default class ShareWithUser extends React.Component {
  constructor(props) {
    super(props);

    // Init empty arrays to stop prerender errors.
    this.state = {
      users: [],
      filteredArray: [],
      user: { email: null },
      filter: 'desc',
    };

    // Bind modals in local namespace
    this.closeModals = this.closeModals.bind(this);
    this.promptModal = this.promptModal.bind(this);
  }

  /* Check authentication status of user and send them to login screen if they're not signed in. */
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      user ? this.getUsers(user.uid) : this.props.history.push('/login');
    });
  }

  /* Get all users and save them to state. */
  getUsers(empId) {
    const users = [];
    userRef.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        if (!users.includes(snap.val())) {
          const user = snap.val();
          if (snap.val().private_files) {
            const images = Object.values(snap.val().private_files);
            for (let i = 0; i < images.length; i++) {
              if (images[i].originalId === auth.currentUser.uid) {
                user.docs = true;
              }
            }
          }
          if (snap.val().private_nonimgfiles) {
            const nonimages = Object.values(snap.val().private_nonimgfiles);
            for (let i = 0; i < nonimages.length; i++) {
              if (nonimages[i].originalId === auth.currentUser.uid) {
                user.docs = true;
              }
            }
          }
          users.push(user);
        }
      });
      this.setState({ users, myId: empId });
    });
  }

  /* Search handler for when employee's are searching for users. */
  updateSearch = (search) => {
    var updatedData = [];
    if (search === null || search === undefined || search === '') {
      updatedData = [];
    } else if (typeof search == 'object') {
      updatedData.push(search);
    } else {
      // User didn't click on a card, show all cases of users with first name
      // relative to the search.
      this.state.users.forEach((element) => {
        let lower = element.name.toLowerCase();
        let email = element.email.toLowerCase();

        if (
          lower.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase())
        ) {
          updatedData.push(element);
        }
      });
    }
    this.setState({ filteredArray: updatedData });
  };

  /* Sort data based on inputted parameter. */
  sortData = (sort, comparator) => {
    console.log(sort, comparator);

    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.users;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    // Sort should be a string of the selected column
    if (sort === 'Name' && comparator === 'asc') {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'asc', sort: sort })
        : this.setState({ users: array, filter: 'asc', sort: sort });
    } else if (sort === 'Name' && comparator === 'desc') {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'desc', sort: sort })
        : this.setState({ users: array, filter: 'desc', sort: sort });
    } else if (sort === 'Email' && comparator === 'asc') {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'asc', sort: sort })
        : this.setState({ users: array, filter: 'asc', sort: sort });
    } else if (sort === 'Email' && comparator === 'desc') {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'desc', sort: sort })
        : this.setState({ users: array, filter: 'desc', sort: sort });
    } else if (sort === 'Role' && comparator === 'asc') {
      array = array.sort((a, b) => {
        if (a.role.toLowerCase() > b.role.toLowerCase()) {
          // no/yes
          return 1;
        }
        if (a.role.toLowerCase() === b.role.toLowerCase()) {
          // no/no yes/yes
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
        }
        if (a.role.toLowerCase() < b.role.toLowerCase()) {
          // yes/no
          return -1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'asc', sort: sort })
        : this.setState({ users: array, filter: 'asc', sort: sort });
    } else if (sort === 'Role' && comparator === 'desc') {
      array = array.sort((a, b) => {
        if (a.role.toLowerCase() < b.role.toLowerCase()) {
          // no/yes
          return 1;
        }
        if (a.role.toLowerCase() === b.role.toLowerCase()) {
          // no/no yes/yes
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
        }
        if (a.role.toLowerCase() > b.role.toLowerCase()) {
          // yes/no
          return -1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'desc', sort: sort })
        : this.setState({ users: array, filter: 'desc', sort: sort });
    }
  };

  /* Close all modals. */
  closeModals = () => {
    this.setState({
      showGiveUserPromoModal: false,
      modal: false,
      showConfirmGivePromotion: false,
      user: { email: null },
    });
  };

  /* Prompt the user with a confirmation modal that they want to give the promo to a user. */
  promptModal = (modal) => {
    this.setState({ modal: modal, showGiveUserPromoModal: false });
  };

  /* Main render method of component. */
  render() {
    const { search } = this.state;
    const filter = createFilterOptions();
    return (
      <>
        <Header history={this.props.history} />
        <div style={styles.userscontainer}>
          <div style={styles.userscontentcontainer}>
            <CustomButtonDark
              color='inherit'
              onClick={() => this.props.history.goBack()}
            >
              Back
            </CustomButtonDark>
            <h3 style={styles.usersheader}>
              *This screen shows all users and customers.
            </h3>

            <Autocomplete
              value={search}
              onChange={(event, newValue) => this.updateSearch(newValue)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id='free-solo-with-text-demo'
              options={this.state.users}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }

                // Regular option
                return option.name + ' - ' + option.email;
              }}
              renderOption={(option) => option.name + ' - ' + option.email}
              style={styles.sharedocautocomplete}
              freeSolo
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  placeholder='Search...'
                  variant='outlined'
                />
              )}
            />
          </div>

          {/* TableView */}
          <TableContainer component={Paper} style={styles.tableContainer}>
            <Table style={styles.table}>
              <TableHead style={styles.tableHead}>
                <TableRow>
                  <TableCell style={styles.rowText}>
                    <TableSortLabel
                      active={this.state.sort === 'Name'}
                      direction={this.state.filter === 'desc' ? 'desc' : 'asc'}
                      onClick={() =>
                        this.state.filter === 'asc'
                          ? this.sortData('Name', 'desc')
                          : this.sortData('Name', 'asc')
                      }
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowText}>
                    <TableSortLabel
                      active={this.state.sort === 'Email'}
                      direction={this.state.filter === 'desc' ? 'desc' : 'asc'}
                      onClick={() =>
                        this.state.filter === 'asc'
                          ? this.sortData('Email', 'desc')
                          : this.sortData('Email', 'asc')
                      }
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowText}>
                    <TableSortLabel
                      active={this.state.sort === 'Role'}
                      direction={this.state.filter === 'desc' ? 'desc' : 'asc'}
                      onClick={() =>
                        this.state.filter === 'asc'
                          ? this.sortData('Role', 'desc')
                          : this.sortData('Role', 'asc')
                      }
                    >
                      Role
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowText}>Documents</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredArray.length === 0 &&
                  this.state.users.map((user, index) => (
                    <TableRow
                      key={index}
                      style={
                        this.state.user.email === user.email
                          ? {
                              cursor: 'pointer',
                              backgroundColor: colors.highlight,
                            }
                          : { cursor: 'pointer' }
                      }
                      onClick={() =>
                        this.setState({
                          showConfirmGivePromotion: true,
                          user: user,
                        })
                      }
                    >
                      <TableCell style={styles.rowText}>{user.name}</TableCell>
                      <TableCell style={styles.rowText}>
                        <Link href={`mailto:${user.email}`} color='inherit'>
                          {user.email}
                        </Link>
                      </TableCell>
                      <TableCell style={styles.rowText}>{user.role}</TableCell>
                      <TableCell style={styles.rowText}>
                        <CustomButton2Dark
                          color='default'
                          variant='contained'
                          disabled={!user.docs}
                          onClick={() => {
                            this.props.history.push({
                              pathname: 'userdocs',
                              state: { user: user, myId: this.state.myId },
                            });
                          }}
                        >
                          View
                        </CustomButton2Dark>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.filteredArray.map((user, index) => (
                  <TableRow
                    key={index}
                    style={
                      this.state.user.email === user.email
                        ? {
                            cursor: 'pointer',
                            backgroundColor: colors.highlight,
                          }
                        : { cursor: 'pointer' }
                    }
                    onClick={() =>
                      this.setState({
                        showConfirmGivePromotion: true,
                        user: user,
                      })
                    }
                  >
                    <TableCell style={styles.rowText}>{user.name}</TableCell>
                    <TableCell style={styles.rowText}>
                      <Link href={`mailto:${user.email}`} color='inherit'>
                        {user.email}
                      </Link>
                    </TableCell>
                    <TableCell style={styles.rowText}>{user.role}</TableCell>
                    <TableCell style={styles.rowText}>
                      <CustomButton2Dark
                        color='default'
                        variant='contained'
                        disabled={!user.docs}
                        onClick={() => {
                          this.props.history.push({
                            pathname: 'userdocs',
                            state: { user: user, myId: this.state.myId },
                          });
                        }}
                      >
                        View
                      </CustomButton2Dark>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Show modals */}
          {this.state.showConfirmGivePromotion && (
            <ShowConfirmGiveDocument
              showError={() => this.promptModal('error')}
              close={this.closeModals}
              user={this.state.user}
              files={this.props.location.state.files}
              showComplete={() => this.promptModal('complete')}
            />
          )}

          {this.state.modal === 'error' && (
            <ErrorModal close={this.closeModals} />
          )}

          {this.state.modal === 'complete' && (
            <CompleteModal close={this.closeModals} />
          )}
        </div>
      </>
    );
  }
}
