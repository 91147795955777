import React from "react";
import { CustomButtonDark } from "../Custom/CustomComponents";
import {
  auth,
  userRef,
  dealership,
  firebaseRef as firebase,
  defaultnotification,
} from "../../Firebase/FirebaseConfig";

// Import modals
import { ErrorModal, CompletionModal } from "../Modals/NotificationUserModals";
import { SendNotificationModal } from "../Modals/NotificationSendModals";
import { InvalidPermission } from "../Alerts";

export default class SendNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    console.log("PROPS", JSON.stringify(this.props));

    this.sendNotification = this.sendNotification.bind(this);
  }

  componentDidMount() {
    console.log(JSON.stringify(this.props));
  }

  /* Give the notification to the selected user in firebase. */
  sendNotification() {
    const userId = this.props.user.id;

    const code = Math.floor(100000 + Math.random() * 900000);
    const userCode = Math.floor(100000 + Math.random() * 900000);

    var notification = {};
    notification.title = dealership;
    notification.givenByEmail = auth.currentUser.email;
    notification.givenBy = auth.currentUser.uid;
    notification.image = defaultnotification;
    notification.body =
      "You have unused prepaid-services. Stop by to redeem them now!";
    notification.code = code;
    notification.userCode = userCode;
    notification.time = firebase.database.ServerValue.TIMESTAMP;

    userRef
      .orderByChild("id")
      .equalTo(userId)
      .once("child_added", (snapshot) => {
        if (snapshot.val()) {
          userRef
            .child(snapshot.key)
            .child("notifications")
            .push(notification)
            .then(() => this.setState({ showCompletionModal: true }))
            .catch((error) => {
              console.log("Error updating firebase:", error);
              this.setState({ showErrorModal: true });
            });
        }
      });
  }

  render() {
    return (
      <>
        <CustomButtonDark
          color="default"
          variant="contained"
          onClick={() => {
            this.setState({ showConfirmationModal: true });
          }}
        >
          SEND PUSH
        </CustomButtonDark>

        {/* Show modals */}
        {this.state.showConfirmationModal && (
          <SendNotificationModal
            user={this.props.user}
            submit={this.sendNotification}
            close={() => this.setState({ showConfirmationModal: false })}
            invalidPermission={() =>
              this.setState({
                showInvalidPermission: true,
                showSendNotificationModal: false,
              })
            }
          />
        )}
        {this.state.showCompletionModal && (
          <CompletionModal
            user={this.props.user}
            close={() =>
              this.setState({
                showCompletionModal: false,
                showErrorModal: false,
                showInvalidPermission: false,
                showConfirmationModal: false,
              })
            }
          />
        )}
        {this.state.showErrorModal && (
          <ErrorModal
            close={() =>
              this.setState({
                showCompletionModal: false,
                showErrorModal: false,
                showInvalidPermission: false,
                showConfirmationModal: false,
              })
            }
          />
        )}
        {this.state.showInvalidPermission && (
          <InvalidPermission
            close={() =>
              this.setState({
                showCompletionModal: false,
                showErrorModal: false,
                showInvalidPermission: false,
                showConfirmationModal: false,
              })
            }
          />
        )}
      </>
    );
  }
}
