import React from "react";
import {
  Typography,
  Toolbar,
  AppBar,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { auth } from "../Firebase/FirebaseConfig";

// Import components
import { CustomButtonDark } from "./Custom/CustomComponents";

// Import styles
import styles from "../Styles/Components";

// Default variables
let myRole = localStorage.getItem("myRole");

/*
 * Header component that is shown on every screen. Needs to
 * be given history props for each time it is called.
 */
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: null,
      screen: "users",
      menu: false,
    };
  }

  componentDidMount() {
    myRole = localStorage.getItem("myRole");
  }

  /* Main render method of component. */
  render() {
    return (
      <>
        <AppBar position="fixed" style={styles.appbar}>
          <Toolbar style={styles.toolbar}>
            <IconButton
              edge="start"
              aria-label="menu"
              style={styles.menubutton}
              onClick={(event) => {
                this.setState({
                  menu: !this.state.menu,
                  anchor: event.currentTarget,
                });
              }}
            >
              <MenuIcon />
              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={this.state.anchor}
                open={this.state.menu}
                onClose={() => {
                  this.setState({ menu: false, anchor: null });
                }}
              >
                <MenuItem
                  onClick={() =>
                    this.props.history.push({
                      pathname: "analytics",
                      state: { myRole: myRole, action: "None" },
                    })
                  }
                >
                  Analytics
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    this.props.history.push({
                      pathname: "users",
                      state: { myRole: myRole, action: "None" },
                    })
                  }
                >
                  Users
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    this.props.history.push({
                      pathname: "promos",
                      state: { myRole: myRole },
                    })
                  }
                >
                  PrePaid-Services
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    this.props.history.push({
                      pathname: "notifications",
                      state: { myRole: myRole },
                    })
                  }
                >
                  Notifications
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    this.props.history.push({
                      pathname: "documents",
                      state: { myRole: myRole },
                    })
                  }
                >
                  Documents
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    this.props.history.push({
                      pathname: "chatrooms",
                      state: { myRole: myRole },
                    })
                  }
                >
                  All Messages
                </MenuItem>
                <MenuItem
                  onClick={() => this.props.history.push({ pathname: "/more" })}
                >
                  More
                </MenuItem>
              </Menu>
            </IconButton>

            <Typography variant="h4" style={styles.headertext}>
              STL Giant Admin Portal
            </Typography>
            <div style={styles.signoutcontainer}>
              <CustomButtonDark
                color="default"
                variant="contained"
                style={styles.signoutbutton}
                onClick={() => {
                  localStorage.setItem("myRole", "");
                  auth
                    .signOut()
                    .then((r) => console.log("THEN:", r))
                    .catch((e) => {
                      console.log("CATCH:", e);
                      // Should probably put an alert or something here.
                    });
                }}
              >
                Sign Out
              </CustomButtonDark>
            </div>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}
