import React from "react";
import {
  analyticsRef,
  empRef,
  managerRef,
  adminRef,
} from "../../Firebase/FirebaseConfig";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Link,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { CustomButton2, CustomTextField } from "../Custom/CustomComponents";
import styles from "../../Styles/Components";

/*
 * This component returns a table containing the id, email, amount of times
 * an employee has redeemed the given promotion, and an option to view each
 * individual promotion that was redeemed.
 */
export default class PromoStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emps: [],
      promoarrdata: [],
      filteredArray: [],
      loading: true,
      nopromos: false,
    };
  }

  /* Get the necessary data everytime the component is mounted. */
  componentDidMount() {
    this.getData(this.props.promo.id);
  }

  /* Return the number of times each (valid) employee has redeemed the given promo. */
  getData(promoId) {
    // Temparr should hold all analytics promos references
    // Emparr should hold all employees with the amount of the given promo they have redeemed.
    let temparr = [];
    let emparr = [];
    let userarr = [];

    analyticsRef.child("Promos").once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        if (snap.val().promodId === promoId || snap.val().promoId === promoId) {
          temparr.push(snap.val());
        }
      });

      for (let i = 0; i < temparr.length; i++) {
        if (emparr.find((e) => e.empId === temparr[i].empId)) {
          // Update the count of how many times this emp has redeemed this promo.
          const empId = temparr[i].empId;
          const index = emparr
            .map(function (e) {
              return e.empId;
            })
            .indexOf(empId);
          emparr[index].count += 1;
        } else {
          // Add the emp to the emparr
          emparr.push({
            empId: temparr[i].empId,
            empName: snapshot.val().name,
            empEmail: temparr[i].empEmail,
            promoId: promoId,
            count: 1,
          });

          empRef.once("value", (snapshot) => {
            snapshot.forEach((snap) => {
              let Obj = {
                name: snap.val().name,
                id: snap.val().id,
              };
              userarr.push(Obj);
            });

            for (let i = 0; i < userarr.length; i++) {
              if (emparr.find((e) => e.empId === userarr[i].id)) {
                const empId = userarr[i].id;
                const index = emparr
                  .map(function (e) {
                    return e.empId;
                  })
                  .indexOf(empId);

                emparr[index].empName = userarr[i].name;
              }
            }

            this.setState({
              emps: emparr,
              promoarrdata: temparr,
              loading: false,
              nopromos: false,
            });
          });

          managerRef.once("value", (snapshot) => {
            snapshot.forEach((snap) => {
              let Obj = {
                name: snap.val().name,
                id: snap.val().id,
              };
              userarr.push(Obj);
            });

            for (let i = 0; i < userarr.length; i++) {
              if (emparr.find((e) => e.empId === userarr[i].id)) {
                const empId = userarr[i].id;
                const index = emparr
                  .map(function (e) {
                    return e.empId;
                  })
                  .indexOf(empId);

                emparr[index].empName = userarr[i].name;
              }
            }

            this.setState({
              emps: emparr,
              promoarrdata: temparr,
              loading: false,
              nopromos: false,
            });
          });

          adminRef.once("value", (snapshot) => {
            snapshot.forEach((snap) => {
              let Obj = {
                name: snap.val().name,
                id: snap.val().id,
              };
              userarr.push(Obj);
            });

            for (let i = 0; i < userarr.length; i++) {
              if (emparr.find((e) => e.empId === userarr[i].id)) {
                const empId = userarr[i].id;
                const index = emparr
                  .map(function (e) {
                    return e.empId;
                  })
                  .indexOf(empId);

                emparr[index].empName = userarr[i].name;
              }
            }

            this.setState({
              emps: emparr,
              promoarrdata: temparr,
              loading: false,
              nopromos: false,
            });
          });
        }
      }

      // If there are no redemptions for this promotion yet.
      if (this.state.emps.length < 1) {
        this.setState({ loading: false, nopromos: true });
      }
    });
  }

  /* Handler for when search is updated. */
  updateSearch = (search) => {
    var updatedData = [];
    if (search === null || search === undefined || search === "") {
      updatedData = [];
    } else if (typeof search == "object") {
      updatedData.push(search);
    } else {
      // User didn't click on a card, show all cases of users with first name
      // relative to the search.
      this.state.emps.forEach((element) => {
        let lower = element.empName.toLowerCase();
        let email = element.empEmail.toLowerCase();

        if (
          lower.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase())
        ) {
          updatedData.push(element);
        }
      });
    }
    this.setState({ filteredArray: updatedData });
  };

  /* Sorting handler for filtering out data properly. */
  sortData = (sort, comparator) => {
    console.log(sort, comparator);

    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.emps;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    // Sort should be a string of the selected column
    if (sort === "Name" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.empName.toLowerCase() < b.empName.toLowerCase()) {
          return -1;
        }
        if (a.empName.toLowerCase() > b.empName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ emps: array, filter: "asc", sort: sort });
    } else if (sort === "Name" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.empName.toLowerCase() > b.empName.toLowerCase()) {
          return -1;
        }
        if (a.empName.toLowerCase() < b.empName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ emps: array, filter: "desc", sort: sort });
    } else if (sort === "Email" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.empEmail.toLowerCase() < b.empEmail.toLowerCase()) {
          return -1;
        }
        if (a.empEmail.toLowerCase() > b.empEmail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ emps: array, filter: "asc", sort: sort });
    } else if (sort === "Email" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.empEmail.toLowerCase() > b.empEmail.toLowerCase()) {
          return -1;
        }
        if (a.empEmail.toLowerCase() < b.empEmail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ emps: array, filter: "desc", sort: sort });
    } else if (sort === "Redeemed" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.count.toLowerCase() < b.count.toLowerCase()) {
          return -1;
        }
        if (a.count.toLowerCase() > b.count.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ emps: array, filter: "asc", sort: sort });
    } else if (sort === "Redeemed" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.count.toLowerCase() > b.count.toLowerCase()) {
          return -1;
        }
        if (a.count.toLowerCase() < b.count.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ emps: array, filter: "desc", sort: sort });
    }
  };

  /* Main render method of component. */
  render() {
    const { search } = this.state;
    const filter = createFilterOptions();
    return (
      <>
        {this.state.loading && <CircularProgress />}
        {this.state.nopromos && (
          <div>
            <h2 style={styles.nopromostext}>
              No one has redeemed this prepaid service yet.
            </h2>
          </div>
        )}
        {!this.state.loading && !this.state.nopromos && (
          <Paper elevation={3} style={styles.listpromomain}>
            <div style={styles.listpromosearchcontainer}>
              <Autocomplete
                value={search}
                onChange={(event, newValue) => this.updateSearch(newValue)}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={this.state.emps}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }

                  // Regular option
                  return option.empName + option.empEmail;
                }}
                renderOption={(option) =>
                  option.empName + " - " + option.empEmail
                }
                style={styles.userssearchbar}
                freeSolo
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    placeholder="Search..."
                    variant="outlined"
                  />
                )}
              />
            </div>
            <TableContainer component={Paper} style={styles.tableContainer}>
              <Table style={styles.table}>
                <TableHead style={styles.tablehead}>
                  <TableRow>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Name"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Name", "desc")
                            : this.sortData("Name", "asc")
                        }
                      >
                        Employee Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Email"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Email", "desc")
                            : this.sortData("Email", "asc")
                        }
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Redeemed"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Redeemed", "desc")
                            : this.sortData("Redeemed", "asc")
                        }
                      >
                        Promo Redeemed
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      View Promos
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.filteredArray.length === 0 &&
                    this.state.emps.map((emp, index) => (
                      <TableRow key={index}>
                        <TableCell style={styles.rowText}>
                          {emp.empName}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          <Link href={`mailto:${emp.empEmail}`} color="inherit">
                            {emp.empEmail}
                          </Link>
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {emp.count} Times
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          <CustomButton2
                            varaiant="contained"
                            color="default"
                            onClick={() =>
                              this.props.history.push({
                                pathname: "emppromos",
                                state: { DATA: this.state.promoarrdata },
                              })
                            }
                          >
                            View
                          </CustomButton2>
                        </TableCell>
                      </TableRow>
                    ))}
                  {this.state.filteredArray.map((emp, index) => (
                    <TableRow key={index}>
                      <TableCell style={styles.rowText}>
                        {emp.empName}
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        <Link href={`mailto:${emp.empEmail}`} color="inherit">
                          {emp.empEmail}
                        </Link>
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        {emp.count} Times
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        <CustomButton2
                          varaiant="contained"
                          color="default"
                          onClick={() =>
                            this.props.history.push({
                              pathname: "emppromos",
                              state: { DATA: this.state.promoarrdata },
                            })
                          }
                        >
                          View
                        </CustomButton2>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </>
    );
  }
}
