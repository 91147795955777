import React from 'react';

// Import Components
import UploadButton from '../Components/Documents/UploadButton';
import {
  CompleteModal,
  ConfirmationModal,
  UploadModal,
  ErrorModal,
  DeleteModal,
  DeleteErrorModal,
  DeleteCompleteModal,
} from '../Components/Modals/DocumentModals';
import Header from '../Components/Header';
import {
  adminRef,
  auth,
  empRef,
  managerRef,
  userRef,
} from '../Firebase/FirebaseConfig';
import {
  ButtonGroup,
  Card,
  CardMedia,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Link,
} from '@material-ui/core';

// Import styles
import styles from '../Styles/Screens';
import colors from '../Styles/Colors';
import {
  CustomButton,
  CustomButton2,
  CustomButton2Dark,
} from '../Components/Custom/CustomComponents';

/*
 * Show all documents for the current user.
 */
export default class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      nonImages: [],
      folder: 'Sales',
      myData: { canUpload: false, canShare: false },
      users: [],
      filteredArray: [],
      user: { email: null },
    };
    this.promptModal = this.promptModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
  }

  /* Check if the user is authenticated, if not send them to the login screen. */
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      user ? this.getDocs(user.uid) : this.props.history.push('/login');
    });
  }

  /* Query the current authed user's database node for their documents. */
  getDocs(userId) {
    let myref = '';
    let imgArr = [];
    let nonimgArr = [];
    let filteredImgArr = [];
    let filteredNonImgArr = [];
    let myRole = localStorage.getItem('myRole');

    if (myRole === 'Employee') {
      myref = empRef;
    } else if (myRole === 'Manager') {
      myref = managerRef;
    } else if (myRole === 'Admin') {
      myref = adminRef;
    }

    // Find user in realtimedb
    myref
      .orderByChild('id')
      .equalTo(userId)
      .once('child_added', (snapshot) => {
        if (snapshot.exists() && snapshot.val().private_files !== undefined) {
          // Get all images and throw them into their own array then set to state.
          imgArr = Object.values(snapshot.val().private_files);
          for (let i = 0; i < imgArr.length; i++) {
            if (imgArr[i].folder === this.state.folder) {
              filteredImgArr.push(imgArr[i]);
            }
          }
          this.setState({
            images: filteredImgArr,
            userId: userId,
            myData: snapshot.val(),
          });
        }

        if (
          snapshot.exists() &&
          snapshot.val().private_nonimgfiles !== undefined
        ) {
          // Get all documents and throw them into their own array then set to state.
          nonimgArr = Object.values(snapshot.val().private_nonimgfiles);
          for (let i = 0; i < nonimgArr.length; i++) {
            if (nonimgArr[i].folder === this.state.folder) {
              filteredNonImgArr.push(nonimgArr[i]);
            }
          }

          this.setState({
            nonImages: filteredNonImgArr,
            userId: userId,
            myData: snapshot.val(),
          });
        }

        // No images were found.
        if (snapshot.val().private_files === undefined) {
          this.setState({ images: [], userId: userId, myData: snapshot.val() });
        }

        // No documents were found.
        if (snapshot.val().private_nonimgfiles === undefined) {
          this.setState({
            nonImages: [],
            userId: userId,
            myData: snapshot.val(),
          });
        }
      });
  }

  /* Get all users who have had documents uploaded for them by the authed user. */
  getOtherDocs(empId) {
    // Step 1: get a list of users who have had a document uploaded by you.
    const users = [];

    userRef.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        if (!users.includes(snap.val())) {
          const user = snap.val();
          console.log(typeof user, 'type of user');
          if (snap.val().private_files) {
            const images = Object.values(snap.val().private_files);
            for (let i = 0; i < images.length; i++) {
              if (images[i].originalId === auth.currentUser.uid) {
                user.docs = true;
              }
            }
          }
          if (snap.val().private_nonimgfiles) {
            const nonimages = Object.values(snap.val().private_nonimgfiles);
            for (let i = 0; i < nonimages.length; i++) {
              if (nonimages[i].originalId === auth.currentUser.uid) {
                user.docs = true;
              }
            }
          }

          if (user.docs) {
            users.push(user);
          }

          // Step 2: add those users to state
          this.setState({ users, myId: empId });
        }
      });
    });
  }

  /* Display inputted modal to client ui. */
  promptModal = (modal) => {
    this.setState({ modal: modal });
  };

  /* Close all modals. */
  closeModals = () => {
    this.setState({ modal: false });
  };

  /* Send the selected file to the selected user. */
  sendForUser = (files) => {
    this.props.history.push({
      pathname: 'sharewithuser',
      state: { files: files },
    });
  };

  /* Go to share document screen with the selected file. */
  shareDocument = (doc, type) => {
    const docObj = { doc: doc, type: type };
    this.props.history.push({
      pathname: 'sharedocwithemp',
      state: { docObj: docObj },
    });
  };

  /* Reload the documents on screen. */
  reloadData = () => {
    this.closeModals();
    if (this.state.folder !== 'Other') {
      this.getDocs(auth.currentUser.uid);
    } else {
      this.getOtherDocs(auth.currentUser.uid);
    }
  };

  /* This is only used for select pdfs. */
  onDocumentLoadSuccess = (numPages) => {
    this.setState({ pageNumber: numPages });
  };

  /* Sort data based on inputted parameter. */
  sortData = (sort, comparator) => {
    console.log(sort, comparator);

    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.users;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    // Sort should be a string of the selected column
    if (sort === 'Name' && comparator === 'asc') {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'asc', sort: sort })
        : this.setState({ users: array, filter: 'asc', sort: sort });
    } else if (sort === 'Name' && comparator === 'desc') {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'desc', sort: sort })
        : this.setState({ users: array, filter: 'desc', sort: sort });
    } else if (sort === 'Email' && comparator === 'asc') {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'asc', sort: sort })
        : this.setState({ users: array, filter: 'asc', sort: sort });
    } else if (sort === 'Email' && comparator === 'desc') {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'desc', sort: sort })
        : this.setState({ users: array, filter: 'desc', sort: sort });
    } else if (sort === 'Role' && comparator === 'asc') {
      array = array.sort((a, b) => {
        if (a.role.toLowerCase() > b.role.toLowerCase()) {
          // no/yes
          return 1;
        }
        if (a.role.toLowerCase() === b.role.toLowerCase()) {
          // no/no yes/yes
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
        }
        if (a.role.toLowerCase() < b.role.toLowerCase()) {
          // yes/no
          return -1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'asc', sort: sort })
        : this.setState({ users: array, filter: 'asc', sort: sort });
    } else if (sort === 'Role' && comparator === 'desc') {
      array = array.sort((a, b) => {
        if (a.role.toLowerCase() < b.role.toLowerCase()) {
          // no/yes
          return 1;
        }
        if (a.role.toLowerCase() === b.role.toLowerCase()) {
          // no/no yes/yes
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
        }
        if (a.role.toLowerCase() > b.role.toLowerCase()) {
          // yes/no
          return -1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: 'desc', sort: sort })
        : this.setState({ users: array, filter: 'desc', sort: sort });
    }
  };

  /* Main render method of component. */
  render() {
    return (
      <>
        <Header history={this.props.history} />

        <div style={styles.docmaincontainer}>
          <div style={styles.docbuttoncontainer}>
            {/* Filter by document folder. */}
            <ButtonGroup
              style={styles.buttongroup}
              color='inherit'
              aria-label='outlined primary button group'
            >
              {this.state.folder === 'Sales' && (
                <CustomButton>Sales</CustomButton>
              )}
              {this.state.folder !== 'Sales' && (
                <CustomButton2Dark
                  onClick={() =>
                    this.setState(
                      { folder: 'Sales', images: [], nonImages: [] },
                      () => this.reloadData()
                    )
                  }
                >
                  Sales
                </CustomButton2Dark>
              )}
              {this.state.folder === 'Service Documents' && (
                <CustomButton>Service Docs</CustomButton>
              )}
              {this.state.folder !== 'Service Documents' && (
                <CustomButton2Dark
                  onClick={() =>
                    this.setState(
                      {
                        folder: 'Service Documents',
                        images: [],
                        nonImages: [],
                      },
                      () => this.reloadData()
                    )
                  }
                >
                  Service Docs
                </CustomButton2Dark>
              )}
              {this.state.folder === 'Insurance Card' && (
                <CustomButton>Insurance Card</CustomButton>
              )}
              {this.state.folder !== 'Insurance Card' && (
                <CustomButton2Dark
                  onClick={() =>
                    this.setState(
                      { folder: 'Insurance Card', images: [], nonImages: [] },
                      () => this.reloadData()
                    )
                  }
                >
                  Insurance Card
                </CustomButton2Dark>
              )}
              {this.state.folder === 'Personal' && (
                <CustomButton>Personal</CustomButton>
              )}
              {this.state.folder !== 'Personal' && (
                <CustomButton2Dark
                  onClick={() =>
                    this.setState(
                      { folder: 'Personal', images: [], nonImages: [] },
                      () => this.reloadData()
                    )
                  }
                >
                  Personal
                </CustomButton2Dark>
              )}
              {this.state.folder === 'Other' && (
                <CustomButton>Sent To Users</CustomButton>
              )}
              {this.state.folder !== 'Other' && (
                <CustomButton2Dark
                  onClick={() =>
                    this.setState(
                      { folder: 'Other', images: [], nonImages: [] },
                      () => this.reloadData()
                    )
                  }
                >
                  Sent To Users
                </CustomButton2Dark>
              )}
            </ButtonGroup>
          </div>

          {/* Show currently authed user's images */}
          {this.state.images.map((img, index) => (
            <div style={styles.docsecondarycontainer} key={index}>
              <Card style={styles.doccard}>
                <CardMedia
                  style={styles.doccardmedia}
                  image={img.url}
                  title={img.name}
                  onClick={() => window.open(img.url, '_blank')}
                />
                <CardActions style={styles.doccardactions}>
                  <CustomButton
                    color='default'
                    variant='contained'
                    disabled={
                      this.state.myData.canShare === true ? false : true
                    }
                    onClick={() => this.shareDocument(img, 'image')}
                  >
                    Share
                  </CustomButton>
                  <CustomButton2
                    color='secondary'
                    variant='contained'
                    onClick={() =>
                      this.setState({
                        modal: 'delete',
                        doc: img,
                        type: 'image',
                      })
                    }
                  >
                    Delete
                  </CustomButton2>
                </CardActions>
              </Card>
            </div>
          ))}

          {/* Show currently authed user's documents */}
          {this.state.nonImages.map((img, index) => (
            <div style={styles.docnonimgcontainer} key={index}>
              <Card style={styles.docnonimgcard}>
                <h3
                  style={styles.docnonimgtext}
                  onClick={() => window.open(img.url, '_blank')}
                >
                  Click To View
                </h3>
                <CardActions style={styles.docnonimgactions}>
                  <CustomButton
                    color='default'
                    variant='contained'
                    disabled={
                      this.state.myData.canShare === true ? false : true
                    }
                    onClick={() => this.shareDocument(img, 'nonimage')}
                  >
                    Share
                  </CustomButton>
                  <CustomButton2
                    color='secondary'
                    variant='contained'
                    onClick={() =>
                      this.setState({
                        modal: 'delete',
                        doc: img,
                        type: 'document',
                      })
                    }
                  >
                    Delete
                  </CustomButton2>
                </CardActions>
              </Card>
            </div>
          ))}

          {this.state.folder === 'Other' && (
            <TableContainer component={Paper} style={styles.tableContainer}>
              <Table style={styles.table}>
                <TableHead style={styles.tableHead}>
                  <TableRow>
                    <TableCell style={styles.rowText}>
                      <TableSortLabel
                        active={this.state.sort === 'Name'}
                        direction={
                          this.state.filter === 'desc' ? 'desc' : 'asc'
                        }
                        onClick={() =>
                          this.state.filter === 'asc'
                            ? this.sortData('Name', 'desc')
                            : this.sortData('Name', 'asc')
                        }
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <TableSortLabel
                        active={this.state.sort === 'Email'}
                        direction={
                          this.state.filter === 'desc' ? 'desc' : 'asc'
                        }
                        onClick={() =>
                          this.state.filter === 'asc'
                            ? this.sortData('Email', 'desc')
                            : this.sortData('Email', 'asc')
                        }
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowText}>
                      <TableSortLabel
                        active={this.state.sort === 'Role'}
                        direction={
                          this.state.filter === 'desc' ? 'desc' : 'asc'
                        }
                        onClick={() =>
                          this.state.filter === 'asc'
                            ? this.sortData('Role', 'desc')
                            : this.sortData('Role', 'asc')
                        }
                      >
                        Role
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowText}>Documents</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.filteredArray.length === 0 &&
                    this.state.users.map((user, index) => (
                      <TableRow
                        key={index}
                        style={
                          this.state.user.email === user.email
                            ? {
                                cursor: 'pointer',
                                backgroundColor: colors.highlight,
                              }
                            : { cursor: 'pointer' }
                        }
                        onClick={() =>
                          this.setState({
                            showConfirmGivePromotion: true,
                            user: user,
                          })
                        }
                      >
                        <TableCell style={styles.rowText}>
                          {user.name}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          <Link href={`mailto:${user.email}`} color='inherit'>
                            {user.email}
                          </Link>
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {user.role}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          <CustomButton2Dark
                            color='default'
                            variant='contained'
                            disabled={!user.docs}
                            onClick={() => {
                              this.props.history.push({
                                pathname: 'userdocs',
                                state: { user: user, myId: this.state.myId },
                              });
                            }}
                          >
                            View
                          </CustomButton2Dark>
                        </TableCell>
                      </TableRow>
                    ))}
                  {this.state.filteredArray.map((user, index) => (
                    <TableRow
                      key={index}
                      style={
                        this.state.user.email === user.email
                          ? {
                              cursor: 'pointer',
                              backgroundColor: colors.highlight,
                            }
                          : { cursor: 'pointer' }
                      }
                      onClick={() =>
                        this.setState({
                          showConfirmGivePromotion: true,
                          user: user,
                        })
                      }
                    >
                      <TableCell style={styles.rowText}>{user.name}</TableCell>
                      <TableCell style={styles.rowText}>
                        <Link href={`mailto:${user.email}`} color='inherit'>
                          {user.email}
                        </Link>
                      </TableCell>
                      <TableCell style={styles.rowText}>{user.role}</TableCell>
                      <TableCell style={styles.rowText}>
                        <CustomButton2Dark
                          color='default'
                          variant='contained'
                          disabled={!user.docs}
                          onClick={() => {
                            this.props.history.push({
                              pathname: 'userdocs',
                              state: { user: user, myId: this.state.myId },
                            });
                          }}
                        >
                          View
                        </CustomButton2Dark>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* End of showing docs */}

          {/* Upload button on bottom right of screen. */}
          {this.state.myData.canUpload && (
            <UploadButton
              promptModal={() => this.promptModal('upload')}
              showComplete={() => this.promptModal('complete')}
              showError={() => this.promptModal('error')}
              close={this.closeModals}
            />
          )}

          {/* Also need to pass state up with component */}
          {this.state.modal === 'upload' && (
            <UploadModal
              close={this.closeModals}
              showError={() => this.promptModal('error')}
              promptModal={() => this.promptModal('confirmgive')}
              sendForUser={this.sendForUser}
              // sendForUser={() => this.promptModal("sendforuser")}
              showComplete={() => this.promptModal('complete')}
            />
          )}

          {/* Show confirmation modal to delete a document. */}
          {this.state.modal === 'delete' && (
            <DeleteModal
              close={this.closeModals}
              type={this.state.type}
              document={this.state.doc}
              showComplete={() => this.promptModal('deletecomplete')}
              showError={() => this.promptModal('deleteerror')}
            />
          )}

          {/* Show error with deleting the document. */}
          {this.state.modal === 'deleteerror' && (
            <DeleteErrorModal close={this.closeModals} />
          )}

          {/* Show document was successfully deleted. */}
          {this.state.modal === 'deletecomplete' && (
            <DeleteCompleteModal close={this.reloadData} />
          )}

          {/* Show confirmation modal to give documents to self or user. */}
          {this.state.modal === 'confirmgive' && (
            <ConfirmationModal
              close={this.closeModals}
              showComplete={this.showComplete}
            />
          )}

          {/* Show completion modal */}
          {this.state.modal === 'complete' && (
            <CompleteModal close={this.reloadData} />
          )}

          {/* Show error modal */}
          {this.state.modal === 'error' && (
            <ErrorModal close={this.closeModals} />
          )}
        </div>
      </>
    );
  }
}
