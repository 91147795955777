import colors from "./Colors";

const styles = {
  maincontainer: {
    height: "100%",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: colors.primarybackground,
  },
  secondarycontainer: {
    paddingTop: 80,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  notificationbutton: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-end",
    justifyContent: "flex-end",
    justifySelf: "flex-end",
  },
  nocontenttext: {
    color: colors.black,
  },
  card: {
    maxWidth: 400,
    margin: 5,
    backgroundColor: colors.white,
  },
  cardcontent: {
    display: "flex",
    flexDirection: "column",
  },
  imgcontainer: {
    width: "100%",
    cursor: "pointer",
  },
  text: {
    color: colors.black,
  },
  hr: {
    width: "100%",
  },
  buttoncontainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  leftbutton: {
    width: "100%",
    marginRight: 5,
  },
  rightbutton: {
    width: "100%",
  },
};

export default styles;
