import React from "react";
import { Card, CardContent, Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  userRef,
  auth,
  analyticsRef,
  firebaseRef,
} from "../Firebase/FirebaseConfig";
import {
  RedeemModal,
  RemovePromoModal,
  ShowRemoveCompleteModal,
} from "../Components/Modals/UserModals";
import Header from "../Components/Header";

// Import styles
import styles from "../Styles/Screens";
import {
  CustomButtonDark,
  CustomButton2,
  CustomButton,
} from "../Components/Custom/CustomComponents";

/*
 * Screen shows all promos a user currently has. From here, an employee
 * can redeem them.
 */
export default class UserPromotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promos: [],
      DATA: [],
      user: this.props.location.state.user,
    };

    this.redeemPromotion = this.redeemPromotion.bind(this);
    this.closeModals = this.closeModals.bind(this);
  }

  /* Get user promotions after first render of screen. */
  componentDidMount() {
    this.getPromos(this.state.user.id);
  }

  /* Get all prepaid-services from a specific user. */
  getPromos(userId) {
    let temparr = [];
    userRef
      .orderByChild("id")
      .equalTo(userId)
      .once("child_added", (snapshot) => {
        if (snapshot.exists() && snapshot.val().promos !== undefined) {
          const mypromoref = userRef.child(snapshot.key).child("promos");
          mypromoref.on("child_added", (snap) => {
            temparr.push(snap.val());
            this.setState({ DATA: temparr });
          });
        }
      });
  }

  /* Handler for finding the prepaid-service in the user's node in realtimedb. */
  redeemPromotion = () => {
    // Find promotion in user node in realtime db, decrement count by 1.

    let temp = [];

    userRef
      .orderByChild("id")
      .equalTo(this.state.user.id)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          const myPromoRef = userRef.child(snapshot.key).child("promos");
          myPromoRef
            .orderByChild("uniqueId")
            .equalTo(this.state.selectedPromo.uniqueId)
            .once("child_added", (snap) => {
              if (snap.exists()) {
                myPromoRef
                  .child(snap.key)
                  .update({ uses: parseInt(snap.val().uses) - 1 });

                // Update our analytics information.
                analyticsRef
                  .child("Promos")
                  .push({
                    empId: auth.currentUser.uid,
                    empEmail: auth.currentUser.email,
                    promodId: snap.val().id,
                    promoName: snap.val().name,
                    promoDesc: snap.val().description,
                    promoCode: snap.val().code,
                    uniquePromoId: snap.val().uniqueId,
                    redeemedAt: firebaseRef.database.ServerValue.TIMESTAMP,
                  })
                  .then(() => {
                    const newpromoref = userRef
                      .child(snapshot.key)
                      .child("promos");
                    newpromoref.on("child_added", (s) => {
                      temp.push(s.val());
                      this.setState({ DATA: temp, showModal: false });
                    });
                    this.setState({ showsnack: true });
                  });
              }
            });
        }
      });
  };

  /* Close employee prompt pin modal. */
  closeModals = () => {
    this.setState({
      showModal: false,
      showRemoveCompleteModal: false,
      showRemovePromoModal: false,
    });
  };

  /* Load data after a delete operation. */
  closeModalReload = () => {
    this.setState(
      {
        showModal: false,
        showRemoveCompleteModal: false,
        showRemovePromoModal: false,
      },
      () => {
        this.getPromos(this.state.user.id);
      }
    );
  };

  /* Main render method of component. */
  render() {
    return (
      <>
        <Header history={this.props.history} />

        {/* Snackbar */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.showsnack}
          autoHideDuration={3000}
          onClose={() => this.setState({ showsnack: false })}
          message="Promo Redeemed"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({ showsnack: false })}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />

        <div style={styles.userpromomain}>
          <div style={{ display: "flex" }}>
            {/* Should have a back button at the top of the screen. */}
            <CustomButtonDark
              color="inherit"
              onClick={() => this.props.history.goBack()}
            >
              Back
            </CustomButtonDark>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.DATA !== undefined && this.state.DATA.length === 0 && (
              <div>
                <h2 style={styles.nopromostext}>
                  There are no prepaid services.
                </h2>
              </div>
            )}

            {/* Display promos here. */}
            {this.state.DATA.map((promo, index) => (
              <Card
                key={index}
                style={
                  promo.uses > 0 ? styles.promosremain : styles.nopromosremain
                }
              >
                <CardContent>
                  <div>
                    <img src={promo.url} alt="promo" height="200" />
                    <h2>{promo.name.toUpperCase()}</h2>
                    <hr style={styles.underlinebar} />
                    <h3>{promo.expDate}</h3>
                    <h3>Number Remaining: {promo.uses}</h3>
                    <h3>Days Remaining: {promo.numDays}</h3>
                    <h3>{promo.description}</h3>
                  </div>

                  <div style={styles.userpromosbuttoncontainer}>
                    <CustomButton
                      color="secondary"
                      variant="contained"
                      style={styles.userpromobutton}
                      onClick={() =>
                        this.setState({
                          showRemovePromoModal: true,
                          promoId: promo.uniqueId,
                        })
                      }
                    >
                      DELETE
                    </CustomButton>
                    <CustomButton2
                      color="default"
                      variant="contained"
                      disabled={promo.uses > 0 ? false : true}
                      style={styles.userpromobutton}
                      onClick={() =>
                        promo.uses > 0
                          ? this.setState({
                              showModal: true,
                              selectedPromo: promo,
                            })
                          : console.log("PROMOTION DISABLED")
                      }
                    >
                      REDEEM
                    </CustomButton2>
                  </div>
                </CardContent>
              </Card>
            ))}

            {/* Show modal for asking employee pin. */}

            {this.state.showModal && (
              <RedeemModal
                close={this.closeModals}
                confirm={this.redeemPromotion}
              />
            )}

            {this.state.showRemovePromoModal && (
              <RemovePromoModal
                user={this.state.user}
                promoId={this.state.promoId}
                close={this.closeModals}
                showComplete={() =>
                  this.setState({ showRemoveCompleteModal: true })
                }
              />
            )}

            {this.state.showRemoveCompleteModal && (
              <ShowRemoveCompleteModal close={this.closeModalReload} />
            )}
          </div>
        </div>
      </>
    );
  }
}
