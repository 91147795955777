/* eslint-disable eqeqeq */
import React from "react";
import {
  Paper,
  Fade,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  LinearProgress,
  Backdrop,
} from "@material-ui/core";
import {
  adminRef,
  firebaseRef,
  promosRef,
  managerRef,
  userRef,
  auth,
  empRef,
  CREATE_USER,
} from "../../Firebase/FirebaseConfig";
import styles from "../../Styles/Modal.js";
import {
  CustomButton,
  CustomButton2,
  CustomTextField,
} from "../Custom/CustomComponents";
import { PINAlert, PasswordAlert } from "../Alerts";

/*
 * Shown when an employee attempts to give a user a prepaid-service.
 */
export class GivePromoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false, disabled: true, PIN: "" };

    this.closeAlert = this.closeAlert.bind(this);
    this.confirmGive = this.confirmGive.bind(this);
  }

  /* Close snackbar when user chooses to. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Find employee pin in database along with authenticated user's data. */
  confirmGive(e) {
    e.preventDefault();

    // Search for user in admin node.
    adminRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snapshot) => {
        if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
          this.handleSubmit();
        } else {
          this.setState({ alert: true });
        }
      });

    // Search for user in manager node.
    managerRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snapshot) => {
        if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
          this.handleSubmit();
        } else {
          this.setState({ alert: true });
        }
      });
  }

  /* Find promotion and give it to selected user. */
  handleSubmit() {
    promosRef
      .orderByChild("id")
      .equalTo(this.props.promoId)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          // Update Count of promotions sold.
          const snapSold = snapshot.val().numSold + 1;
          promosRef.child(snapshot.key).update({ numSold: snapSold });

          // Generate unique Id for user
          const uniqueId =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);

          // Calculating the number of days from today the promo will be valid for.
          const futureDays = snapshot.val().numDays * 86400000;
          const moreDays = new Date().getTime() + futureDays;
          const futureDate = new Date(moreDays);
          const newExp =
            futureDate.getMonth() +
            1 +
            "-" +
            futureDate.getDate() +
            "-" +
            futureDate.getFullYear();

          var promotion = {};
          promotion.code = snapshot.val().code;
          promotion.givenByEmail = auth.currentUser.email;
          promotion.givenBy = auth.currentUser.uid;
          promotion.givenAt = firebaseRef.database.ServerValue.TIMESTAMP;
          promotion.description = snapshot.val().description;
          promotion.expDate = newExp;
          promotion.id = this.props.promoId;
          promotion.uniqueId = uniqueId;
          promotion.name = snapshot.val().name;
          promotion.numDays = snapshot.val().numDays;
          promotion.numSold = snapshot.val().numSold;
          promotion.url = snapshot.val().url;
          promotion.uses = snapshot.val().uses;

          // Find selected user in database and give them their promotion.
          userRef
            .orderByChild("id")
            .equalTo(this.props.user.id)
            .once("child_added", (snap) => {
              if (snap.exists()) {
                userRef.child(snap.key).child("promos").push(promotion);
                this.props.showComplete();
              }
            });
        }
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}

            <Paper elevation={3} style={styles.usermodalcontainer}>
              <form onSubmit={this.confirmGive} style={styles.usermodalform}>
                <h1 style={styles.usermodaltext}>
                  Give this promotion to {this.props.user.name}?
                </h1>
                <h3 style={styles.usermodaltext}>
                  Only Manager's and Admin's can perform this action.
                </h3>
                <h3 style={styles.usermodaltext}>
                  If you are sure, please enter your pin below.
                </h3>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.usermodalhr} />
                <div style={styles.usermodalbuttoncontainer}>
                  <CustomButton
                    color="default"
                    variant="contained"
                    style={styles.usermodalbutton1}
                    onClick={() => this.props.close()}
                  >
                    CANCEL
                  </CustomButton>
                  <CustomButton2
                    color="default"
                    variant="contained"
                    disabled={this.state.PIN.length === 6 ? false : true}
                    style={styles.usermodalbutton}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal for removing a prepaid-service.
 */
export class RemovePromoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false, disabled: true, PIN: "" };

    this.confirmRemove = this.confirmRemove.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  /* Close alert popup. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Does a pin lookup for the admin. Shows error alert if wrong pin was added. */
  confirmRemove(e) {
    e.preventDefault();

    // Find current authed user in realtimedb.
    adminRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snapshot) => {
        if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
          this.handleRemove();
        } else {
          this.setState({ alert: true });
        }
      });
  }

  /* Remove promotion from user. */
  handleRemove() {
    console.log("remove promo");
    console.log("this.props", this.props.user.id);
    userRef
      .orderByChild("id")
      .equalTo(this.props.user.id)
      .once("child_added", (snapshot) => {
        if (snapshot.exists()) {
          // Remove promotion from user. This will remove the oldest promotion with the same ID.

          const singlePromoRef = userRef.child(snapshot.key).child("promos");
          singlePromoRef
            .orderByChild("uniqueId")
            .equalTo(this.props.promoId)
            .once("child_added", (snap) => {
              if (snap.exists()) {
                singlePromoRef.child(snap.key).remove();
                this.props.showComplete();
              }
            });
        }
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}

            <Paper elevation={3} style={styles.usermodalcontainer}>
              <form onSubmit={this.confirmRemove} style={styles.usermodalform}>
                <h1 style={styles.usermodaltext}>
                  Remove this promotion from {this.props.user.name}?
                </h1>
                <h3 style={styles.usermodaltext}>
                  Only Admin's can perform this action.
                </h3>
                <h3 style={styles.usermodaltext}>
                  If you are sure, please enter your pin below.
                </h3>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.usermodalhr} />
                <div style={styles.usermodalbuttoncontainer}>
                  <CustomButton
                    color="primary"
                    variant="contained"
                    style={styles.usermodalbutton1}
                    onClick={() => this.props.close()}
                  >
                    CANCEL
                  </CustomButton>
                  <CustomButton2
                    color="secondary"
                    variant="contained"
                    disabled={this.state.PIN.length === 6 ? false : true}
                    style={styles.usermodalbutton}
                    type="submit"
                  >
                    DELETE
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a user has successfully received the given promotion.
 */
export class ShowCompletionModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.usermodalsmallcontainer}>
            <h1 style={styles.usermodaltext}>You have given this promotion.</h1>
            <div>
              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.usermodalbutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been removed.
 */
export class ShowRemoveCompleteModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.usermodalsmallcontainer}>
            <h1 style={styles.usermodaltext}>
              That promotion has been removed.
            </h1>
            <div>
              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.usermodalbutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal for redeeming a promotion for a user.
 */
export class RedeemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false, disabled: true, PIN: "" };

    this.closeAlert = this.closeAlert.bind(this);
    this.checkPIN = this.checkPIN.bind(this);
  }

  /* Close false pin snackbar. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Find the pin for an employee/manager/admin. */
  checkPIN(e) {
    e.preventDefault();

    adminRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snapshot) => {
        if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
          this.props.confirm();
        } else {
          this.setState({ alert: true });
        }
      });
    managerRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snap) => {
        if (snap.exists() && snap.val().PIN == this.state.PIN) {
          this.props.confirm();
        } else {
          this.setState({ alert: true });
        }
      });
    empRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (s) => {
        if (s.exists() && s.val().PIN == this.state.PIN) {
          this.props.confirm();
        } else {
          this.setState({ alert: true });
        }
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}

            <Paper elevation={3} style={styles.usermodalcontainer}>
              <form onSubmit={this.checkPIN} style={styles.usermodalform}>
                <h1 style={styles.usermodaltext}>Redeem this promotion?</h1>
                <h3 style={styles.usermodaltext}>
                  If you are sure, please enter your pin below.
                </h3>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.usermodalhr} />
                <div style={styles.usermodalbuttoncontainer}>
                  <CustomButton
                    color="default"
                    variant="contained"
                    style={styles.usermodalbutton1}
                    onClick={() => this.props.close()}
                  >
                    CANCEL
                  </CustomButton>
                  <CustomButton2
                    color="default"
                    variant="contained"
                    disabled={this.state.PIN.length === 6 ? false : true}
                    style={styles.usermodalbutton}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Modal for creating a new user.
 */
export class CreateUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { PIN: "", userType: "User", disabled: false };

    // Bind methods
    this.checkPasswordsMatch = this.checkPasswordsMatch.bind(this);
  }

  /* Check if both passwords match. */
  checkPasswordsMatch(e) {
    e.preventDefault();

    if (this.state.password === this.state.password2) {
      // Passwords match so create the user;
      this.buildUserObject();
    } else {
      // Show error modal that passwords do not match
      this.setState({ showPasswordError: true });
    }
  }

  /* Create the user object we'll be creating in firebase then send it to a firebase function. */
  buildUserObject() {
    let Obj = {};

    this.setState({ disabled: true });
    if (this.state.userType === "User") {
      // Create user in database and auth

      Obj = {
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/autostringer-demo.appspot.com/o/demo%2Fdefaults%2Fblank-profile-picture-973460_1280.png?alt=media&token=a73cb18c-6e32-4655-8f90-0c71c6e2d00d",
        email: this.state.email,
        password: this.state.password,
        fcmToken: "",
        name: this.state.firstName.trim() + " " + this.state.lastName.trim(),
        role: "User",
      };
    } else if (this.state.userType === "Customer") {
      const pin = Math.floor(100000 + Math.random() * 900000);

      Obj = {
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/autostringer-demo.appspot.com/o/demo%2Fdefaults%2Fblank-profile-picture-973460_1280.png?alt=media&token=a73cb18c-6e32-4655-8f90-0c71c6e2d00d",
        email: this.state.email,
        password: this.state.password,
        fcmToken: "",
        name: this.state.firstName.trim() + " " + this.state.lastName.trim(),
        role: "Customer",
        PIN: pin,
      };
    } else if (
      this.state.userType === "Employee" ||
      this.state.userType === "Manager" ||
      this.state.userType === "Admin"
    ) {
      const pin = Math.floor(100000 + Math.random() * 900000);

      Obj = {
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/autostringer-demo.appspot.com/o/demo%2Fdefaults%2Fblank-profile-picture-973460_1280.png?alt=media&token=a73cb18c-6e32-4655-8f90-0c71c6e2d00d",
        email: this.state.email,
        password: this.state.password,
        fcmToken: "",
        name: this.state.firstName.trim() + " " + this.state.lastName.trim(),
        role: this.state.userType,
        PIN: pin,
        phoneNum: this.state.phoneNum,
        officeNum: this.state.officeNum,
        department: this.state.department,
      };
    }

    this.createUser(Obj);
  }

  /* Create user in a firebase function. */
  async createUser(Obj) {
    return fetch(CREATE_USER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        user: Obj,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.message === "SUCCESS") {
          this.setState({ disabled: false });
          this.props.showComplete();
        } else {
          this.setState({ disabled: false });
          this.props.showError();
        }
        // set state to show completion.
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usercreationmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.showPasswordError && (
              <PasswordAlert
                close={() => this.setState({ showPasswordError: false })}
              />
            )}
            <Paper elevation={3} style={styles.usermodalcontainer}>
              <form
                onSubmit={this.checkPasswordsMatch}
                style={styles.usermodalform}
              >
                <h2 style={styles.usermodaltext}>Create An Account</h2>

                <hr style={styles.usermodalhr} />
                {/* Show Radio buttons to select what type of user we are creating. */}
                <h3 style={styles.usermodaltext}>
                  What type of user are you creating?
                </h3>

                <RadioGroup
                  aria-label="type"
                  name="usertypes"
                  value={this.state.userType}
                  style={styles.usermodalradiogroup}
                  onChange={(e) => this.setState({ userType: e.target.value })}
                >
                  <div style={styles.usermodalform}>
                    <FormControlLabel
                      value="User"
                      control={<Radio />}
                      label="User"
                    />
                    <FormControlLabel
                      value="Customer"
                      control={<Radio />}
                      label="Customer"
                    />
                    <FormControlLabel
                      value="Employee"
                      control={<Radio />}
                      label="Employee"
                    />
                  </div>

                  <div style={styles.usermodalform}>
                    {(this.props.myRole === "Admin" ||
                      this.props.myRole === "Manager") && (
                      <FormControlLabel
                        value="Manager"
                        control={<Radio />}
                        label="Manager"
                      />
                    )}
                    {this.props.myRole === "Admin" && (
                      <FormControlLabel
                        value="Admin"
                        control={<Radio />}
                        label="Admin"
                      />
                    )}
                  </div>
                </RadioGroup>

                {/* Basic form questions for any user */}
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={this.state.firstName}
                  placeholder="First Name"
                  required
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                />

                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={this.state.lastName}
                  placeholder="Last Name"
                  required
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                />

                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="email"
                  value={this.state.email}
                  placeholder="Email"
                  required
                  onChange={(e) => this.setState({ email: e.target.value })}
                />

                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="password"
                  value={this.state.password}
                  placeholder="Password"
                  required
                  onChange={(e) => this.setState({ password: e.target.value })}
                />

                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="password"
                  value={this.state.password2}
                  placeholder="Confirm Password"
                  required
                  onChange={(e) => this.setState({ password2: e.target.value })}
                />

                {this.state.userType !== "Employee" &&
                  this.state.userType !== "Manager" &&
                  this.state.userType !== "Admin" && (
                    <CustomTextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      inputProps={{ maxLength: 6 }}
                      value={this.state.PIN}
                      placeholder="PIN (optional)"
                      onChange={(e) => this.setState({ PIN: e.target.value })}
                    />
                  )}

                {/* If Creating an Employee */}
                {this.state.userType !== "User" &&
                  this.state.userType !== "Customer" && (
                    <>
                      <h4 style={styles.usermodaltext}>Department</h4>
                      <RadioGroup
                        aria-label="type"
                        name="usertypes"
                        value={this.state.department}
                        style={styles.usermodalradiogroup}
                        onChange={(e) =>
                          this.setState({ department: e.target.value })
                        }
                      >
                        <div style={styles.usermodalform}>
                          <FormControlLabel
                            value="Sales"
                            control={<Radio />}
                            label="Sales"
                          />
                          <FormControlLabel
                            value="Service"
                            control={<Radio />}
                            label="Service"
                          />
                          <FormControlLabel
                            value="Parts"
                            control={<Radio />}
                            label="Parts"
                          />
                        </div>
                        <div style={styles.usermodalform}>
                          <FormControlLabel
                            value="Body Shop"
                            control={<Radio />}
                            label="Body Shop"
                          />
                          <FormControlLabel
                            value="Office"
                            control={<Radio />}
                            label="Office"
                          />
                        </div>
                      </RadioGroup>

                      <CustomTextField
                        id="outlined-basic"
                        variant="outlined"
                        type="number"
                        value={this.state.officeNum}
                        placeholder="Office Number"
                        onChange={(e) =>
                          this.setState({ officeNum: e.target.value })
                        }
                      />

                      <CustomTextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        value={this.state.phoneNum}
                        placeholder="Phone Number"
                        onChange={(e) =>
                          this.setState({ phoneNum: e.target.value })
                        }
                      />
                    </>
                  )}

                <hr style={styles.usermodalhr} />
                {this.state.disabled && (
                  <LinearProgress style={styles.usermodalhr} />
                )}
                <div style={styles.usermodalbuttoncontainer}>
                  <CustomButton
                    color="default"
                    variant="contained"
                    style={styles.usermodalbutton1}
                    onClick={() => this.props.close()}
                  >
                    CANCEL
                  </CustomButton>
                  <CustomButton2
                    color="default"
                    variant="contained"
                    disabled={this.state.disabled}
                    style={styles.usermodalbutton}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal to delete a user.
 */
export class DeleteUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: false };

    this.lookupPin = this.lookupPin.bind(this);
  }

  /* Search current authed user's pin then delete selected user. */
  lookupPin(e) {
    e.preventDefault();

    const pin = this.state.PIN;

    if (this.props.myRole === "Employee") {
      empRef
        .orderByChild("id")
        .equalTo(this.props.myId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == pin) {
            // emp pin is same as one added.
            this.props.showComplete();
          }
        });
    } else if (this.props.myRole === "Manager") {
      managerRef
        .orderByChild("id")
        .equalTo(this.props.myId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == pin) {
            // manager pin is same as one added
            this.props.showComplete();
          }
        });
    } else if (this.props.myRole === "Admin") {
      adminRef
        .orderByChild("id")
        .equalTo(this.props.myId)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == pin) {
            // admin pin is same as one added
            console.log("in admin bout to show showcomplete");
            this.props.showComplete();
          }
        });
    } else {
      // Authed user should not be here
      let message =
        "You need to be an employee, manager, or admin to perform this action.";
      this.props.showError(message);
    }
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.showAlert && (
              <PINAlert close={() => this.setState({ showAlert: false })} />
            )}

            <Paper elevation={3} style={styles.confirmdocmodalcontainer}>
              <h1 style={styles.uploadmodalheader}>
                Are you sure you want to delete this user?
              </h1>
              <h3 style={styles.uploadmodalheader}>
                If so, please enter your employee pin below.
              </h3>
              <form onSubmit={this.lookupPin} style={styles.confirmtextfield}>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.progressbar} />
                {this.state.visible && (
                  <LinearProgress
                    color="secondary"
                    style={styles.confirmdocprogressbar}
                  />
                )}
                <div style={styles.confirmdocbuttoncontainer}>
                  <CustomButton
                    variant="contained"
                    color="default"
                    style={styles.confirmdocleftbutton}
                    onClick={() => this.props.close()}
                  >
                    Close
                  </CustomButton>

                  <CustomButton2
                    variant="contained"
                    color="default"
                    disabled={this.state.visible}
                    style={styles.progressbar}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a user is successfully created.
 */
export class ShowUserAddedModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.usermodalsmallcontainer}>
            <h2 style={styles.usermodaltext}>The user has been created.</h2>

            <hr style={styles.usermodalhr} />
            <CustomButton
              color="default"
              variant="contained"
              style={styles.usermodalbutton}
              onClick={() => this.props.close()}
            >
              CLOSE
            </CustomButton>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * If an error occurs during the create user process, this modal gets displayed.
 */
export class ShowErrorModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.usermodalsmallcontainer}>
            <h2 style={styles.usermodaltext}>
              Something went wrong on our end. Please try again later.
            </h2>

            <hr style={styles.usermodalhr} />
            <CustomButton
              color="default"
              variant="contained"
              style={styles.usermodalbutton}
              onClick={() => this.props.close()}
            >
              CLOSE
            </CustomButton>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * If user is successfully deleted, show this completion modal.
 */
export class ShowDeleteCompleteModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.usermodalsmallcontainer}>
            <h2 style={styles.usermodaltext}>The user has been deleted.</h2>

            <hr style={styles.usermodalhr} />
            <CustomButton
              color="default"
              variant="contained"
              style={styles.usermodalbutton}
              onClick={() => this.props.close()}
            >
              CLOSE
            </CustomButton>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * If user will receive a reset password email, this is displayed.
 */
export class ResetSuccessModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.usermodalsmallcontainer}>
            <h2 style={styles.usermodaltext}>
              {this.props.user.name} will receive a reset password email
              shortly.
            </h2>
            <hr style={styles.usermodalhr} />
            <CustomButton
              color="default"
              variant="contained"
              style={styles.usermodalbutton}
              onClick={() => this.props.close()}
            >
              CLOSE
            </CustomButton>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * If user will receive a reset password email, this is displayed.
 */
export class ResetError extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.usermodalsmallcontainer}>
            <h2 style={styles.usermodaltext}>
              Something went wrong with sending a reset password email. Please
              try again later.
            </h2>
            <hr style={styles.usermodalhr} />
            <CustomButton
              color="default"
              variant="contained"
              style={styles.usermodalbutton}
              onClick={() => this.props.close()}
            >
              CLOSE
            </CustomButton>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal for updating a users profile.
 */
export class UpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false, disabled: true, PIN: "" };

    this.closeAlert = this.closeAlert.bind(this);
    this.checkPIN = this.checkPIN.bind(this);
  }

  /* Close false pin snackbar. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Find the pin for an employee/manager/admin. */
  checkPIN(e) {
    e.preventDefault();

    adminRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snapshot) => {
        if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
          this.props.confirm();
        } else {
          this.setState({ alert: true });
        }
      });
    managerRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (snap) => {
        if (snap.exists() && snap.val().PIN == this.state.PIN) {
          this.props.confirm();
        } else {
          this.setState({ alert: true });
        }
      });
    empRef
      .orderByChild("id")
      .equalTo(auth.currentUser.uid)
      .once("child_added", (s) => {
        if (s.exists() && s.val().PIN == this.state.PIN) {
          this.props.confirm();
        } else {
          this.setState({ alert: true });
        }
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}

            <Paper elevation={3} style={styles.usermodalcontainer}>
              <form onSubmit={this.checkPIN} style={styles.usermodalform}>
                <h1 style={styles.usermodaltext}>Update this user's data?</h1>
                <h3 style={styles.usermodaltext}>
                  If you are sure, please enter your pin below.
                </h3>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.usermodalhr} />
                <div style={styles.usermodalbuttoncontainer}>
                  <CustomButton
                    color="default"
                    variant="contained"
                    style={styles.usermodalbutton1}
                    onClick={() => this.props.close()}
                  >
                    CANCEL
                  </CustomButton>
                  <CustomButton2
                    color="default"
                    variant="contained"
                    disabled={this.state.PIN.length === 6 ? false : true}
                    style={styles.usermodalbutton}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}
