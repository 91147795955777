import {
  adminRef,
  empRef,
  managerRef,
  userRef,
} from "../Firebase/FirebaseConfig";

/*
 * This might need a promise wrapped around it to properly send data.
 * Called in AllUsersWith.js
 */
export async function getAllUsers(role) {
  let userArr = [];

  const fbData = new Promise((resolve, reject) => {
    // Pull All users with appropriate role.
    if (role === "User") {
      userRef.once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          if (snap.val().role === "User") {
            userArr.push(snap.val());
          }
        });
        resolve(userArr);
      });
    } else if (role === "Customer") {
      userRef.once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          if (snap.val().role === "Customer") {
            userArr.push(snap.val());
          }
        });
        resolve(userArr);
      });
    } else if (role === "User+Customer") {
      userRef.once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          userArr.push(snap.val());
        });
        resolve(userArr);
      });
    } else if (role === "Employee") {
      empRef.once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          userArr.push(snap.val());
        });
        resolve(userArr);
      });
    } else if (role === "Manager") {
      managerRef.once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          userArr.push(snap.val());
        });
        resolve(userArr);
      });
    } else if (role === "Admin") {
      adminRef.once("value", (snapshot) => {
        snapshot.forEach((snap) => {
          userArr.push(snap.val());
        });
        resolve(userArr);
      });
    }
  });

  const timedPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject("Timed Out");
    }, 5000);
  });

  return Promise.race([fbData, timedPromise]);
}

/*
 * Search realtimedb for all user's that have a given promo.
 * Called in AllUsersWith.js
 */
export async function getUsersWithPromo(promoId) {
  let userArr = [];

  const fbData = new Promise((resolve, reject) => {
    // Search users node for all users with promo
    userRef.once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        // If user has any promos (cuts our query time)
        if (snap.val().promos !== undefined) {
          const promoRef = userRef.child(`${snap.key}/promos`);

          // Look at every promo on user node for our given promo.
          promoRef
            .orderByChild("id")
            .equalTo(promoId)
            .once("child_added", (snaps) => {
              if (!userArr.includes(snaps.val().email)) {
                // Append to array
                userArr.push(snap.val());
              }
            });
        }
      });
      resolve(userArr);
    });
  });

  const timedPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject("Timed Out");
    }, 5000);
  });

  return Promise.race([fbData, timedPromise]);
}

/*
 * Grab all users that have received a given notification.
 */
export async function getUsersWithNotification(notificationId) {
  let userArr = [];

  const fbData = new Promise((resolve, reject) =>
    // Search users node for users with notification
    userRef.once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        // If user has any notifications (cuts our query time)
        if (snap.val().notifications) {
          const notiRef = userRef.child(`${snap.key}/notifications`);

          // Look at every notification the user has.
          notiRef
            .orderByChild("code")
            .equalTo(notificationId)
            .once("child_added", (snaps) => {
              if (!userArr.includes(snaps.val().email)) {
                // Append to array
                userArr.push(snap.val());
              }
            });
        }
      });

      resolve(userArr);
    })
  );

  const timedPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject("Timed Out");
    }, 5000);
  });

  return Promise.race([fbData, timedPromise]);
}
