import React from "react";
import { Button } from "@material-ui/core";
import { auth } from "../../Firebase/FirebaseConfig";

export default class ResetEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /* Send password reset email to the selected user. */
  sendEmail(email) {
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.props.showSuccess();
      })
      .catch((error) => {
        console.log(error);
        this.props.showError(error);
      });
  }

  /* Main render method of component. */
  render() {
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ width: "100%", marginTop: 10 }}
        onClick={() => this.sendEmail(this.props.email)}
      >
        Reset Password
      </Button>
    );
  }
}
