import colors from "./Colors";

const styles = {
  main: {
    position: "sticky",
    bottom: 20,
    right: 20,
  },
  icon: {
    width: 50,
    height: 50,
    cursor: "pointer",
    bottom: 20,
    right: 20,
    color: colors.buttonprimary,
  },
};

export default styles;
