import React from "react";
import { auth } from "../Firebase/FirebaseConfig";

// Import components
import styles from "../Styles/Screens";
import Header from "../Components/Header";
import Loading from "../Components/Modals/Loading";
import ListPromos from "../Components/Analytics/ListPromos";
import AmountPromosRemaining from "../Components/Analytics/AmountPromosRemaining";

/*
 * Main analytics screen. Shows the amount of all promotions out there and
 * a list of all promotions.
 */
export default class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  /* Check if user is authenticated or not. If not send them back to the login screen. */
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push("/login");
      }
    });
  }

  /* Main render method of component. */
  render() {
    return (
      <>
        <Header history={this.props.history} />

        {/* Loading animation */}
        {this.state.loading && (
          <Loading show={() => this.setState({ loading: false })} />
        )}
        {!this.state.loading && (
          <div style={styles.analyticsmaincontainer}>
            <div style={styles.analyticssecondarycontainer}>
              <div style={styles.analyticsseccontainer}>
                <AmountPromosRemaining />
              </div>

              <ListPromos history={this.props.history} />
            </div>
          </div>
        )}
      </>
    );
  }
}
