import React from "react";
import { adminRef, empRef, managerRef } from "../../Firebase/FirebaseConfig";
import {
  Paper,
  Modal,
  Backdrop,
  Fade,
  LinearProgress,
} from "@material-ui/core";
import {
  CustomButton,
  CustomButton2,
  CustomTextField,
} from "../Custom/CustomComponents";
import styles from "../../Styles/Modal";
import { PINAlert } from "../Alerts";

export class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: false, visible: false, PIN: "" };

    this.lookupPin = this.lookupPin.bind(this);
  }

  lookupPin(e) {
    e.preventDefault();

    this.setState({ visible: true });

    const myRole = localStorage.getItem("myRole");

    let myref = empRef;
    if (myRole === "Manager") {
      myref = managerRef;
    } else if (myRole === "Admin") {
      myref = adminRef;
    }

    myref
      .orderByChild("id")
      .equalTo(this.props.myId)
      .once("child_added", (snapshot) => {
        if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
          this.props.deleteDoc();
        } else {
          this.setState({ showAlert: true, visible: false });
        }
      });
  }

  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.showAlert && (
              <PINAlert close={() => this.setState({ showAlert: false })} />
            )}

            <Paper elevation={3} style={styles.confirmdocmodalcontainer}>
              <h1 style={styles.uploadmodalheader}>
                Are you sure you want to delete this document for{" "}
                {this.props.username}?
              </h1>
              <h3 style={styles.uploadmodalheader}>
                If so, please enter your employee pin below.
              </h3>
              <form
                onSubmit={this.lookupPin}
                style={styles.confirmdocmodalform}
              >
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  style={styles.fullwidth}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.progressbar} />
                {this.state.visible && (
                  <LinearProgress
                    color="secondary"
                    style={styles.confirmdocprogressbar}
                  />
                )}
                <div style={styles.confirmdocbuttoncontainer}>
                  <CustomButton
                    variant="contained"
                    color="default"
                    style={styles.confirmdocleftbutton}
                    onClick={() => this.props.close()}
                  >
                    Close
                  </CustomButton>

                  <CustomButton2
                    variant="contained"
                    color="default"
                    disabled={this.state.PIN.length !== 6 || this.state.visible}
                    style={styles.progressbar}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * If an error occurs during the deletion process, show this modal.
 */
export class ErrorModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              There was an error deleting this document.
            </h1>
            <h1 style={styles.addpromoheader}>{this.props.message}</h1>
            <h1 style={styles.addpromoheader}>Please try again later.</h1>
            <div>
              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * If everything goes well with the deletion process, show this modal.
 */
export class CompletionModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              This document was successfully deleted.
            </h1>
            <div>
              <CustomButton
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}
