import colors from "./Colors";

const styles = {
  // Login screen stuff
  logincontainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: colors.white,
  },
  logincollapse: {
    alignItems: "center",
    justifyContent: "center",
  },
  loginalert: {
    position: "absolute",
    bottom: 20,
    left: "28%",
    right: "28%",
  },
  loginpaper: {
    display: "flex",
    height: 300,
    width: 600,
    padding: 30,
    backgroundColor: colors.white,
  },
  loginform: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  loginformcontainer: {
    display: "flex",
    flexDirection: "column",
  },
  loginheader: {
    textAlign: "center",
    color: colors.black,
  },
  logintextfield: {
    marginBottom: 30,
  },
  loginbutton: {
    marginTop: 30,
  },

  // Home Screen stuff
  maincontainer: {
    backgroundColor: colors.white,
    paddingTop: 20,
  },
  // Users Screen Stuff
  userscontainer: {
    backgroundColor: colors.white,
    paddingLeft: 30,
    paddingRight: 30,
    height: "100%",
    minHeight: "100vh",
    overflow: "scroll",
  },
  userscontentcontainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    paddingTop: 80,
  },
  userssearchbar: {
    width: "50%",
  },
  usersheadertext: {
    fontWeight: "normal",
    color: colors.black,
  },
  usersheader: {
    fontWeight: "normal",
    color: colors.black,
    marginLeft: 10,
  },
  tableContainer: {
    backgroundColor: colors.white,
  },
  table: {
    backgroundColor: colors.white,
  },
  tablehead: {
    backgroundColor: colors.white,
    color: colors.black,
  },
  rowText: {
    color: colors.black,
  },
  rowClickable: {
    cursor: "pointer",
  },
  rowheaderText: {
    color: colors.black,
    fontWeight: "bold",
  },
  buttongroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
    margintop: 10,
  },

  // User promo stuff
  userpromomain: {
    height: "100%",
    minHeight: "100vh",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 100,
    backgroundColor: colors.white,
  },
  nopromostext: {
    color: colors.black,
  },
  promosremain: {
    maxWidth: 400,
    margin: 5,
    backgroundColor: colors.white,
  },
  nopromosremain: {
    maxWidth: 400,
    margin: 5,
    backgroundColor: colors.white,
  },
  underlinebar: {
    width: "100%",
  },
  userpromosbuttoncontainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  userpromobutton: {
    width: "100%",
  },

  // Promos screen stuff
  promocontainer: {
    height: "100%",
    minHeight: "100vh",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 100,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
  },
  promocard: {
    maxWidth: 400,
    margin: 5,
    backgroundColor: colors.white,
  },
  promocardcontent: {
    cursor: "pointer",
  },
  promoname: {
    color: colors.black,
  },
  promoimagecontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 10,
  },
  bar: {
    width: "100%",
  },
  promobuttoncontainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  promoleftbutton: {
    width: "100%",
    marginRight: 5,
  },
  promorightbutton: {
    width: "100%",
  },
  // Document screens
  docmaincontainer: {
    height: "100%",
    minHeight: "100vh",
    backgroundColor: colors.white,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  docbuttoncontainer: {
    paddingTop: 100,
  },
  docsecondarycontainer: {
    display: "flex",
  },
  doccard: {
    margin: 10,
    minWidth: 300,
    backgroundColor: colors.white,
  },
  doccardmedia: {
    height: 0,
    paddingTop: "56.25%",
    cursor: "pointer",
  },
  doccardactions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  docnonimgcontainer: {
    display: "flex",
  },
  docnonimgcard: {
    margin: 10,
    minWidth: 300,
  },
  docnonimgtext: {
    textAlign: "center",
    cursor: "pointer",
  },
  docnonimgactions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  documentmodal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  documentmodalcontainer: {
    width: 400,
    padding: 20,
    backgroundColor: colors.white,
  },
  documentmodalheader: {
    textAlign: "center",
    color: colors.black,
  },
  documentmodalbutton: {
    width: "100%",
  },

  // Share doc with emp stuff
  sharedoccontainer: {
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: colors.white,
  },
  sharedocsearchcontainer: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
    marginBottom: 20,
    marginTop: 70,
    marginLeft: 20,
    marginRight: 20,
  },
  sharedocautocomplete: {
    width: "50%",
  },

  // EmpPromos stuff
  emppromomain: {
    height: "100%",
    minHeight: "100vh",
    backgroundColor: colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 30,
    paddingLeft: 30,
  },

  emppromoautocompletecontainer: {
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },

  // User screen stuff
  fillwidth: {
    width: "100%",
    marginBottom: 20,
  },
  usertextinput: {
    marginBottom: 20,
  },
  useravatar: {
    height: 300,
    width: 300,
  },
  userbuttoncontainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  userleftbutton: {
    marginRight: 10,
    width: "100%",
  },
  userrightbutton: {
    width: "100%",
  },

  // Messaging stuff
  messagesform: {
    height: "7vh",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgb(24, 23, 23)",
    width: "100%",
    // maxWidth: "728px",
    display: "flex",
    fontSize: "1.5rem",
  },
  messagesinput: {
    width: "100%",
    fontSize: "1.5rem",
    backgroundColor: "rgb(58, 58, 58)",
    color: colors.white,
    outline: "none",
    border: "none",
    padding: "0 10px",
  },
  messagescontainer: {
    height: "100%",
    minHeight: "100vh",
    marginLeft: "10vh 0 10vh",
    marginRight: "10vh 0 10vh",
    paddingBottom: "10vh",
    paddingRight: 30,
    paddingLeft: 30,
    paddingTop: "10vh",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    background: "rgb(100,100,101)",
    background:
      "linear-gradient(0deg, rgba(100,100,101,1) 1%, rgba(251,253,254,1) 100%)",
  },
  spinnertopcontainer: {
    margin: "auto",
    overflow: "auto",
    width: "50%",
    height: "50%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
  },
  spinnerseccontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  messagesstickybutton: {
    position: "sticky",
    top: 80,
    left: 10,
  },
  sentimage: {
    width: 100,
    height: 100,
    cursor: "pointer",
  },
  messagessendbutton: {
    width: "20%",
    backgroundColor: "rgb(56, 56, 143)",
  },
  uploadbuttoncontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  uploadbuttonlabel: {
    height: "100%",
  },
  uploadbutton: {
    height: "100%",
  },
  send: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    justifySelf: "flex-end",
    flexDirection: "row-reverse",
  },
  sendbody: {
    color: colors.white,
    backgroundColor: colors.sentbody,
    borderRadius: 5,
    borderWidth: 5,
    padding: 5,
    marginRight: 5,
    flexDirection: "column",
    display: "flex",
    maxWidth: 200,
  },
  received: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    justifySelf: "flex-end",
    flexDirection: "row",
  },
  receivedbody: {
    backgroundColor: colors.receivedbody,
    borderRadius: 5,
    borderWidth: 5,
    color: colors.black,
    padding: 5,
    marginLeft: 5,
    flexDirection: "column",
    display: "flex",
    maxWidth: 200,
  },
  messagebody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  messagesendtime: {
    marginTop: -18,
    marginRight: 5,
    textAlign: "right",
  },
  messagereceivedtime: {
    marginTop: -18,
    marginLeft: 5,
    textAlign: "left",
  },
  messageavatar: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    marginTop: 20,
  },

  // analytics stuff
  analyticsmaincontainer: {
    height: "100%",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: colors.white,
    display: "flex",
  },
  analyticssecondarycontainer: {
    paddingTop: "10vh",
    paddingBottom: "5vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  analyticsseccontainer: {
    marginBottom: 20,
  },

  // promo data stuff
  promodatamaincontainer: {
    height: "100%",
    minHeight: "100vh",
    backgroundColor: colors.white,
    paddingLeft: 30,
    paddingRight: 30,
  },
  promodataseccontainer: {
    paddingTop: 80,
  },
  promodatabuttoncontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: 10,
  },
};

export default styles;
