import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Link,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { getAllUsers, getUsersWithPromo } from "../Helpers/FindUsers";

import {
  GivePromoModal,
  ShowCompletionModal,
} from "../Components/Modals/UserModals";
import Header from "../Components/Header";

// Import styles
import styles from "../Styles/Components";
import {
  CustomButtonDark,
  CustomButton,
  CustomButton2,
  CustomTextField,
} from "../Components/Custom/CustomComponents";
import { InvalidPermission } from "../Components/Alerts";

import colors from "../Styles/Colors";

// Default variables
let myRole = localStorage.getItem("myRole");

/*
 * Component should show how all usres with a given prepaid service.
 */
export default class AllUsersWith extends React.Component {
  constructor(props) {
    super(props);
    this.state = { users: [], filteredArray: [], user: { email: null } };
  }

  /* Get data after initial render. */
  componentDidMount() {
    myRole = localStorage.getItem("myRole");
    this.getUsers();
  }

  /* Get all users with the inputted promotion. */
  async getUsers() {
    let temp = [];
    await getUsersWithPromo(this.props.location.state.promoId).then((r) => {
      temp.push(r);
    });

    await getAllUsers("User+Customer").then((r) => {
      temp = r;
    });

    this.setState({ users: temp });
  }

  /* If user already has the promotion, prompt emp if they want to remove all occurances of the promotion
     for the user or just the oldest/newest one.
     If user does not have the promotion, prompt emp if they want to give it to that user. */
  determineOnclick(user) {
    if (myRole === "Admin" || myRole === "Manager") {
      this.setState({ showGivePromoModal: true, user: user });
    } else {
      this.setState({ showInvalidPermission: true });
    }
  }

  /* Handler for when search is updated. */
  updateSearch = (search) => {
    var updatedData = [];
    if (search === null || search === undefined || search === "") {
      updatedData = [];
    } else if (typeof search == "object") {
      updatedData.push(search);
    } else {
      // User didn't click on a card, show all cases of users with first name
      // relative to the search.
      this.state.users.forEach((element) => {
        let lower = element.name.toLowerCase();
        let email = element.email.toLowerCase();

        if (
          lower.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase())
        ) {
          updatedData.push(element);
        }
      });
    }
    this.setState({ filteredArray: updatedData });
  };

  /* Close all modals */
  closeModals = () => {
    this.setState({
      showGivePromoModal: false,
      showCompletionModal: false,
      showRemoveCompleteModal: false,
      showRemovePromoModal: false,
      user: { email: null },
    });
  };

  /* Show the completion modal. */
  showCompleteModal = () => {
    this.setState(
      {
        showGivePromoModal: false,
        showCompletionModal: true,
        showRemovePromoModal: false,
        showRemoveCompleteModal: false,
      },
      () => this.getUsers()
    );
  };

  /* Show completion modal for when a promotion was removed from a user. */
  showRemoveCompleteModal = () => {
    this.setState(
      {
        showGivePromoModal: false,
        showCompletionModal: false,
        showRemovePromoModal: false,
        showRemoveCompleteModal: true,
      },
      () => this.getUsers()
    );
  };

  /* Sorting handler for filtering out data properly. */
  sortData = (sort, comparator) => {
    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.users;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    for (let i = 0; i < array.length; i++) {
      console.log(array[i].name);
    }

    // Sort should be a string of the selected column
    if (sort === "Name" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ users: array, filter: "asc", sort: sort });
    } else if (sort === "Name" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ users: array, filter: "desc", sort: sort });
    } else if (sort === "Email" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ users: array, filter: "asc", sort: sort });
    } else if (sort === "Email" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ users: array, filter: "desc", sort: sort });
    } else if (sort === "Role" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (
          a.role.toLowerCase() < b.role.toLowerCase() &&
          a.name.toLowerCase() < b.name.toLowerCase()
        ) {
          return -1;
        }
        if (
          a.role.toLowerCase() > b.role.toLowerCase() &&
          a.name.toLowerCase() > b.name.toLowerCase()
        ) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ users: array, filter: "asc", sort: sort });
    } else if (sort === "Role" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (
          a.role.toLowerCase() > b.role.toLowerCase() &&
          a.name.toLowerCase() > b.name.toLowerCase()
        ) {
          return -1;
        }
        if (
          a.role.toLowerCase() < b.role.toLowerCase() &&
          a.name.toLowerCase() < b.name.toLowerCase()
        ) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ users: array, filter: "desc", sort: sort });
    } else if (sort === "Promo" && comparator === "asc") {
      array = array.sort((a, b) => {
        // -1 represents we are moving b behind a.
        // 1 represents we are moving a behind b.

        a.haspromo =
          a.promos !== undefined
            ? Object.values(a.promos).find(
                (e) => e.id === this.props.location.state.promoId
              )
              ? "Yes"
              : "No"
            : "No";
        b.haspromo =
          b.promos !== undefined
            ? Object.values(b.promos).find(
                (e) => e.id === this.props.location.state.promoId
              )
              ? "Yes"
              : "No"
            : "No";

        if (a.haspromo.toLowerCase() <= b.haspromo.toLowerCase()) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          console.log(a.name, b.name, a.haspromo, b.haspromo, "-1");
          return 1;
        }
        if (a.haspromo.toLowerCase() > b.haspromo.toLowerCase()) {
          console.log(a.name, b.name, a.haspromo, b.haspromo, "1");
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ users: array, filter: "asc", sort: sort });
    } else if (sort === "Promo" && comparator === "desc") {
      array = array.sort((a, b) => {
        a.haspromo =
          a.promos !== undefined
            ? Object.values(a.promos).find(
                (e) => e.id === this.props.location.state.promoId
              )
              ? "Yes"
              : "No"
            : "No";
        b.haspromo =
          b.promos !== undefined
            ? Object.values(b.promos).find(
                (e) => e.id === this.props.location.state.promoId
              )
              ? "Yes"
              : "No"
            : "No";

        if (a.haspromo.toLowerCase() < b.haspromo.toLowerCase()) {
          // no/yes
          return 1;
        }
        if (a.haspromo.toLowerCase() === b.haspromo.toLowerCase()) {
          // no/no yes/yes
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
        }
        if (a.haspromo.toLowerCase() > b.haspromo.toLowerCase()) {
          // yes/no
          return -1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ users: array, filter: "desc", sort: sort });
    }
  };

  /* Main render method of component. */
  render() {
    const { search } = this.state;
    const filter = createFilterOptions();
    return (
      <>
        <Header history={this.props.history} />

        <div style={styles.allusersmaincontainer}>
          {/* Should have a back button at the top of the screen. */}
          <CustomButtonDark
            color="inherit"
            onClick={() => this.props.history.goBack()}
          >
            Back
          </CustomButtonDark>
          <div style={styles.alluserssecondarycontainer}>
            <h3 style={styles.allusersheader}>
              *This screen shows all users and customers who have the selected
              promotion.
            </h3>
            <Autocomplete
              value={search}
              onChange={(event, newValue) => this.updateSearch(newValue)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={this.state.users}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }

                // Regular option
                return option.name + option.email;
              }}
              renderOption={(option) => option.name + " - " + option.email}
              style={styles.alluserssearchbar}
              freeSolo
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  placeholder="Search..."
                  variant="outlined"
                />
              )}
            />
          </div>

          {/* Table Container */}
          <TableContainer component={Paper} style={styles.tablecontainer}>
            <Table style={styles.table}>
              <TableHead style={styles.tablehead}>
                <TableRow>
                  <TableCell style={styles.rowText}>
                    <TableSortLabel
                      active={this.state.sort === "Name"}
                      direction={this.state.filter === "desc" ? "desc" : "asc"}
                      onClick={() =>
                        this.state.filter === "asc"
                          ? this.sortData("Name", "desc")
                          : this.sortData("Name", "asc")
                      }
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowText}>
                    <TableSortLabel
                      active={this.state.sort === "Email"}
                      direction={this.state.filter === "desc" ? "desc" : "asc"}
                      onClick={() =>
                        this.state.filter === "asc"
                          ? this.sortData("Email", "desc")
                          : this.sortData("Email", "asc")
                      }
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowText}>
                    <TableSortLabel
                      active={this.state.sort === "Role"}
                      direction={this.state.filter === "desc" ? "desc" : "asc"}
                      onClick={() =>
                        this.state.filter === "asc"
                          ? this.sortData("Role", "desc")
                          : this.sortData("Role", "asc")
                      }
                    >
                      Role
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowText}>
                    <TableSortLabel
                      active={this.state.sort === "Promo"}
                      direction={this.state.filter === "desc" ? "desc" : "asc"}
                      onClick={() =>
                        this.state.filter === "asc"
                          ? this.sortData("Promo", "desc")
                          : this.sortData("Promo", "asc")
                      }
                    >
                      Has Promo?
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={styles.rowText}>Action</TableCell>
                  <TableCell style={styles.rowText}>Promos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredArray.length === 0 &&
                  this.state.users.map((user, index) => (
                    <TableRow
                      key={index}
                      style={
                        this.state.user.email === user.email
                          ? {
                              cursor: "pointer",
                              backgroundColor: colors.highlight,
                            }
                          : { cursor: "pointer" }
                      }
                    >
                      <TableCell style={styles.rowText}>{user.name}</TableCell>
                      <TableCell style={styles.rowText}>
                        <Link href={`mailto:${user.email}`} color="inherit">
                          {user.email}
                        </Link>
                      </TableCell>
                      <TableCell style={styles.rowText}>{user.role}</TableCell>
                      {user.promos &&
                        Object.values(user.promos).find(
                          (e) => e.id === this.props.location.state.promoId
                        ) && (
                          <TableCell style={styles.rowHasPromoText}>
                            Yes
                          </TableCell>
                        )}
                      {user.promos &&
                        !Object.values(user.promos).find(
                          (e) => e.id === this.props.location.state.promoId
                        ) && (
                          <TableCell style={styles.rowHasPromoText}>
                            No
                          </TableCell>
                        )}
                      {!user.promos && (
                        <TableCell style={styles.rowHasPromoText}>No</TableCell>
                      )}
                      <TableCell>
                        <CustomButton2
                          variant="contained"
                          color="default"
                          onClick={() => this.determineOnclick(user)}
                        >
                          Give
                        </CustomButton2>
                      </TableCell>
                      <TableCell>
                        <CustomButton
                          variant="contained"
                          color="default"
                          onClick={() =>
                            this.props.history.push({
                              pathname: "userpromotions",
                              state: {
                                user: user,
                                promos: user.promos,
                              },
                            })
                          }
                        >
                          View Promotions
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.filteredArray.map((user, index) => (
                  <TableRow
                    key={index}
                    style={
                      this.state.user.email === user.email
                        ? {
                            cursor: "pointer",
                            backgroundColor: colors.highlight,
                          }
                        : { cursor: "pointer" }
                    }
                  >
                    <TableCell style={styles.rowText}>{user.name}</TableCell>
                    <TableCell style={styles.rowText}>
                      <Link href={`mailto:${user.email}`} color="inherit">
                        {user.email}
                      </Link>
                    </TableCell>
                    <TableCell style={styles.rowText}>{user.role}</TableCell>
                    {user.promos &&
                      Object.values(user.promos).find(
                        (e) => e.id === this.props.location.state.promoId
                      ) && (
                        <TableCell style={styles.rowHasPromoText}>
                          Yes
                        </TableCell>
                      )}
                    {user.promos &&
                      !Object.values(user.promos).find(
                        (e) => e.id === this.props.location.state.promoId
                      ) && <TableCell style={styles.rowText}>No</TableCell>}
                    {!user.promos && (
                      <TableCell style={styles.rowText}>No</TableCell>
                    )}
                    <TableCell>
                      <CustomButton2
                        variant="contained"
                        color="default"
                        onClick={() => this.determineOnclick(user)}
                      >
                        Give
                      </CustomButton2>
                    </TableCell>
                    <TableCell>
                      <CustomButton
                        variant="contained"
                        color="default"
                        onClick={() =>
                          this.props.history.push({
                            pathname: "userpromotions",
                            state: { user: user, promos: user.promos },
                          })
                        }
                      >
                        View Promotions
                      </CustomButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Show Modals */}
          {this.state.showGivePromoModal && (
            <GivePromoModal
              user={this.state.user}
              promoId={this.props.location.state.promoId}
              close={this.closeModals}
              showComplete={this.showCompleteModal}
            />
          )}

          {this.state.showCompletionModal && (
            <ShowCompletionModal close={this.closeModals} />
          )}

          {this.state.showInvalidPermission && (
            <InvalidPermission
              close={() => this.setState({ showInvalidPermission: false })}
            />
          )}
        </div>
      </>
    );
  }
}
