const styles = {
  collapse: {
    alignItems: "center",
    justifyContent: "center",
  },
  alert: {
    position: "absolute",
    bottom: 20,
    left: "10%",
    right: "10%",
    alignItems: "center",
    height: 50,
  },
};

export default styles;
