import React from "react";
import { Collapse, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import styles from "../Styles/Alerts";

/*
 * Snackbar for when an employee enters the wrong pin when completing an action.
 */
export class PINAlert extends React.Component {
  render() {
    return (
      <Collapse in={true} style={styles.collapse}>
        <Alert
          variant="filled"
          severity="error"
          style={styles.alert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => this.props.close()}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <h3>
            That PIN does not belong to you. Please enter your admin or manager
            PIN.
          </h3>
        </Alert>
      </Collapse>
    );
  }
}

/*
 * Snackbar for when two passwords are not correct.
 */
export class PasswordAlert extends React.Component {
  render() {
    return (
      <Collapse in={true} style={styles.collapse}>
        <Alert
          variant="filled"
          severity="error"
          style={styles.alert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => this.props.close()}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <h3>Your passwords are not the same.</h3>
        </Alert>
      </Collapse>
    );
  }
}

/*
 * Snackbar for when an employee attempts to perform an action that is outside
 * of their permissions.
 */
export class InvalidPermission extends React.Component {
  render() {
    return (
      <Collapse in={true} style={styles.collapse}>
        <Alert
          variant="filled"
          severity="error"
          style={styles.alert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => this.props.close()}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <h3>You are not allowed to perform this operation.</h3>
        </Alert>
      </Collapse>
    );
  }
}
