import colors from "./Colors";

const styles = {
  maincontainer: {
    display: "flex",
    paddingTop: 150,
    backgroundColor: colors.white,
    paddingLeft: 30,
    paddingRight: 30,
    height: "100%",
    minHeight: "100vh",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  progresscontainer: {
    margin: "auto",
    overflow: "auto",
    width: "50%",
    height: "50%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
  },
  fullheight: {
    height: "100%",
  },
  button: {
    width: "100%",
    marginTop: 10,
  },
  formcontainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  formheader: {
    textAlign: "center",
    color: colors.darkgray,
  },
  label: {
    color: colors.darkgray,
  },
  progressseccontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  contentcontainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
  },
  useravatar: { height: 300, width: 300 },
  usertextinput: { marginBottom: 20 },
  fillwidth: { width: "100%", marginBottom: 20 },
  userbuttoncontainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  userleftbutton: {
    width: "100%",
    marignRight: 5,
  },
  userrightbutton: { width: "100%" },
};

export default styles;
