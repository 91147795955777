import React from "react";
import { Paper, Fade, Modal, Backdrop } from "@material-ui/core";
import { auth, adminRef, managerRef } from "../../Firebase/FirebaseConfig";
import styles from "../../Styles/Modal.js";
import {
  CustomTextField,
  CustomButton,
  CustomButton2,
} from "../Custom/CustomComponents";
import { PINAlert } from "../Alerts";

/*
 * Shown when sending a generic message to a user about their outstanding promotions.
 */
export class SendNotificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false, disabled: true, PIN: "" };

    this.closeAlert = this.closeAlert.bind(this);
    this.confirmGive = this.confirmGive.bind(this);
  }

  /* Close snackbar when user chooses to. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Find employee pin in database along with authenticated user's data. */
  confirmGive(e) {
    e.preventDefault();

    let myRole = localStorage.getItem("myRole");

    if (myRole === "Admin") {
      // Search for user in admin node.
      adminRef
        .orderByChild("id")
        .equalTo(auth.currentUser.uid)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
            this.props.submit();
          } else {
            this.setState({ alert: true });
          }
        });
    } else if (myRole === "Manager") {
      // Search for user in manager node.
      managerRef
        .orderByChild("id")
        .equalTo(auth.currentUser.uid)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
            this.props.submit();
          } else {
            this.setState({ alert: true });
          }
        });
    } else {
      this.props.invalidPermission();
    }
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}

            <Paper elevation={3} style={styles.usermodalcontainer}>
              <form onSubmit={this.confirmGive} style={styles.usermodalform}>
                <h1 style={styles.usermodaltext}>
                  Send a push notification to {this.props.user.name}?
                </h1>
                <h3 style={styles.usermodaltext}>
                  They will receive a push notification that says "You have
                  unused prepaid-serivces. Stop by to redeem them now!"
                </h3>
                <h3 style={styles.usermodaltext}>
                  Only Manager's and Admin's can perform this action.
                </h3>
                <h3 style={styles.usermodaltext}>
                  If you are sure, please enter your pin below.
                </h3>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.usermodalhr} />
                <div style={styles.usermodalbuttoncontainer}>
                  <CustomButton
                    color="default"
                    variant="contained"
                    style={styles.usermodalbutton1}
                    onClick={() => this.props.close()}
                  >
                    CANCEL
                  </CustomButton>
                  <CustomButton2
                    color="default"
                    variant="contained"
                    disabled={this.state.PIN.length === 6 ? false : true}
                    style={styles.usermodalbutton}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}
