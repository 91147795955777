import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { auth, promosRef } from '../Firebase/FirebaseConfig';

// import components
import {
  EditModal,
  DeleteConfirmationModal,
  DeleteModal,
  DisableModal,
  ShowDisableConfirmationModal,
  EnableModal,
  ShowEnableConfirmationModal,
} from '../Components/Modals/PromoModals';
import Header from '../Components/Header';
import AddPromos from '../Components/Modals/Addpromos';
import UploadButton from '../Components/promos/UploadButton';

// Import styles
import styles from '../Styles/Screens';
import {
  CustomButton,
  CustomButton2,
} from '../Components/Custom/CustomComponents';

// Default variables
let myRole = localStorage.getItem('myRole');

/*
 * Shows all promotions employee's can currently give out.
 */
export default class Promos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { DATA: [], showAddpromos: false };
  }

  /* Check authentication status after first render. */
  componentDidMount() {
    myRole = localStorage.getItem('myRole');
    auth.onAuthStateChanged((user) => {
      user ? this.getData() : this.props.history.push('/login');
    });
  }

  /* Get all promotions and save them to state. */
  getData() {
    const tempArr = [];
    if (this.state.DATA.length > 1) {
      this.setState({ Data: [] });
    }

    promosRef.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        tempArr.push(snap.val());
      });
      this.setState({ DATA: tempArr });
    });
  }

  /* Close all modals that are shown up on UI. */
  closeModals = () => {
    this.setState(
      {
        showAddpromos: false,
        showEditModal: false,
        showDeleteConfirmationModal: false,
        showDeleteModal: false,
        showDisableModal: false,
        showDisableConfirmationModal: false,
        showEnableModal: false,
        showEnableConfirmationModal: false,
      },
      () => this.getUpdatedData()
    );
  };

  /* Handler for showing the deletion modal. */
  showDeleteModal = () => {
    this.closeModals();
    this.setState({ showDeleteModal: true });
  };

  /* Handler for showing the disable confirmation modal. */
  showDisableConfirmationModal = () => {
    this.closeModals();
    this.setState({ showDisableConfirmationModal: true });
  };

  /* Handler for showing the enable confirmation modal. */
  showEnableConfirmationModal = () => {
    this.closeModals();
    this.setState({ showEnableConfirmationModal: true });
  };

  /* Handler for getting a new promotion once an employee has created one. */
  getUpdatedData() {
    let tempArr = [];
    promosRef.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        tempArr.push(snap.val());
      });
      this.setState({ DATA: tempArr });
    });
  }

  /* Main render method of component. */
  render() {
    const { DATA } = this.state;
    return (
      <>
        <Header history={this.props.history} />

        <div style={styles.promocontainer}>
          {/* Display if no promos. */}
          {DATA !== undefined && DATA.length === 0 && (
            <div>
              <h2 style={styles.nopromostext}>
                There are no prepaid services.
              </h2>
            </div>
          )}

          {/* Display Promos here. */}
          {DATA.map((promo, index) => (
            <Card key={index} style={styles.promocard}>
              <CardContent>
                <div
                  style={styles.promocardcontent}
                  onClick={() =>
                    this.props.history.push({
                      pathname: 'givepromo',
                      state: { promoId: promo.id },
                    })
                  }
                >
                  <div style={styles.promoimagecontainer}>
                    <img src={promo.url} alt='promo' height='200' />
                  </div>
                  <h2 style={styles.promoname}>{promo.name.toUpperCase()}</h2>
                  <hr style={styles.bar} />
                  <h3 style={styles.promoname}>{promo.expDate}</h3>
                  <h3 style={styles.promoname}>Uses: {promo.uses}</h3>
                  <h3 style={styles.promoname}>
                    Days Remaining: {promo.numDays}
                  </h3>
                  <h3 style={styles.promoname}>{promo.description}</h3>
                </div>
                <div style={styles.promobuttoncontainer}>
                  <CustomButton
                    color='default'
                    variant='contained'
                    disabled={myRole !== 'Admin' ? true : false}
                    style={styles.promoleftbutton}
                    onClick={() =>
                      promo.disabled === false || promo.disabled === undefined
                        ? this.setState({
                            showDisableModal: true,
                            Obj: { id: promo.id },
                          })
                        : this.setState({
                            showEnableModal: true,
                            Obj: { id: promo.id },
                          })
                    }
                  >
                    {(promo.disabled === false ||
                      promo.disabled === undefined) &&
                      'DISABLE'}
                    {promo.disabled === true && 'ENABLE'}
                  </CustomButton>
                  <CustomButton2
                    color='secondary'
                    variant='contained'
                    disabled={
                      myRole !== 'Admin'
                        ? true
                        : promo.disabled
                        ? promo.disabled
                        : false
                    }
                    style={styles.promoleftbutton}
                    onClick={() =>
                      this.setState({
                        showDeleteConfirmationModal: true,
                        Obj: { id: promo.id },
                      })
                    }
                  >
                    DELETE
                  </CustomButton2>
                  <CustomButton
                    color='default'
                    variant='contained'
                    disabled={
                      myRole !== 'Admin'
                        ? true
                        : promo.disabled
                        ? promo.disabled
                        : false
                    }
                    style={styles.promorightbutton}
                    onClick={() =>
                      this.setState({
                        showEditModal: true,
                        Obj: {
                          id: promo.id,
                          name: promo.name,
                          desc: promo.description,
                          code: promo.code,
                          url: promo.url,
                          imageName: promo.imageName,
                          expDate: promo.expDate,
                          uses: promo.uses,
                          numDays: promo.numDays,
                        },
                      })
                    }
                  >
                    EDIT
                  </CustomButton>
                </div>
              </CardContent>
            </Card>
          ))}

          {/* Show Edit modal to change values of promotion. */}
          {this.state.showEditModal && (
            <EditModal promo={this.state.Obj} close={this.closeModals} />
          )}

          {/* Show Confirmation when deleting promotion */}
          {this.state.showDeleteConfirmationModal && (
            <DeleteConfirmationModal
              close={this.closeModals}
              promo={this.state.Obj}
              showDeleteModal={this.showDeleteModal}
            />
          )}

          {/* Show Delete modal */}
          {this.state.showDeleteModal && (
            <DeleteModal close={this.closeModals} />
          )}

          {/* Show Disable Promotion Modals */}
          {this.state.showDisableModal && (
            <DisableModal
              close={this.closeModals}
              promo={this.state.Obj}
              showDisableConfirmationModal={this.showDisableConfirmationModal}
            />
          )}

          {this.state.showDisableConfirmationModal && (
            <ShowDisableConfirmationModal close={this.closeModals} />
          )}

          {/* Show Enable Promotion modals */}
          {this.state.showEnableModal && (
            <EnableModal
              close={this.closeModals}
              promo={this.state.Obj}
              showEnableConfirmationModal={this.showEnableConfirmationModal}
            />
          )}

          {this.state.showEnableConfirmationModal && (
            <ShowEnableConfirmationModal close={this.closeModals} />
          )}

          {/* Icon to add another promotion. */}
          <UploadButton click={() => this.setState({ showAddpromos: true })} />

          {this.state.showAddpromos && myRole === 'Admin' && (
            <AddPromos close={this.closeModals} />
          )}
        </div>
      </>
    );
  }
}
