import React from "react";

import Header from "../Components/Header";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Link,
  CircularProgress,
} from "@material-ui/core";
import {
  CustomButtonDark,
  CustomTextField,
} from "../Components/Custom/CustomComponents";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import styles from "../Styles/Screens";
import { userRef } from "../Firebase/FirebaseConfig";

/*
 * Shows all promotions that an employee has given to a user/customer.
 */
export default class EmpPromos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promos: this.props.location.state.DATA,
      filteredArray: [],
      emps: [],
      loading: true,
    };
  }

  /* Get data after first render to dom. */
  componentDidMount() {
    this.getData(this.state.promos);
  }

  /* Get all promotions from users/customers. */
  getData(DATA) {
    let temparr = [];

    userRef.once("value", (snapshot) => {
      snapshot.forEach((snap) => {
        // We have all user nodes here.
        temparr.push(snap.val());
      });

      for (let i = 0; i < temparr.length; i++) {
        if (temparr[i].promos) {
          // will execute twice.

          let promos = Object.values(temparr[i].promos);

          for (let j = 0; j < promos.length; j++) {
            // will execute once.
            if (DATA.find((e) => e.uniquePromoId === promos[j].uniqueId)) {
              for (let k = 0; k < DATA.length; k++) {
                if (DATA[k].uniquePromoId === promos[j].uniqueId) {
                  DATA[k].username = temparr[i].name;
                  DATA[k].useremail = temparr[i].email;
                } else {
                  // DATA.splice(k, 1);
                  DATA[k].username = "-";
                  DATA[k].useremail = "-";
                }
              }
            }
          }
        }
      }
      this.setState({ promos: DATA, loading: false });
    });
  }

  /* Helper method to format date to a readable string. */
  renderDate(timestamp) {
    let n = new Date(timestamp);
    let m = n.getMonth() + 1;
    let d = n.getDate();
    let y = n.getFullYear();
    return m + "/" + d + "/" + y;
  }

  /* Handler for when search is updated. */
  updateSearch = (search) => {
    var updatedData = [];
    if (search === null || search === undefined || search === "") {
      updatedData = [];
    } else if (typeof search == "object") {
      updatedData.push(search);
    } else {
      // User didn't click on a card, show all cases of users with first name
      // relative to the search.
      this.state.promos.forEach((element) => {
        let lower = element.username.toLowerCase();
        let email = element.useremail.toLowerCase();

        if (
          lower.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase())
        ) {
          updatedData.push(element);
        }
      });
    }
    this.setState({ filteredArray: updatedData });
  };

  /* Sorting handler for filtering out data properly. */
  sortData = (sort, comparator) => {
    console.log(sort, comparator);

    let array =
      this.state.filteredArray.length > 0
        ? this.state.filteredArray
        : this.state.promos;
    let filtered = this.state.filteredArray.length > 0 ? true : false;

    // Sort should be a string of the selected column
    if (sort === "Name" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.username.toLowerCase() < b.username.toLowerCase()) {
          return -1;
        }
        if (a.username.toLowerCase() > b.username.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "Name" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.username.toLowerCase() > b.username.toLowerCase()) {
          return -1;
        }
        if (a.username.toLowerCase() < b.username.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    } else if (sort === "Email" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.useremail.toLowerCase() > b.useremail.toLowerCase()) {
          return -1;
        }
        if (a.useremail.toLowerCase() < b.useremail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "Email" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.useremail.toLowerCase() < b.useremail.toLowerCase()) {
          return -1;
        }
        if (a.useremail.toLowerCase() > b.useremail.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    } else if (sort === "PName" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.promoName.toLowerCase() > b.promoName.toLowerCase()) {
          return -1;
        }
        if (a.promoName.toLowerCase() < b.promoName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "PName" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.promoName.toLowerCase() < b.promoName.toLowerCase()) {
          return -1;
        }
        if (a.promoName.toLowerCase() > b.promoName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    } else if (sort === "Description" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.promoDesc.toLowerCase() > b.promoDesc.toLowerCase()) {
          return -1;
        }
        if (a.promoDesc.toLowerCase() < b.promoDesc.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "Description" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.promoDesc.toLowerCase() < b.promoDesc.toLowerCase()) {
          return -1;
        }
        if (a.promoDesc.toLowerCase() > b.promoDesc.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    } else if (sort === "RedeemedAt" && comparator === "asc") {
      array = array.sort((a, b) => {
        if (a.redeemedAt > b.redeemedAt) {
          return -1;
        }
        if (a.redeemedAt < b.redeemedAt) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "asc", sort: sort })
        : this.setState({ promos: array, filter: "asc", sort: sort });
    } else if (sort === "RedeemedAt" && comparator === "desc") {
      array = array.sort((a, b) => {
        if (a.redeemedAt < b.redeemedAt) {
          return -1;
        }
        if (a.redeemedAt > b.redeemedAt) {
          return 1;
        }
        return 0;
      });

      filtered
        ? this.setState({ filteredArray: array, filter: "desc", sort: sort })
        : this.setState({ promos: array, filter: "desc", sort: sort });
    }
  };

  /* Main render method of component. */
  render() {
    const { search } = this.state;
    const filter = createFilterOptions();
    return (
      <>
        <Header history={this.props.history} />
        {/* Main view of component. */}
        {this.state.loading && (
          // center progress here
          <CircularProgress />
        )}

        {!this.state.loading && (
          <Paper elevation={3} style={styles.emppromomain}>
            {/* Back Button */}
            <div style={styles.emppromoautocompletecontainer}>
              <CustomButtonDark
                color="inherit"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </CustomButtonDark>

              {/* AutoComplete component to effeciently search for data. */}
              <Autocomplete
                value={search}
                onChange={(event, newValue) => this.updateSearch(newValue)}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={this.state.promos}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }

                  // Regular option
                  return option.username + option.useremail;
                }}
                renderOption={(option) =>
                  option.username + " - " + option.useremail
                }
                style={styles.userssearchbar}
                freeSolo
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    placeholder="Search..."
                    variant="outlined"
                  />
                )}
              />
            </div>
            <TableContainer component={Paper} style={styles.tableContainer}>
              <Table style={styles.table}>
                <TableHead style={styles.tablehead}>
                  <TableRow>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Name"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Name", "desc")
                            : this.sortData("Name", "asc")
                        }
                      >
                        OwnedBy
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Email"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Email", "desc")
                            : this.sortData("Email", "asc")
                        }
                      >
                        Owner's Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "PName"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("PName", "desc")
                            : this.sortData("PName", "asc")
                        }
                      >
                        Promo Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "Description"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("Description", "desc")
                            : this.sortData("Description", "asc")
                        }
                      >
                        Description
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={styles.rowheaderText}>
                      <TableSortLabel
                        active={this.state.sort === "RedeemedAt"}
                        direction={
                          this.state.filter === "desc" ? "desc" : "asc"
                        }
                        onClick={() =>
                          this.state.filter === "asc"
                            ? this.sortData("RedeemedAt", "desc")
                            : this.sortData("RedeemedAt", "asc")
                        }
                      >
                        Redeemed At
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.filteredArray.length === 0 &&
                    this.state.promos.map((promo, index) => (
                      <TableRow key={index}>
                        <TableCell style={styles.rowText}>
                          {promo.username}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          <Link
                            href={`mailto:${promo.useremail}`}
                            color="inherit"
                          >
                            {promo.useremail}
                          </Link>
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {promo.promoName}
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {promo.promoDesc} Times
                        </TableCell>
                        <TableCell style={styles.rowText}>
                          {this.renderDate(promo.redeemedAt)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {this.state.filteredArray.map((promo, index) => (
                    <TableRow key={index}>
                      <TableCell style={styles.rowText}>
                        {promo.username}
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        <Link
                          href={`mailto:${promo.useremail}`}
                          color="inherit"
                        >
                          {promo.useremail}
                        </Link>
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        {promo.promoName}
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        {promo.promoDesc}
                      </TableCell>
                      <TableCell style={styles.rowText}>
                        {this.renderDate(promo.redeemedAt)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </>
    );
  }
}
