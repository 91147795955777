import React from "react";
import {
  Paper,
  Fade,
  Modal,
  Backdrop,
  LinearProgress,
} from "@material-ui/core";
import {
  auth,
  adminRef,
  managerRef,
  PUSH_ALL_CUSTOMERS,
} from "../../Firebase/FirebaseConfig";
import { app as dealership } from "../../Firebase/FirebaseConfig";
import styles from "../../Styles/Modal.js";
import {
  CustomTextField,
  CustomButton,
  CustomButton2,
} from "../Custom/CustomComponents";
import { PINAlert } from "../Alerts";

/*
 * Shown when an employee attempts to give a user a prepaid-service.
 */
export class SendNotificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false, disabled: true, PIN: "" };

    this.closeAlert = this.closeAlert.bind(this);
    this.confirmGive = this.confirmGive.bind(this);
  }

  /* Close snackbar when user chooses to. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Find employee pin in database along with authenticated user's data. */
  confirmGive(e) {
    e.preventDefault();

    let myRole = localStorage.getItem("myRole");

    if (myRole === "Admin") {
      // Search for user in admin node.
      adminRef
        .orderByChild("id")
        .equalTo(auth.currentUser.uid)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
            this.props.submit();
          } else {
            this.setState({ alert: true });
          }
        });
    } else if (myRole === "Manager") {
      // Search for user in manager node.
      managerRef
        .orderByChild("id")
        .equalTo(auth.currentUser.uid)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
            this.props.submit();
          } else {
            this.setState({ alert: true });
          }
        });
    } else {
      this.props.invalidPermission();
    }
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && <PINAlert close={this.closeAlert} />}

            <Paper elevation={3} style={styles.usermodalcontainer}>
              <form onSubmit={this.confirmGive} style={styles.usermodalform}>
                <h1 style={styles.usermodaltext}>
                  Send this notification to {this.props.user.name}?
                </h1>
                <h3 style={styles.usermodaltext}>
                  Only Manager's and Admin's can perform this action.
                </h3>
                <h3 style={styles.usermodaltext}>
                  If you are sure, please enter your pin below.
                </h3>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.usermodalhr} />
                <div style={styles.usermodalbuttoncontainer}>
                  <CustomButton
                    color="default"
                    variant="contained"
                    style={styles.usermodalbutton1}
                    onClick={() => this.props.close()}
                  >
                    CANCEL
                  </CustomButton>
                  <CustomButton2
                    color="default"
                    variant="contained"
                    disabled={this.state.PIN.length === 6 ? false : true}
                    style={styles.usermodalbutton}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class SendAllModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { PIN: "", disabled: false };

    this.checkPin = this.checkPin.bind(this);
  }

  checkPin(e) {
    e.preventDefault();

    this.setState({ disabled: true }, () => this.comparePin());
  }

  comparePin() {
    let myRole = localStorage.getItem("myRole");

    if (myRole === "Admin") {
      adminRef
        .orderByChild("id")
        .equalTo(auth.currentUser.uid)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && this.state.PIN == snapshot.val().PIN) {
            this.sendNotificationToAll();
          } else {
            this.setState({ alert: true });
          }
        });
    } else if (myRole === "Manager") {
      managerRef
        .orderByChild("id")
        .equalTo(auth.currentUser.uid)
        .once("child_added", (snapshot) => {
          if (snapshot.exists() && this.state.PIN == snapshot.val().PIN) {
            this.sendNotificationToAll();
          } else {
            this.setState({ alert: true });
          }
        });
    } else {
      this.props.invalidPermission();
    }
  }

  async sendNotificationToAll() {
    // Saving these variables to local space so they don't get lost in the payload randomly.
    let notification = this.props.notification;
    let group = this.props.group; // Should be users or customers
    let dealer = dealership;

    console.log("dealer", dealer);

    return fetch(PUSH_ALL_CUSTOMERS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        role: group,
        dealer: dealer,
        content: notification,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.error) {
          this.props.showError(result.error);
        } else {
          console.log("RESULT:", result);
          this.props.showComplete();
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.showError(error);
      });
  }

  render() {
    const message = this.props.group === "Users" ? "users" : "customers";
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.usermodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.alert && (
              <PINAlert close={() => this.setState({ alert: false })} />
            )}

            <Paper elevation={3} style={styles.usermodalcontainer}>
              <form onSubmit={this.checkPin} style={styles.usermodalform}>
                <h1 style={styles.usermodaltext}>
                  Send this notification to all {message}?
                </h1>
                <h3 style={styles.usermodaltext}>
                  Only Manager's and Admin's can perform this action.
                </h3>
                <h3 style={styles.usermodaltext}>
                  If you are sure, please enter your pin below.
                </h3>
                <CustomTextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder="000000"
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.usermodalhr} />
                {this.state.disabled && (
                  <LinearProgress color="primary" style={styles.userlinear} />
                )}
                <div style={styles.usermodalbuttoncontainer}>
                  <CustomButton
                    color="default"
                    variant="contained"
                    style={styles.usermodalbutton1}
                    onClick={() => this.props.close()}
                  >
                    CANCEL
                  </CustomButton>
                  <CustomButton2
                    color="default"
                    variant="contained"
                    disabled={
                      this.state.disabled
                        ? true
                        : this.state.PIN.length === 6
                        ? false
                        : true
                    }
                    style={styles.usermodalbutton}
                    type="submit"
                  >
                    SUBMIT
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a notification has been given.
 */
export class CompletionModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              {this.props.user.name} will receive the notification shortly.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class ErrorModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              There was an issue sending this notification, please try again
              later.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a prepaid-service has been enabled.
 */
export class CompleteAllModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.deletepromocontainer}>
            <h1 style={styles.addpromoheader}>
              All {this.props.group.toLowerCase()} will receive the promotion
              shortly.
            </h1>
            <div>
              <CustomButton2
                variant="contained"
                color="secondary"
                style={styles.editpromobutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}
