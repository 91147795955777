import React from "react";
import {
  Paper,
  Backdrop,
  Modal,
  Fade,
  LinearProgress,
} from "@material-ui/core";
import { promosRef, storageRef } from "../../Firebase/FirebaseConfig";
import styles from "../../Styles/Modal";
import { CustomButton, CustomTextField } from "../Custom/CustomComponents";

/*
 * This is a modal that displays when an employee is creating a new promotion.
 */
export default class AddPromos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /* Upload handler for images. */
  async uploadToStorage(path) {
    const image = path[0];

    // Generate a unique name for the file.
    const uniqueId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    const ref = storageRef.child(`/promos/${uniqueId}.jpg`);

    // Take filesystem path and upload a byte stream to firebase storage.
    await ref
      .put(image)
      .then(() => {
        // Get the downloadURL since it will be public to anyone who has the promotion.
        ref
          .getDownloadURL()
          .then((url) => {
            // Create date info for when this badboy expires.
            const today = new Date();
            const futureDate = this.state.date.split("-").join("/");
            const selectedDate = new Date(futureDate);
            const diffInTime = selectedDate.getTime() - today.getTime();
            const diffInDays = diffInTime / (1000 * 3600 * 24);

            // Everything looks good, push to database.
            const newPromo = promosRef.push({
              name: this.state.name,
              description: this.state.desc,
              numDays: Math.ceil(diffInDays),
              expDate: this.state.date,
              numSold: 0,
              code: this.state.code,
              imageName: uniqueId,
              url: url,
              uses: this.state.uses,
            });

            // Update the promotion key to the actual snapshot.key.
            promosRef.child(newPromo.key).update({ id: newPromo.key });

            // Show completion of task
            this.props.close();
          })
          .catch((e) => {
            console.log(e);
            this.setState({ disabled: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ disabled: false });
      });
  }

  /* Submission handler, stop the page from refreshing and upload the image to storage. */
  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ disabled: true });

    await this.uploadToStorage(this.state.path);
  }

  /* Main render method of component. */
  render() {
    // Minimum date for something to expire is the current date.
    const today = new Date();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let y = today.getFullYear();
    d = d < 10 ? "0" + d : d;
    const mindate = y + "-" + m + "-" + d;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.addpromomodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={5} style={styles.addpromocontainer}>
            <form onSubmit={this.handleSubmit} style={styles.addpromoform}>
              <h2 style={styles.addpromoheader}>Add Prepaid Service</h2>
              <CustomTextField
                id="outlined-basic"
                style={styles.addpromotextfield}
                variant="outlined"
                required
                type="text"
                value={this.state.name}
                placeholder="Name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
              <CustomTextField
                id="image"
                style={styles.addpromotextfield}
                variant="outlined"
                required
                type="file"
                value={this.state.image}
                onChange={(e) => this.setState({ path: e.target.files })}
              />
              <CustomTextField
                id="outlined-basic"
                style={styles.addpromotextfield}
                variant="outlined"
                required
                type="text"
                value={this.state.code}
                placeholder="Promo Code"
                onChange={(e) => this.setState({ code: e.target.value })}
              />
              <CustomTextField
                id="outlined-basic"
                style={styles.addpromotextfield}
                variant="outlined"
                required
                rows={4}
                multiline
                type="text"
                value={this.state.desc}
                placeholder="Description..."
                onChange={(e) => this.setState({ desc: e.target.value })}
              />
              <label style={styles.addpromolabel}>Expiration Date</label>
              <CustomTextField
                id="date"
                style={styles.addpromocalendar}
                variant="outlined"
                required
                InputProps={{ inputProps: { min: mindate } }}
                type="date"
                value={this.state.date}
                onChange={(e) => this.setState({ date: e.target.value })}
              />
              <CustomTextField
                id="outlined-basic"
                style={styles.addpromotextfield}
                variant="outlined"
                type="number"
                required
                InputProps={{ inputProps: { min: 1 } }}
                value={this.state.uses}
                placeholder="5"
                onChange={(e) => this.setState({ uses: e.target.value })}
              />
              {this.state.disabled && (
                <LinearProgress color="primary" style={styles.progressbar} />
              )}
              <CustomButton
                variant="contained"
                color="default"
                type="submit"
                disabled={this.state.disabled}
              >
                SUBMIT
              </CustomButton>
            </form>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}
