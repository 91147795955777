/* eslint-disable eqeqeq */
import React from 'react';
import {
  Modal,
  Fade,
  Paper,
  Backdrop,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import {
  adminRef,
  auth,
  empRef,
  managerRef,
  storageRef,
  userRef,
} from '../../Firebase/FirebaseConfig';

import { PINAlert } from '../Alerts';

import styles from '../../Styles/Modal';
import {
  CustomButton,
  CustomButton2,
  CustomTextField,
} from '../Custom/CustomComponents';

/*
 * Modal for uploading a document to storage and saving relevant data in
 * realtimedb.
 */
export class UploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      docArr: [],
      folder: 'Sales',
      disabled: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  /*
   * Main submission handler for uploading the document for either an
   * employee or a user.
   */
  async handleSubmit(e) {
    e.preventDefault();

    const files = this.state.images;

    // Show progress until image is properly uploaded to to storage bucket.
    if (this.state.checked) {
      // We need to pass state up of the file path here.
      const folder = this.state.folder;

      // Save to localstorage for easier access later.
      localStorage.setItem('Files', JSON.stringify(files));
      localStorage.setItem('FolderType', folder);

      this.props.sendForUser(files);
    } else {
      // Wait for handler to upload all files to storage bucket.
      await this.handleImageUpload(files);
    }
  }

  /* Upload image to firebase storage */
  async handleImageUpload(files) {
    this.setState({ disabled: true });

    let docArr = [];
    let promiseArr = [];

    // Multiple file upload?
    for (let i = 0; i < files.length; i++) {
      let p = new Promise((resolve, reject) => {
        const type = files[i].type;
        const image = files[i];
        const uniqueId =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);

        // Get actual file type?
        const ref = storageRef.child(
          `/${auth.currentUser.uid}/Uploads/${uniqueId}.jpg`
        );

        // Create bytestrem to firebase storage.
        ref
          .put(image)
          .then((res) => {
            // Get downloadurl to save in realtimedb.
            ref
              .getDownloadURL()
              .then((url) => {
                const Obj = { imageName: uniqueId, url: url, type: type };
                docArr.push(Obj);

                this.setState({ docArr: docArr }, () => resolve());
              })
              .catch((e) => {
                console.log(e);
                this.setState({ disable: false }, () => reject());
              });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ disable: false }, () => reject());
          });
      });
      promiseArr.push(p);
    }

    // Once all images have been uploaded, push a document object to realtimedb so
    // we can easilly access it.
    Promise.all(promiseArr)
      .then(() => {
        this.uploadToDatabase();
      })
      .catch((e) => {
        console.log('PROMISE ERROR:', e);
      });
  }

  /* Take uploaded files and upload them to realtimedb. */
  uploadToDatabase() {
    const docs = this.state.docArr;
    const myRole = localStorage.getItem('myRole');

    let myref = empRef;
    if (myRole === 'Manager') {
      myref = managerRef;
    } else if (myRole === 'Admin') {
      myref = adminRef;
    }

    // Loop for multiple files.
    for (let i = 0; i < docs.length; i++) {
      // Find current authed user in realtimedb.
      myref
        .orderByChild('id')
        .equalTo(auth.currentUser.uid)
        .once('child_added', (snapshot) => {
          if (snapshot.exists() && docs[i].type.split('/')[0] !== 'image') {
            // Upload files under my non_images node.
            myref
              .child(snapshot.key)
              .child('private_nonimgfiles')
              .push({
                url: docs[i].url,
                name: docs[i].imageName,
                folder: this.state.folder,
                originalId: auth.currentUser.uid,
              })
              .then(() => this.props.showComplete())
              .catch((error) => {
                console.log('Firebase Error:', error);
                this.props.showError();
              });
          } else if (snapshot.exists()) {
            // Upload files under my images node.
            myref
              .child(snapshot.key)
              .child('private_files')
              .push({
                url: docs[i].url,
                name: docs[i].imageName,
                folder: this.state.folder,
                originalId: auth.currentUser.uid,
              })
              .then(() => this.props.showComplete())
              .catch((error) => {
                console.log('Firebase Error:', error);
                this.props.showError();
              });
          }
        });
    }
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.uploadmodalcontainer}>
            <h1 style={styles.uploadmodalheader}>Upload A New Document.</h1>
            {this.state.disabled && (
              <LinearProgress color='secondary' style={styles.progressbar} />
            )}

            <form onSubmit={this.handleSubmit} style={styles.uploadmodalform}>
              <CustomTextField
                variant='outlined'
                type='file'
                inputProps={{ multiple: true }}
                value={this.state.image}
                onChange={(e) => this.setState({ images: e.target.files })}
              />

              <RadioGroup
                aria-label='Folder'
                name='Folder'
                value={this.state.folder}
                onChange={(folder) =>
                  this.setState({ folder: folder.target.value })
                }
              >
                <FormControlLabel
                  value='Sales'
                  style={styles.uploadmodaltext}
                  control={<Radio />}
                  label='Sales'
                />
                <FormControlLabel
                  value='Service Documents'
                  style={styles.uploadmodaltext}
                  control={<Radio />}
                  label='Service Documents'
                />
                <FormControlLabel
                  value='Insurance Card'
                  style={styles.uploadmodaltext}
                  control={<Radio />}
                  label='Insurance Card'
                />
                <FormControlLabel
                  value='Personal'
                  style={styles.uploadmodaltext}
                  control={<Radio />}
                  label='Personal'
                />
              </RadioGroup>

              <div>
                <FormControlLabel
                  style={styles.uploadmodaltext}
                  control={
                    <Checkbox
                      checked={this.state.checked}
                      onChange={(e) =>
                        this.setState({ checked: e.target.checked })
                      }
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      size='medium'
                    />
                  }
                  label='Is this for another user?'
                />
              </div>
              <div style={styles.uploadmodalbuttoncontainer}>
                <CustomButton
                  variant='contained'
                  color='default'
                  style={styles.uploadmodalbutton1}
                  onClick={() => this.props.close()}
                >
                  Close
                </CustomButton>
                <CustomButton2
                  variant='contained'
                  color='default'
                  disabled={this.state.disabled}
                  style={styles.uploadmodalbutton1}
                  type='submit'
                >
                  SUBMIT
                </CustomButton2>
              </div>
            </form>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal for GIVING a document to another user.
 */
export class ShowConfirmGiveDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, PIN: '' };

    // Bind methods to local namespace.
    this.closeAlert = this.closeAlert.bind(this);
    this.checkPin = this.checkPin.bind(this);
  }

  /* Check if entered pin is the correct pin or not. */
  checkPin(e) {
    e.preventDefault();
    this.setState({ visible: true });

    try {
      // Try to find database node pertaining to currently authed user.
      let myref = '';
      if (localStorage.getItem('myRole') === 'Employee') {
        myref = empRef;
      } else if (localStorage.getItem('myRole') === 'Manager') {
        myref = managerRef;
      } else if (localStorage.getItem('myRole') === 'Admin') {
        myref = adminRef;
      }

      // Find employee pin in database
      myref
        .orderByChild('id')
        .equalTo(auth.currentUser.uid)
        .once('child_added', (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
            // Employee's pin was correct and we can move to the upload process. (We use == instead of === since firebase will sometimes save pins as numerical values.)
            this.uploadDocument(this.props.user);
          } else {
            // Wrong pin entered.
            this.setState({ showAlert: true, visible: false });
          }
        });
    } catch (e) {
      // The role is not saved in localstorage and we need to user to reauthenticate in
      // order for them to do employee tasks.
      console.log(e);
      this.props.showError('Not Employee');
    }
  }

  /* Look up user and upload document for them in storage and in realtime db. */
  uploadDocument(user) {
    let docArr = [];
    let promiseArr = [];

    let docs = this.props.files;

    // We might be uploading multiple documents so we need to throw a promise in a for loop.
    for (let i = 0; i < docs.length; i++) {
      let p = new Promise((resolve, reject) => {
        const type = docs[i].type;
        const image = docs[i];

        // Create id for document.
        const uniqueId =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);

        // Create reference to storage bucket
        const ref = storageRef.child(`/${user.id}/Uploads/${uniqueId}.jpg`);

        // Upload documents to storage bucket
        ref
          .put(image)
          .then((res) => {
            // Get download url
            ref
              .getDownloadURL()
              .then((url) => {
                const Obj = { imageName: uniqueId, url: url, type: type };
                docArr.push(Obj);
                this.setState({ docArr: docArr }, () => resolve());
              })
              .catch((e) => {
                console.log(e);
                this.setState({ visible: false }, () => reject());
              });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ visible: false }, () => reject());
          });
      });
      promiseArr.push(p);
    }

    // Once all documents are uploaded, upload to user's realtimedb node.
    Promise.all(promiseArr)
      .then(() => {
        this.uploadToDatabase(this.props.user);
      })
      .catch((e) => {
        console.log('PROMISE ALL ERROR', e);
        this.props.showError('Promise All');
      });
  }

  /* Upload documents to user's database node. */
  uploadToDatabase(user) {
    for (let i = 0; i < this.state.docArr.length; i++) {
      const docs = this.state.docArr;

      const folder = localStorage.getItem('FolderType');

      // Find user's database node.
      userRef
        .orderByChild('id')
        .equalTo(user.id)
        .once('child_added', (snapshot) => {
          if (snapshot.exists() && docs[i].type.split('/')[0] !== 'image') {
            // Upload non_images to user's non_images node.
            userRef
              .child(snapshot.key)
              .child('private_nonimgfiles')
              .push({
                url: docs[i].url,
                name: docs[i].imageName,
                folder: folder,
                originalId: auth.currentUser.uid,
              })
              .then(() => this.props.showComplete())
              .catch((error) => {
                console.log('Upload file error:', error);
                this.props.showError(error);
              });
          } else if (snapshot.exists()) {
            // Upload images to user's image node.
            userRef
              .child(snapshot.key)
              .child('private_files')
              .push({
                url: docs[i].url,
                name: docs[i].imageName,
                folder: folder,
                originalId: auth.currentUser.uid,
              })
              .then(() => this.props.showComplete())
              .catch((e) => {
                console.log('Upload image error:', e);
                this.props.showError(e);
              });
          }
        });
    }
  }

  /* Close snackbar if user chooses to do so. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.showAlert && (
              <PINAlert close={() => this.setState({ showAlert: false })} />
            )}
            <Paper elevation={3} style={styles.confirmdocmodalcontainer}>
              <h1 style={styles.uploadmodalheader}>
                Are you sure you want to upload this document for{' '}
                {this.props.user.name}?
              </h1>
              <h3 style={styles.uploadmodalheader}>
                If so, please enter your employee pin below.
              </h3>
              <form onSubmit={this.checkPin} style={styles.confirmdocmodalform}>
                <CustomTextField
                  id='outlined-basic'
                  variant='outlined'
                  type='text'
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder='000000'
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.progressbar} />
                {this.state.visible && (
                  <LinearProgress
                    color='secondary'
                    style={styles.confirmdocprogressbar}
                  />
                )}
                <div style={styles.confirmdocbuttoncontainer}>
                  <CustomButton
                    variant='contained'
                    color='default'
                    style={styles.confirmdocleftbutton}
                    onClick={() => this.props.close()}
                  >
                    Close
                  </CustomButton>

                  <CustomButton2
                    variant='contained'
                    color='default'
                    disabled={this.state.visible}
                    style={styles.progressbar}
                    type='submit'
                  >
                    UPLOAD
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal for sharing a document an employee already owns
 * with another user.
 */
export class ShowConfirmShareDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.checkPin = this.checkPin.bind(this);
    this.uploadToDatabase = this.uploadToDatabase.bind(this);
  }

  /* Check if entered pin is the correct pin or not. */
  checkPin(e) {
    e.preventDefault();
    this.setState({ visible: true });

    try {
      // Try to find database node pertaining to currently authed user.
      let myref = '';
      const myRole = localStorage.getItem('myRole');
      if (myRole === 'Employee') {
        myref = empRef;
      } else if (myRole === 'Manager') {
        myref = managerRef;
      } else if (myRole === 'Admin') {
        myref = adminRef;
      }

      // Find employee pin in database
      myref
        .orderByChild('id')
        .equalTo(auth.currentUser.uid)
        .once('child_added', (snapshot) => {
          if (snapshot.exists() && snapshot.val().PIN == this.state.PIN) {
            // Employee's pin was correct and we can move to the upload process.
            this.uploadToDatabase(this.props.user);
          } else {
            // Wrong pin entered.
            this.setState({ showAlert: true, visible: false });
          }
        });
    } catch (e) {
      // The role is not saved in localstorage and we need to user to reauthenticate in
      // order for them to do employee tasks.
      console.log(e);
      this.props.showError('Not Employee');
    }
  }

  /* Upload document to user's database node. */
  uploadToDatabase(user) {
    const docs = this.props.files;
    console.log(docs);

    // Find user in realtimedb.
    userRef
      .orderByChild('id')
      .equalTo(user.id)
      .once('child_added', (snapshot) => {
        if (snapshot.exists() && docs.type !== 'image') {
          // Save doc info under user's non_images node.
          userRef
            .child(snapshot.key)
            .child('private_nonimgfiles')
            .push({
              url: docs.doc.url,
              name: docs.doc.name,
              folder: docs.doc.folder,
              originalId: auth.currentUser.uid,
            })
            .then(() => this.props.showComplete())
            .catch((error) => {
              console.log('Upload file error:', error);
              this.props.showError(error);
            });
        } else if (snapshot.exists()) {
          // Save image info under user's images node.
          userRef
            .child(snapshot.key)
            .child('private_files')
            .push({
              url: docs.doc.url,
              name: docs.doc.name,
              folder: docs.doc.folder,
              originalId: auth.currentUser.uid,
            })
            .then(() => this.props.showComplete())
            .catch((e) => {
              console.log('Upload image error:', e);
              this.props.showError(e);
            });
        }
      });
  }

  /* Close snackbar if user chooses to do so. */
  closeAlert = () => {
    this.setState({ alert: false });
  };

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>
            {this.state.showAlert && (
              <PINAlert close={() => this.setState({ showAlert: false })} />
            )}
            <Paper elevation={3} style={styles.confirmdocmodalcontainer}>
              <h1 style={styles.uploadmodalheader}>
                Are you sure you want to share this document with{' '}
                {this.props.user.name}?
              </h1>
              <h3 style={styles.uploadmodalheader}>
                If so, please enter your employee pin below.
              </h3>
              <form onSubmit={this.checkPin} style={styles.confirmdocmodalform}>
                <CustomTextField
                  id='outlined-basic'
                  variant='outlined'
                  type='text'
                  inputProps={{ maxLength: 6 }}
                  value={this.state.PIN}
                  placeholder='000000'
                  onChange={(e) => this.setState({ PIN: e.target.value })}
                />
                <hr style={styles.progressbar} />
                {this.state.visible && (
                  <LinearProgress
                    color='secondary'
                    style={styles.confirmdocprogressbar}
                  />
                )}
                <div style={styles.confirmdocbuttoncontainer}>
                  <CustomButton
                    variant='contained'
                    color='default'
                    style={styles.uploadmodalbutton1}
                    onClick={() => this.props.close()}
                  >
                    Close
                  </CustomButton>

                  <CustomButton2
                    variant='contained'
                    color='default'
                    disabled={this.state.visible}
                    style={styles.uploadmodalbutton}
                    type='submit'
                  >
                    SHARE
                  </CustomButton2>
                </div>
              </form>
            </Paper>
          </>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal for uploading a document.
 */
export class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.uplodamodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.confirmdocmodalcontainer}>
            <h1 style={styles.uploadmodalheader}>
              Are you sure you want to upload this document?
            </h1>
            <div>
              <CustomButton
                variant='contained'
                color='default'
                style={styles.uploadmodalbutton1}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>

              <CustomButton2
                variant='contained'
                color='default'
                style={styles.uploadmodalbutton}
                onClick={() => this.props.upload()}
              >
                UPLOAD
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Confirmation modal prompts the user if they're sure they want to
 * delete the document.
 */
export class DeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleRemove = this.handleRemove.bind(this);
  }

  /* Depending on the button pressed, the document will be removed from our database
     and storage bucket from either just the main user or everyone. */
  handleRemove(status) {
    try {
      const myRole = localStorage.getItem('myRole');
      let myref = '';
      if (status === 'Everyone') {
        this.deleteForEveryone();
      }
      if (myRole === 'Employee') {
        myref = empRef;
      } else if (myRole === 'Manager') {
        myref = managerRef;
      } else if (myRole === 'Admin') {
        myref = adminRef;
      }

      // Find current authed user's database node.
      myref
        .orderByChild('id')
        .equalTo(auth.currentUser.uid)
        .once('child_added', (snapshot) => {
          if (
            snapshot.exists() &&
            this.props.type === 'image' &&
            snapshot.val().private_files !== undefined
          ) {
            // Remove image from database and storage bucket.
            const mydocref = myref.child(snapshot.key).child('private_files');
            mydocref
              .orderByChild('name')
              .equalTo(this.props.document.name)
              .once('child_added', (snap) => {
                if (snap.exists()) {
                  mydocref.child(snap.key).remove();
                  storageRef
                    .child(
                      `/${auth.currentUser.uid}/Uploads/${this.props.document.name}.jpg`
                    )
                    .delete()
                    .then(this.props.showComplete());
                }
              });
          } else if (
            snapshot.exists() &&
            this.props.type !== 'image' &&
            snapshot.val().private_nonimgfiles !== undefined
          ) {
            console.log('inside second conditional');
            // Remove nonimage from database and storage bucket.

            const mydocref = myref
              .child(snapshot.key)
              .child('private_nonimgfiles');
            mydocref
              .orderByChild('name')
              .equalTo(this.props.document.name)
              .once('child_added', (snap) => {
                if (snap.exists()) {
                  mydocref.child(snap.key).remove();
                  storageRef
                    .child(
                      `/${auth.currentUser.uid}/Uploads/${this.props.document.name}.jpg`
                    )
                    .delete()
                    .then(this.props.showComplete());
                }
              });
          }
        });
    } catch (error) {
      console.log(error);
      this.props.showError();
    }
  }

  /* If user chooses to delete the document permanently, delete it for everyone. */
  deleteForEveryone() {
    // Delete for all admins
    adminRef.on('child_added', (snapshot) => {
      if (
        snapshot.exists() &&
        this.props.type === 'image' &&
        snapshot.val().private_files !== undefined
      ) {
        const privateRef = adminRef.child(snapshot.key).child('private_files');
        privateRef
          .orderByChild('name')
          .equalTo(this.props.document.name)
          .once('child_added', (snap) => {
            if (snap.exists()) {
              privateRef.child(snap.key).remove();
            }
          });
      } else if (
        snapshot.exists() &&
        this.props.type !== 'image' &&
        snapshot.val().private_nonimgfiles !== undefined
      ) {
        const privatenonimgref = adminRef
          .child(snapshot.key)
          .child('private_nonimgfiles');
        privatenonimgref
          .orderByChild('name')
          .equalTo(this.props.document.name)
          .once('child_added', (snap) => {
            if (snap.exists()) {
              privatenonimgref.child(snap.key).remove();
            }
          });
      }
    });

    // Delete for all managers
    managerRef.on('child_added', (snapshot) => {
      if (
        snapshot.exists() &&
        this.props.type === 'image' &&
        snapshot.val().private_files !== undefined
      ) {
        const privateRef = managerRef
          .child(snapshot.key)
          .child('private_files');
        privateRef
          .orderByChild('name')
          .equalTo(this.props.document.name)
          .once('child_added', (snap) => {
            if (snap.exists()) {
              privateRef.child(snap.key).remove();
            }
          });
      } else if (
        snapshot.exists() &&
        this.props.type !== 'image' &&
        snapshot.val().private_nonimgfiles !== undefined
      ) {
        const privatenonimgref = managerRef
          .child(snapshot.key)
          .child('private_nonimgfiles');
        privatenonimgref
          .orderByChild('name')
          .equalTo(this.props.document.name)
          .once('child_added', (snap) => {
            if (snap.exists()) {
              privatenonimgref.child(snap.key).remove();
            }
          });
      }
    });

    // Delete for all employees
    empRef.on('child_added', (snapshot) => {
      if (
        snapshot.exists() &&
        this.props.type === 'image' &&
        snapshot.val().private_files !== undefined
      ) {
        const privateRef = empRef.child(snapshot.key).child('private_files');
        privateRef
          .orderByChild('name')
          .equalTo(this.props.document.name)
          .once('child_added', (snap) => {
            if (snap.exists()) {
              privateRef.child(snap.key).remove();
            }
          });
      } else if (
        snapshot.exists() &&
        this.props.type !== 'image' &&
        snapshot.val().private_nonimgfiles !== undefined
      ) {
        const privatenonimgref = empRef
          .child(snapshot.key)
          .child('private_nonimgfiles');
        privatenonimgref
          .orderByChild('name')
          .equalTo(this.props.document.name)
          .once('child_added', (snap) => {
            if (snap.exists()) {
              privatenonimgref.child(snap.key).remove();
            }
          });
      }
    });

    // Delete references in storage bucket.
    const originalId = this.props.document.originalId;
    const docName = this.props.document.name;
    const deleteRef = storageRef.child(
      `/${auth.currentUser.uid}/Uploads/${docName}.jpg`
    );
    const originalRef = storageRef.child(
      `/${originalId}/Uploads/${docName}.jpg`
    );

    if (originalId !== auth.currentUser.uid) {
      deleteRef
        .delete()
        .then(() => {
          originalRef
            .delete()
            .then(() => {
              this.props.showComplete();
            })
            .catch((err) => {
              console.log(err);
              this.props.showError();
            });
        })
        .catch((e) => {
          console.log(e);
          this.props.showError();
        });
    } else {
      deleteRef
        .delete()
        .then(() => {
          this.props.showComplete();
        })
        .catch((err) => {
          console.log(err);
          this.props.showError();
        });
    }
  }

  /* Main render method of component. */
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.confirmdocmodalcontainer}>
            <h1 style={styles.uploadmodalheader}>
              Are you sure you want to delete this document?
            </h1>
            <hr style={styles.uploadmodalheader} />

            <div style={styles.confirmdocbuttonbottomcontainer}>
              <CustomButton
                variant='contained'
                color='default'
                style={styles.confirmdocbutton}
                onClick={() => this.props.close()}
              >
                Close
              </CustomButton>

              <CustomButton2
                variant='contained'
                color='secondary'
                style={styles.confirmdocbutton}
                onClick={() => this.handleRemove('ME')}
              >
                DELETE FOR MYSELF
              </CustomButton2>
              <CustomButton2
                variant='contained'
                color='secondary'
                style={styles.uplodamodalbutton}
                onClick={() => this.handleRemove('Everyone')}
              >
                DELETE FOR EVERYONE
              </CustomButton2>
            </div>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a user has uploaded a document.
 */
export class CompleteModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.documentmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.documentmodalcontainer}>
            <h1 style={styles.documentmodalheader}>
              Your document has been uploaded.
            </h1>
            <CustomButton2
              variant='contained'
              color='default'
              style={styles.documentmodalbutton}
              onClick={() => this.props.close()}
            >
              Close
            </CustomButton2>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a document has been shared.
 */
export class CompleteShareModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.documentmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.documentmodalcontainer}>
            <h1 style={styles.documentmodalheader}>
              Your document has been shared.
            </h1>
            <CustomButton2
              variant='contained'
              color='default'
              style={styles.documentmodalbutton}
              onClick={() => this.props.close()}
            >
              Close
            </CustomButton2>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Completion modal for when a document has been deleted.
 */
export class DeleteCompleteModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.uploadmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.documentmodalcontainer}>
            <h1 style={styles.documentmodalheader}>
              Your document has been deleted.
            </h1>
            <CustomButton2
              variant='contained'
              color='default'
              style={styles.documentmodalbutton}
              onClick={() => this.props.close()}
            >
              Close
            </CustomButton2>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Error modal warns the user that their request cannot be completed.
 */
export class ErrorModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.documentmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.documentmodalcontainer}>
            <h1 style={styles.documentmodalheader}>
              Something went wrong with your upload. Please try again later.
            </h1>
            <CustomButton2
              variant='contained'
              color='default'
              style={styles.documentmodalbutton}
              onClick={() => this.props.close()}
            >
              Close
            </CustomButton2>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Error modal warns user that their document cannot be shared at this time.
 */
export class ErrorShareModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.documentmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.documentmodalcontainer}>
            <h1 style={styles.documentmodalheader}>
              Something went wrong with sharing this document. Please try again
              later.
            </h1>
            <CustomButton2
              variant='contained'
              color='default'
              style={styles.documentmodalbutton}
              onClick={() => this.props.close()}
            >
              Close
            </CustomButton2>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}

/*
 * Error modal warns the user that their document cannot be deleted at this time.
 */
export class DeleteErrorModal extends React.Component {
  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={styles.documentmodal}
        open={true}
        onClose={() => this.props.close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={styles.documentmodalcontainer}>
            <h1 style={styles.documentmodalheader}>
              Something went wrong with your request. Please try again later.
            </h1>
            <CustomButton2
              variant='contained'
              color='default'
              style={styles.documentmodalbutton}
              onClick={() => this.props.close()}
            >
              Close
            </CustomButton2>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}
