/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { auth, notificationsRef } from '../Firebase/FirebaseConfig';

// Import Components
import Header from '../Components/Header';
import AddNotificationButton from '../Components/Notifications/AddNotificationButton';
import {
  UploadModal,
  UploadCompleteModal,
  EditCompleteModal,
  DeleteModal,
  DeleteCompleteModal,
  DeleteErrorModal,
  ErrorModal,
  EditModal,
} from '../Components/Modals/NotificationModals';
import {
  CustomButton,
  CustomButton2,
} from '../Components/Custom/CustomComponents';

import styles from '../Styles/Notifications';

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = { DATA: [] };

    this.showUploadModal = this.showUploadModal.bind(this);
  }

  /* Set the role and establish authentication handler for this screen. */
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      user ? this.getData() : this.props.history.push('/login');
    });
  }

  /* Get all notifications from our database. */
  getData() {
    const tempArr = [];
    if (this.state.DATA.length > 1) {
      this.setState({ Data: [] });
    }

    notificationsRef.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        tempArr.push(snap.val());
      });
      this.setState({ DATA: tempArr });
    });
  }

  /* Handler for component to access parent state. */
  showUploadModal() {
    this.setState({ showUploadModal: true });
  }

  /* Save the notification to it's own helper method to help with runtime issues. */
  showEditModal(noti) {
    this.setState({ notification: noti, showEditModal: true });
  }

  /* Save the notification to it's own helper method to help with runtime issues. */
  showDeleteModal(noti) {
    this.setState({ notification: noti, showDeleteModal: true });
  }

  /* Close all modals on screen. */
  closeModals = () => {
    this.setState({
      showUploadModal: false,
      showComplete: false,
      showError: false,
      showEditModal: false,
      showEditComplete: false,
      showEditError: false,
      showDeleteComplete: false,
      showDeleteModal: false,
      showDeleteError: false,
    });
  };

  /* Close all modals and reload the screen. */
  closeModalsReload = () => {
    this.setState(
      {
        showUploadModal: false,
        showComplete: false,
        showError: false,
        showEditModal: false,
        showEditComplete: false,
        showEditError: false,
        showDeleteComplete: false,
        showDeleteModal: false,
        showDeleteError: false,
      },
      () => {
        this.getData();
      }
    );
  };

  /* Main render method of component. */
  render() {
    const { DATA } = this.state;
    const myRole = localStorage.getItem('myRole');
    console.log(myRole);
    console.log(myRole !== 'Admin');

    return (
      <>
        <Header history={this.props.history} />
        <div style={styles.maincontainer}>
          {/* Display if no notifications. */}
          {DATA !== undefined && DATA.length === 0 && (
            <div>
              <h2 style={styles.nocontenttext}>There are no notifications.</h2>
            </div>
          )}

          {/* Display notifications here. */}
          <div style={styles.secondarycontainer}>
            {DATA.map((noti, index) => (
              <Card key={index} style={styles.card}>
                <CardContent style={styles.cardcontent}>
                  <div
                    style={styles.imgcontainer}
                    onClick={() =>
                      noti.image ? window.open(noti.image, '_blank') : null
                    }
                  >
                    <img
                      src={noti.image ? noti.image : null}
                      alt='There is no image for this notification.'
                      width='100%'
                      height='100%'
                    />
                  </div>
                  <h2 style={styles.text}>{noti.title}</h2>
                  <hr style={styles.hr} />
                  <h3 style={styles.text}>{noti.body}</h3>
                  <div style={styles.buttoncontainer}>
                    <CustomButton
                      variant='contained'
                      color='default'
                      style={styles.leftbutton}
                      onClick={() =>
                        this.props.history.push({
                          pathname: 'sendnotification',
                          state: { notification: noti },
                        })
                      }
                    >
                      Give
                    </CustomButton>
                    <CustomButton2
                      variant='contained'
                      color='secondary'
                      style={styles.leftbutton}
                      disabled={myRole !== 'Admin'}
                      onClick={() => this.showDeleteModal(noti)}
                    >
                      DELETE
                    </CustomButton2>
                    <CustomButton
                      variant='contained'
                      color='default'
                      style={styles.rightbutton}
                      disabled={myRole !== 'Admin'}
                      onClick={() => this.showEditModal(noti)}
                    >
                      EDIT
                    </CustomButton>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>

          <div style={styles.notificationbutton}>
            <AddNotificationButton promptModal={this.showUploadModal} />
          </div>

          {/* Show Modals */}
          {this.state.showUploadModal && myRole === 'Admin' && (
            <UploadModal
              close={this.closeModals}
              showComplete={() => this.setState({ showComplete: true })}
              showError={(error) =>
                this.setState({ error: error, showError: true })
              }
            />
          )}
          {this.state.showEditModal && myRole === 'Admin' && (
            <EditModal
              notification={this.state.notification}
              close={this.closeModals}
              showComplete={() => this.setState({ showEditComplete: true })}
              showError={(error) =>
                this.setState({ error: error, showEditError: true })
              }
            />
          )}
          {this.state.showDeleteModal && myRole === 'Admin' && (
            <DeleteModal
              notification={this.state.notification}
              close={this.closeModals}
              showComplete={() => this.setState({ showDeleteComplete: true })}
              showError={(error) => {
                this.setState({ error: error, showDeleteError: true });
              }}
            />
          )}
          {this.state.showComplete && (
            <UploadCompleteModal close={this.closeModals} />
          )}

          {this.state.showError && <ErrorModal close={this.closeModals} />}
          {this.state.showEditComplete && (
            <EditCompleteModal close={this.closeModalsReload} />
          )}
          {this.state.showDeleteComplete && (
            <DeleteCompleteModal close={this.closeModalsReload} />
          )}
          {this.state.showDeleteError && (
            <DeleteErrorModal close={this.closeModals} />
          )}
        </div>{' '}
      </>
    );
  }
}
