import firebase from "firebase";
import "firebase/storage";

// STL Giant Config
const firebaseConfig = {
  apiKey: "AIzaSyAACy8ZvwdUx_SYIab-whd4sqLRpDbpVXA",
  authDomain: "autostringer-4c687.firebaseapp.com",
  databaseURL: "https://autostringer-4c687.firebaseio.com",
  projectId: "autostringer-4c687",
  storageBucket: "autostringer-4c687.appspot.com",
  messagingSenderId: "787026951185",
  appId: "1:787026951185:web:a869c066fa423a5566c5b0",
  measurementId: "G-RM0PNXXPDF",
};

// Demo Config
// export const firebaseConfig = {
//   apiKey: "AIzaSyDlZdEF-juEhycN3RudVQVvgqjrytKuvF4",
//   authDomain: "autostringer-demo.firebaseapp.com",
//   databaseURL: "https://autostringer-demo.firebaseio.com",
//   projectId: "autostringer-demo",
//   storageBucket: "autostringer-demo.appspot.com",
//   messagingSenderId: "168533156868",
//   appId: "1:168533156868:web:b1cb123414eb5444830151",
//   measurementId: "G-PYZ2M64HEN",
// };

firebase.initializeApp(firebaseConfig);

// Initialize browser for messages (if we actually want to do this.)
// const messaging = firebase.messaging();
// messaging
//   .requestPermission()
//   .then((perm) => {
//     console.log(perm);
//     console.log("Has permission");
//     return messaging.getToken();
//   })
//   .then((token) => {
//     console.log(token);
//     // Update currently authed user here
//   })
//   .catch((err) => {
//     // This call will fail if there is an insecure connection. On linux, I pass the https=true flag to yarn start but on mac this should work fine.
//     console.log(err);
//   });

// messaging.onMessage((payload) => {
//   console.log("OnMessage:", payload);
// });

/* Endpoint methods for firebase (realtimedb, storage, authentication) */
// export const app = "demo";
export const app = "south-county-autos";

// export const dealership = "Demo";
export const dealership = "South County Autos";

export const firebaseRef = firebase;

export const auth = firebase.auth();

export const userRef = firebase.database().ref(`${app}/users`);

export const empRef = firebase.database().ref(`${app}/employees`);

export const adminRef = firebase.database().ref(`${app}/admin`);

export const analyticsRef = firebase.database().ref(`${app}/analytics`);

export const archivePromosRef = firebase.database().ref(`${app}/archivePromos`);

export const managerRef = firebase.database().ref(`${app}/managers`);

export const chatrooms = firebase.database().ref(`${app}/chatrooms`);

export const promosRef = firebase.database().ref(`${app}/promos`);

export const notificationsRef = firebase.database().ref(`${app}/notifications`);

export const storageRef = firebase.storage().ref(`${app}`);

// OnRequest urls from firebase
export const PUSH_ALL_CUSTOMERS =
  "https://us-central1-autostringer-4c687.cloudfunctions.net/pushNotificationWeb";

export const CREATE_USER =
  "https://us-central1-autostringer-4c687.cloudfunctions.net/createUser";

export const DELETE_USER =
  "https://us-central1-autostringer-4c687.cloudfunctions.net/deleteUser";

export const SEND_USER_NOTIFICATION =
  " https://us-central1-autostringer-4c687.cloudfunctions.net/sendUserNotification ";

// Extra global values and stuff
export const PARENT_SITE = "https://www.southcountyautos.com/";

export const defaultnotification =
  "https://firebasestorage.googleapis.com/v0/b/autostringer-4c687.appspot.com/o/south-county-autos%2Fnotifications%2FDefault%2Fappstore.png?alt=media&token=1c2984d6-b8f8-42c5-abaa-f1df29bd8791";
