import React from "react";
import { userRef } from "../../Firebase/FirebaseConfig";

import styles from "../../Styles/Components";

/*
 * This component queries the database for every user and spits out
 * the amount of promotions that can still be redeemed.
 * Ex: If there are two users that each have a promotion with 5 redemptions
 *     each, then this component should theoretically return 10.
 */
export default class AmountPromosRemaining extends React.Component {
  constructor(props) {
    super(props);
    this.state = { remaining: 0 };
  }

  /* Get the necessary data everytime the component is mounted. */
  componentDidMount() {
    this.getData();
  }

  /* Return the number of outstanding promotions in our database. */
  getData() {
    let num = 0;

    // Read database info once.
    userRef.once("value", (snapshot) => {
      // Iterate through each user.
      snapshot.forEach((snap) => {
        const userpromoref = userRef.child(snap.key).child("promos");
        // Read the user's promotions once.
        userpromoref.once("value", (sn) => {
          // Iterate through each user's promotions.
          sn.forEach((s) => {
            // Increment count for the amount of uses on each promotion and set it to state.
            let uses = parseInt(s.val().uses);
            num += uses;
            this.setState({ remaining: num });
          });
        });
      });
    });
  }

  /* Main render method of component. */
  render() {
    return (
      <div style={styles.mainremainingcontainer}>
        Total Pre-paid Service Redemptions Out There:{" "}
        <h4 style={styles.remainingcount}>{this.state.remaining}</h4>
      </div>
    );
  }
}
